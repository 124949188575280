import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface CanalMarketing {
    nom: string;
    budget: string;
    roi: string;
    priorite: 'haute' | 'moyenne' | 'basse';
}

interface BudgetData {
    chiffreAffaires: string;
    objectifCroissance: string;
    budgetTotal: string;
    canaux: CanalMarketing[];
}

interface BudgetResults {
    repartition: {
        canal: string;
        budget: number;
        roiEstime: number;
        caAttendu: number;
    }[];
    totalBudget: number;
    roiGlobal: number;
    recommandations: string[];
}

const BudgetMarketing: React.FC = () => {
    const [formData, setFormData] = useState<BudgetData>({
        chiffreAffaires: '',
        objectifCroissance: '',
        budgetTotal: '',
        canaux: [
            { nom: 'SEO', budget: '', roi: '', priorite: 'haute' },
            { nom: 'Publicité en ligne', budget: '', roi: '', priorite: 'moyenne' },
            { nom: 'Réseaux sociaux', budget: '', roi: '', priorite: 'moyenne' },
            { nom: 'Email marketing', budget: '', roi: '', priorite: 'haute' },
            { nom: 'Marketing de contenu', budget: '', roi: '', priorite: 'haute' },
        ],
    });

    const [results, setResults] = useState<BudgetResults | null>(null);

    const calculateBudget = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const objectif = parseFloat(formData.objectifCroissance) || 0;
        const budgetTotal = parseFloat(formData.budgetTotal) || 0;

        // Calcul de la répartition et des ROI
        const repartition = formData.canaux.map(canal => {
            const budget = parseFloat(canal.budget) || 0;
            const roi = parseFloat(canal.roi) || 0;
            const caAttendu = budget * (1 + roi / 100);

            return {
                canal: canal.nom,
                budget,
                roiEstime: roi,
                caAttendu,
            };
        });

        // Calcul du ROI global
        const totalInvestissement = repartition.reduce((sum, canal) => sum + canal.budget, 0);
        const totalCaAttendu = repartition.reduce((sum, canal) => sum + canal.caAttendu, 0);
        const roiGlobal = ((totalCaAttendu - totalInvestissement) / totalInvestissement) * 100;

        // Génération des recommandations
        const recommandations = [];

        // Vérification du budget total
        if (budgetTotal > ca * 0.2) {
            recommandations.push("Le budget marketing dépasse 20% du CA, envisagez d'optimiser les dépenses");
        }

        // Analyse des ROI par canal
        repartition.forEach(canal => {
            if (canal.roiEstime < 100) {
                recommandations.push(`Le ROI du canal ${canal.canal} est faible, réévaluez la stratégie`);
            }
        });

        // Vérification de la répartition
        const canauxHautePriorite = formData.canaux.filter(c => c.priorite === 'haute');
        const budgetHautePriorite = canauxHautePriorite.reduce((sum, c) => sum + (parseFloat(c.budget) || 0), 0);

        if (budgetHautePriorite < budgetTotal * 0.5) {
            recommandations.push("Augmentez l'allocation aux canaux prioritaires pour optimiser le ROI");
        }

        // Vérification de l'objectif de croissance
        if (totalCaAttendu < ca * (1 + objectif / 100)) {
            recommandations.push("Le budget actuel pourrait être insuffisant pour atteindre l'objectif de croissance");
        }

        setResults({
            repartition,
            totalBudget: budgetTotal,
            roiGlobal,
            recommandations,
        });
    };

    const handleCanalChange = (index: number, field: keyof CanalMarketing, value: string) => {
        const newCanaux = [...formData.canaux];
        newCanaux[index] = { ...newCanaux[index], [field]: value };
        setFormData(prev => ({ ...prev, canaux: newCanaux }));
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Planification du budget marketing
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires actuel (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Objectif de croissance (%)"
                        type="number"
                        value={formData.objectifCroissance}
                        onChange={e => setFormData(prev => ({ ...prev, objectifCroissance: e.target.value }))}
                        placeholder="Ex: 20"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Budget marketing total (€)"
                        type="number"
                        value={formData.budgetTotal}
                        onChange={e => setFormData(prev => ({ ...prev, budgetTotal: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 4 }}>
                    Répartition par canal
                </Typography>

                {formData.canaux.map((canal, index) => (
                    <Paper key={index} elevation={0} sx={{ p: 2, mb: 2, backgroundColor: '#f8f9fa' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{canal.nom}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Budget alloué (€)"
                                    type="number"
                                    value={canal.budget}
                                    onChange={e => handleCanalChange(index, 'budget', e.target.value)}
                                    placeholder="Ex: 20000"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="ROI attendu (%)"
                                    type="number"
                                    value={canal.roi}
                                    onChange={e => handleCanalChange(index, 'roi', e.target.value)}
                                    placeholder="Ex: 150"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl component="fieldset" fullWidth>
                                    <RadioGroup
                                        row
                                        value={canal.priorite}
                                        onChange={e =>
                                            handleCanalChange(
                                                index,
                                                'priorite',
                                                e.target.value as 'haute' | 'moyenne' | 'basse',
                                            )
                                        }
                                    >
                                        <FormControlLabel value="haute" control={<Radio />} label="Haute" />
                                        <FormControlLabel value="moyenne" control={<Radio />} label="Moyenne" />
                                        <FormControlLabel value="basse" control={<Radio />} label="Basse" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateBudget}
                    disabled={!formData.budgetTotal || !formData.chiffreAffaires}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Analyser le budget
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Budget marketing total</span>
                        <span className="value">{Math.round(results.totalBudget).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">ROI global estimé</span>
                        <span className="value">{results.roiGlobal.toFixed(1)}%</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Répartition par canal</span>
                        <span className="value"></span>
                    </div>

                    {results.repartition.map((canal, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{canal.canal}</span>
                                <span className="value">{Math.round(canal.budget).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">ROI attendu</span>
                                <span className="value">
                                    {canal.roiEstime}% ({Math.round(canal.caAttendu).toLocaleString('fr-FR')} €)
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default BudgetMarketing;
