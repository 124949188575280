import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import ResultsCard from '../../common/ResultsCard';

interface FormeJuridiqueData {
    activite: 'commerce' | 'service' | 'artisanat' | 'liberal';
    chiffreAffaires: string;
    benefice: string;
    nombreAssocies: string;
    protectionPatrimoine: 'oui' | 'non';
}

interface FormeJuridiqueResults {
    formeRecommandee: string;
    formesAlternatives: string[];
    avantages: string[];
    inconvenients: string[];
    recommandations: string[];
}

const FormeJuridique: React.FC = () => {
    const [formData, setFormData] = useState<FormeJuridiqueData>({
        activite: 'commerce',
        chiffreAffaires: '',
        benefice: '',
        nombreAssocies: '1',
        protectionPatrimoine: 'oui',
    });

    const [results, setResults] = useState<FormeJuridiqueResults | null>(null);

    const calculateFormeJuridique = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const benefice = parseFloat(formData.benefice) || 0;
        const associes = parseInt(formData.nombreAssocies) || 1;

        let formeRecommandee = '';
        let formesAlternatives: string[] = [];
        let avantages: string[] = [];
        let inconvenients: string[] = [];
        let recommandations: string[] = [];

        // Logique de détermination de la forme juridique
        if (associes === 1) {
            if (ca < 72600 && formData.activite === 'service') {
                formeRecommandee = 'Entreprise individuelle';
                formesAlternatives = ['EURL', 'SASU'];
                avantages = [
                    'Simplicité de création et de gestion',
                    'Régime fiscal micro-entreprise possible',
                    'Pas de capital social minimum',
                ];
                inconvenients = [
                    'Responsabilité illimitée sur les biens personnels',
                    'Crédibilité limitée auprès des partenaires',
                    'Difficultés pour lever des fonds',
                ];
            } else {
                formeRecommandee = formData.protectionPatrimoine === 'oui' ? 'EURL' : 'Entreprise individuelle';
                formesAlternatives = ['SASU'];
                avantages = [
                    'Protection du patrimoine personnel',
                    'Crédibilité renforcée',
                    'Optimisation fiscale possible',
                ];
                inconvenients = [
                    'Coûts et formalités de constitution',
                    'Obligations comptables plus importantes',
                    'Charges sociales plus élevées',
                ];
            }
        } else {
            if (formData.activite === 'liberal') {
                formeRecommandee = 'SELARL';
                formesAlternatives = ['SELAS'];
                avantages = [
                    'Adaptée aux professions réglementées',
                    'Protection du patrimoine',
                    'Crédibilité professionnelle',
                ];
                inconvenients = ['Capital social minimum requis', 'Formalisme important', 'Coût de constitution élevé'];
            } else {
                formeRecommandee = benefice > 42000 ? 'SAS' : 'SARL';
                formesAlternatives = benefice > 42000 ? ['SARL'] : ['SAS'];
                avantages = [
                    "Flexibilité dans l'organisation",
                    'Facilité pour lever des fonds',
                    'Statut adapté à la croissance',
                ];
                inconvenients = ['Coûts de constitution', 'Obligations comptables', 'Formalisme des décisions'];
            }
        }

        // Génération des recommandations
        if (ca > 100000) {
            recommandations.push('Privilégiez une forme sociétaire pour une meilleure crédibilité');
        }
        if (formData.protectionPatrimoine === 'oui') {
            recommandations.push('Optez pour une société pour protéger votre patrimoine personnel');
        }
        if (associes > 3) {
            recommandations.push('La SAS offre plus de flexibilité pour gérer plusieurs associés');
        }

        setResults({
            formeRecommandee,
            formesAlternatives,
            avantages,
            inconvenients,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Choix de la forme juridique
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.activite}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                activite: e.target.value as 'commerce' | 'service' | 'artisanat' | 'liberal',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="artisanat"
                            control={<Radio />}
                            label="Artisanat"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="liberal"
                            control={<Radio />}
                            label="Libéral"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires prévisionnel (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Bénéfice prévisionnel (€)"
                        type="number"
                        value={formData.benefice}
                        onChange={e => setFormData(prev => ({ ...prev, benefice: e.target.value }))}
                        placeholder="Ex: 40000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre d'associés"
                        type="number"
                        value={formData.nombreAssocies}
                        onChange={e => setFormData(prev => ({ ...prev, nombreAssocies: e.target.value }))}
                        placeholder="Ex: 1"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.protectionPatrimoine}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                protectionPatrimoine: e.target.value as 'oui' | 'non',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="oui"
                            control={<Radio />}
                            label="Protection du patrimoine souhaitée"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="non"
                            control={<Radio />}
                            label="Protection du patrimoine non prioritaire"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateFormeJuridique}
                    disabled={!formData.chiffreAffaires || !formData.benefice}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Déterminer la forme juridique
                </Button>
            </Paper>

            {results && (
                <ResultsCard
                    mainResult={{
                        label: 'Forme juridique recommandée',
                        value: results.formeRecommandee,
                    }}
                    sections={[
                        {
                            title: 'Formes alternatives possibles',
                            items: [
                                {
                                    label: 'Alternatives',
                                    value: results.formesAlternatives.join(', '),
                                },
                            ],
                        },
                        {
                            title: 'Analyse détaillée',
                            items: [
                                {
                                    label: 'Avantages',
                                    value: '',
                                    description: results.avantages.map(avantage => `• ${avantage}`).join('\n'),
                                    color: '#2E7D32', // Vert pour les avantages
                                },
                                {
                                    label: 'Inconvénients',
                                    value: '',
                                    description: results.inconvenients
                                        .map(inconvenient => `• ${inconvenient}`)
                                        .join('\n'),
                                    color: '#C62828', // Rouge pour les inconvénients
                                },
                            ],
                        },
                    ]}
                    recommendations={results.recommandations}
                />
            )}
        </div>
    );
};

export default FormeJuridique;
