import React from 'react';
import { Paper } from '@mui/material';

interface ResultsCardProps {
    mainResult?: {
        label: string;
        value: string | number;
        color?: string;
    };
    sections?: {
        title: string;
        items: {
            label: string;
            value: string | number;
            description?: string;
            color?: string;
            backgroundColor?: string;
        }[];
    }[];
    recommendations?: string[];
}

const ResultsCard: React.FC<ResultsCardProps> = ({ mainResult, sections, recommendations }) => {
    return (
        <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
            {mainResult && (
                <div className="result-row highlight">
                    <span className="label">{mainResult.label}</span>
                    <span className="value" style={mainResult.color ? { color: mainResult.color } : undefined}>
                        {mainResult.value}
                    </span>
                </div>
            )}

            {sections?.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">{section.title}</span>
                        <span className="value"></span>
                    </div>

                    {section.items.map((item, itemIndex) => (
                        <React.Fragment key={itemIndex}>
                            <div className="result-row" style={{ backgroundColor: item.backgroundColor }}>
                                <span className="label">{item.label}</span>
                                <span className="value" style={item.color ? { color: item.color } : undefined}>
                                    {item.value}
                                </span>
                            </div>
                            {item.description && (
                                <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                    <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                        {item.description}
                                    </span>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}

            {recommendations && recommendations.length > 0 && (
                <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                    <span className="label">Recommandations</span>
                    {recommendations.map((recommendation, index) => (
                        <p key={index} style={{ margin: 0, color: '#666' }}>
                            • {recommendation}
                        </p>
                    ))}
                </div>
            )}
        </Paper>
    );
};

export default ResultsCard;
