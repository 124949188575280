import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface DelaisData {
    chiffreAffaires: string;
    achats: string;
    creancesClients: string;
    dettesFournisseurs: string;
    typeClient: 'public' | 'prive';
    delaiContractuel: string;
    retardMoyen: string;
}

interface DelaisResults {
    delaiClientJours: number;
    delaiFournisseurJours: number;
    bfr: number;
    coutFinancement: number;
    retardPenalites: number;
    details: {
        label: string;
        valeur: number;
        unite: string;
        description: string;
    }[];
    recommandations: string[];
}

const DelaisPaiement: React.FC = () => {
    const [formData, setFormData] = useState<DelaisData>({
        chiffreAffaires: '',
        achats: '',
        creancesClients: '',
        dettesFournisseurs: '',
        typeClient: 'prive',
        delaiContractuel: '30',
        retardMoyen: '0',
    });

    const [results, setResults] = useState<DelaisResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateDelais = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const achats = parseFloat(formData.achats) || 0;
        const creances = parseFloat(formData.creancesClients) || 0;
        const dettes = parseFloat(formData.dettesFournisseurs) || 0;
        const delaiContractuel = parseFloat(formData.delaiContractuel) || 30;
        const retardMoyen = parseFloat(formData.retardMoyen) || 0;

        // Calcul des délais réels
        const delaiClientJours = (creances / ca) * 360;
        const delaiFournisseurJours = (dettes / achats) * 360;

        // Calcul du BFR lié aux délais
        const bfr = creances - dettes;

        // Estimation du coût de financement du BFR (taux de 5%)
        const coutFinancement = bfr * 0.05;

        // Calcul des pénalités de retard potentielles
        const tauxPenalite = formData.typeClient === 'public' ? 0.0825 : 0.12; // 8.25% pour le public, 12% pour le privé
        const retardPenalites = ca * (retardMoyen / 360) * tauxPenalite;

        // Génération des recommandations
        const recommandations = [];

        if (delaiClientJours > delaiContractuel + 15) {
            recommandations.push(
                'Les délais clients sont significativement supérieurs aux délais contractuels. Mettez en place des relances systématiques.',
            );
        }

        if (delaiFournisseurJours < 45) {
            recommandations.push(
                'Les délais fournisseurs sont courts. Négociez des conditions de paiement plus favorables.',
            );
        }

        if (bfr > ca * 0.25) {
            recommandations.push("Le BFR est élevé. Optimisez votre cycle d'exploitation.");
        }

        if (retardMoyen > 15) {
            recommandations.push('Les retards de paiement sont importants. Renforcez votre processus de recouvrement.');
        }

        const details = [
            {
                label: 'Délai client moyen',
                valeur: delaiClientJours,
                unite: 'jours',
                description: 'Durée moyenne de paiement des clients',
            },
            {
                label: 'Délai fournisseur moyen',
                valeur: delaiFournisseurJours,
                unite: 'jours',
                description: 'Durée moyenne de paiement des fournisseurs',
            },
            {
                label: 'BFR lié aux délais',
                valeur: bfr,
                unite: '€',
                description: 'Besoin de financement généré par les délais',
            },
            {
                label: 'Coût de financement',
                valeur: coutFinancement,
                unite: '€',
                description: 'Coût annuel du financement du BFR',
            },
            {
                label: 'Pénalités exigibles',
                valeur: retardPenalites,
                unite: '€',
                description: 'Pénalités de retard potentielles',
            },
        ];

        setResults({
            delaiClientJours,
            delaiFournisseurJours,
            bfr,
            coutFinancement,
            retardPenalites,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateDelais();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Analyse des délais de paiement
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeClient}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, typeClient: e.target.value as 'public' | 'prive' }))
                        }
                    >
                        <FormControlLabel
                            value="prive"
                            control={<Radio />}
                            label="Clients privés"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="public"
                            control={<Radio />}
                            label="Clients publics"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires annuel (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Achats annuels (€)"
                        type="number"
                        value={formData.achats}
                        onChange={e => setFormData(prev => ({ ...prev, achats: e.target.value }))}
                        placeholder="Ex: 600000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Créances clients (€)"
                        type="number"
                        value={formData.creancesClients}
                        onChange={e => setFormData(prev => ({ ...prev, creancesClients: e.target.value }))}
                        placeholder="Ex: 250000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dettes fournisseurs (€)"
                        type="number"
                        value={formData.dettesFournisseurs}
                        onChange={e => setFormData(prev => ({ ...prev, dettesFournisseurs: e.target.value }))}
                        placeholder="Ex: 150000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Délai contractuel (jours)"
                        type="number"
                        value={formData.delaiContractuel}
                        onChange={e => setFormData(prev => ({ ...prev, delaiContractuel: e.target.value }))}
                        placeholder="Ex: 30"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Retard moyen (jours)"
                        type="number"
                        value={formData.retardMoyen}
                        onChange={e => setFormData(prev => ({ ...prev, retardMoyen: e.target.value }))}
                        placeholder="Ex: 15"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.chiffreAffaires || !formData.achats}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer les délais
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">
                                    {Math.round(detail.valeur).toLocaleString('fr-FR')} {detail.unite}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default DelaisPaiement;
