import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface ProjetData {
    investissementInitial: string;
    duree: string;
    tauxActualisation: string;
    fluxAnnuels: string[];
    valeurResiduelle: string;
    typeProjet: 'industriel' | 'commercial' | 'immobilier';
}

interface ProjetResults {
    van: number;
    tri: number;
    roi: number;
    delaiRecuperation: number;
    fluxActualises: {
        annee: number;
        fluxBrut: number;
        fluxActualise: number;
        cumul: number;
    }[];
    recommandations: string[];
}

const RentabiliteProjet: React.FC = () => {
    const [formData, setFormData] = useState<ProjetData>({
        investissementInitial: '',
        duree: '',
        tauxActualisation: '',
        fluxAnnuels: [''],
        valeurResiduelle: '0',
        typeProjet: 'industriel',
    });

    const [results, setResults] = useState<ProjetResults | null>(null);

    const calculateRentabilite = () => {
        const investissement = parseFloat(formData.investissementInitial) || 0;
        const duree = parseInt(formData.duree) || 0;
        const tauxActualisation = parseFloat(formData.tauxActualisation) || 0;
        const valeurResiduelle = parseFloat(formData.valeurResiduelle) || 0;
        const fluxAnnuels = formData.fluxAnnuels.map(f => parseFloat(f) || 0);

        // Calcul des flux actualisés
        const fluxActualises = fluxAnnuels.map((flux, index) => {
            const facteurActualisation = Math.pow(1 + tauxActualisation / 100, -(index + 1));
            const fluxActualise = flux * facteurActualisation;
            return {
                annee: index + 1,
                fluxBrut: flux,
                fluxActualise,
                cumul: 0, // sera calculé plus tard
            };
        });

        // Ajout de la valeur résiduelle à la dernière année
        if (valeurResiduelle > 0) {
            const facteurActualisation = Math.pow(1 + tauxActualisation / 100, -duree);
            fluxActualises[duree - 1].fluxBrut += valeurResiduelle;
            fluxActualises[duree - 1].fluxActualise += valeurResiduelle * facteurActualisation;
        }

        // Calcul du cumul des flux actualisés
        let cumulActualise = -investissement;
        fluxActualises.forEach(flux => {
            cumulActualise += flux.fluxActualise;
            flux.cumul = cumulActualise;
        });

        // Calcul de la VAN
        const van = cumulActualise;

        // Calcul du TRI (méthode itérative simplifiée)
        let tri = 0;
        let step = 50;
        let iterations = 0;
        while (step > 0.01 && iterations < 100) {
            let npv = -investissement;
            for (let i = 0; i < fluxAnnuels.length; i++) {
                npv += fluxAnnuels[i] / Math.pow(1 + tri / 100, i + 1);
            }
            if (valeurResiduelle > 0) {
                npv += valeurResiduelle / Math.pow(1 + tri / 100, duree);
            }
            if (Math.abs(npv) < 1) break;
            if (npv > 0) {
                tri += step;
            } else {
                tri -= step;
                step /= 2;
            }
            iterations++;
        }

        // Calcul du ROI
        const totalFlux = fluxAnnuels.reduce((sum, flux) => sum + flux, 0) + valeurResiduelle;
        const roi = ((totalFlux - investissement) / investissement) * 100;

        // Calcul du délai de récupération
        let delaiRecuperation = duree;
        let cumulBrut = -investissement;
        for (let i = 0; i < fluxAnnuels.length; i++) {
            cumulBrut += fluxAnnuels[i];
            if (cumulBrut >= 0) {
                delaiRecuperation = i + 1;
                break;
            }
        }

        // Génération des recommandations
        const recommandations = [];

        if (van < 0) {
            recommandations.push("Le projet n'est pas rentable avec les hypothèses actuelles");
        }

        if (tri < tauxActualisation) {
            recommandations.push("Le TRI est inférieur au taux d'actualisation, le projet est risqué");
        }

        if (delaiRecuperation > duree / 2) {
            recommandations.push('Le délai de récupération est long, vérifiez la structure de financement');
        }

        const seuilsRentabilite = {
            industriel: { tri: 15, roi: 25 },
            commercial: { tri: 20, roi: 30 },
            immobilier: { tri: 8, roi: 15 },
        };

        const seuils = seuilsRentabilite[formData.typeProjet];
        if (tri < seuils.tri) {
            recommandations.push(`Le TRI est inférieur aux standards du secteur (${seuils.tri}%)`);
        }
        if (roi < seuils.roi) {
            recommandations.push(`Le ROI est inférieur aux standards du secteur (${seuils.roi}%)`);
        }

        setResults({
            van,
            tri,
            roi,
            delaiRecuperation,
            fluxActualises,
            recommandations,
        });
    };

    const handleFluxChange = (index: number, value: string) => {
        const newFlux = [...formData.fluxAnnuels];
        newFlux[index] = value;
        setFormData(prev => ({ ...prev, fluxAnnuels: newFlux }));
    };

    const updateFluxInputs = (duree: string) => {
        const nombreAnnees = parseInt(duree) || 0;
        setFormData(prev => ({
            ...prev,
            duree,
            fluxAnnuels: Array(nombreAnnees).fill(''),
        }));
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Analyse de rentabilité de projet
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeProjet}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                typeProjet: e.target.value as 'industriel' | 'commercial' | 'immobilier',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="industriel"
                            control={<Radio />}
                            label="Projet industriel"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="commercial"
                            control={<Radio />}
                            label="Projet commercial"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="immobilier"
                            control={<Radio />}
                            label="Projet immobilier"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Investissement initial (€)"
                        type="number"
                        value={formData.investissementInitial}
                        onChange={e => setFormData(prev => ({ ...prev, investissementInitial: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Durée du projet (années)"
                        type="number"
                        value={formData.duree}
                        onChange={e => updateFluxInputs(e.target.value)}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux d'actualisation (%)"
                        type="number"
                        value={formData.tauxActualisation}
                        onChange={e => setFormData(prev => ({ ...prev, tauxActualisation: e.target.value }))}
                        placeholder="Ex: 8"
                    />
                </FormControl>

                {formData.fluxAnnuels.map((flux, index) => (
                    <FormControl key={index} fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label={`Flux de trésorerie année ${index + 1} (€)`}
                            type="number"
                            value={flux}
                            onChange={e => handleFluxChange(index, e.target.value)}
                            placeholder={`Ex: ${20000 + index * 5000}`}
                        />
                    </FormControl>
                ))}

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Valeur résiduelle (€)"
                        type="number"
                        value={formData.valeurResiduelle}
                        onChange={e => setFormData(prev => ({ ...prev, valeurResiduelle: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateRentabilite}
                    disabled={!formData.investissementInitial || !formData.duree || !formData.tauxActualisation}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Analyser la rentabilité
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">VAN (Valeur Actuelle Nette)</span>
                        <span className="value" style={{ color: results.van >= 0 ? '#10B981' : '#EF4444' }}>
                            {Math.round(results.van).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row">
                        <span className="label">TRI (Taux de Rentabilité Interne)</span>
                        <span className="value">{results.tri.toFixed(1)}%</span>
                    </div>

                    <div className="result-row">
                        <span className="label">ROI (Retour sur Investissement)</span>
                        <span className="value">{results.roi.toFixed(1)}%</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Délai de récupération</span>
                        <span className="value">{results.delaiRecuperation} ans</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Détail des flux</span>
                        <span className="value"></span>
                    </div>

                    {results.fluxActualises.map((flux, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">Année {flux.annee}</span>
                                <span className="value">{Math.round(flux.fluxBrut).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">Flux actualisé</span>
                                <span className="value">
                                    {Math.round(flux.fluxActualise).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">Cumul actualisé</span>
                                <span className="value" style={{ color: flux.cumul >= 0 ? '#10B981' : '#EF4444' }}>
                                    {Math.round(flux.cumul).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default RentabiliteProjet;
