import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect } from 'react';

import { fonts } from '../../styles/theme';
import { mixpanelEvent } from '../layout/scripts';

declare global {
    interface Window {
        ga: any;
        dataLayer: any;
        Intercom: any;
    }
}

interface Props {
    formId: string;
    target?: string;
    onSubmit?: ($form: never) => void;
    afterSubmit?: () => void;
    trackingId?: string;
    className?: string;
    status?: string;
}

const HubspotStyle = styled.div`
    font-size: 11px !important;
    font-family: ${fonts.OpenSans_Regular} !important;
    line-height: 150% !important;

    p {
        font-size: 11px !important;
        font-family: ${fonts.OpenSans_Regular} !important;
        line-height: 150% !important;
    }

    .submitted-message {
        p,
        span {
            font-size: 14px !important;
        }
    }

    .hs-button {
        margin-top: 12px;
        margin-bottom: 0;
    }
`;

const HubspotForm = ({
    formId,
    target = 'hubspotForm',
    afterSubmit,
    onSubmit,
    trackingId,
    status,
    className = '',
}: Props) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        const trackOnSubmit = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const trackingName = trackingId && `-${trackingId}`;
            const params = (
                'event,button,click,cta-hubspot' +
                (trackingName || '') +
                (status ? ',' + status : '')
            ).split(',');
            params.unshift('send');
            if (window && window.ga) {
                window.ga.apply(window.ga, params);
            }
            if (window && window.dataLayer) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.dataLayer = window.dataLayer || [];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.dataLayer.push({
                    event: 'tracking_event',
                    platform: 'Marketing WebSite',
                    tag_name: 'container-hubspot-button-generic-click-displaymessage',
                });
            }
            mixpanelEvent({ params: params });
        };

        script.addEventListener('load', () => {
            if ((window as any).hsFormsOnReady) {
                (window as any).hsFormsOnReady.push(() => {
                    (window as any).hbspt.forms.create({
                        portalId: process.env.GATSBY_HUBSPOT_TRACKING_CODE,
                        formId: formId,
                        css: '',
                        target: '#' + target,
                        onFormReady: ($form: any) => {
                            // Obtenir l'élément DOM du formulaire
                            const formElement = document.querySelector(`#${target} form`);
                            if (formElement) {
                                // Supprimer les attributs 'for' des labels dans les champs cachés
                                const hiddenFields = formElement.querySelectorAll(
                                    '.hs-form-field[style*="display: none;"] label',
                                );
                                hiddenFields.forEach((label: Element) => {
                                    label.removeAttribute('for');
                                });
                            }
                        },
                        onFormSubmit: onSubmit
                            ? ($form: never) => {
                                  trackOnSubmit();
                                  return onSubmit($form);
                              }
                            : trackOnSubmit,
                        onFormSubmitted: afterSubmit ? afterSubmit : () => {},
                    });
                });
            }
        });
    }, [formId, afterSubmit, onSubmit, target, status, trackingId]);

    return (
        <HubspotStyle className={'hubspotForm' + (className && ' ' + className)}>
            <div id={target}></div>
        </HubspotStyle>
    );
};

export default HubspotForm;
