import { Button, Checkbox, FormControl, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

// Modifier le type LienAssocie
type LienAssocie = 'famille' | 'ami' | 'business' | 'couple' | 'autre';

// Modifier le type pour les besoins
type Besoin =
    | 'information'
    | 'benefices'
    | 'nondilution'
    | 'inalienabilite'
    | 'preemption'
    | 'sortieconjointe'
    | 'sortiedesaccord'
    | 'sortiealternative'
    | 'sortieforcee'
    | 'nonconcurrence'
    | 'exclusivite'
    | 'proprieteintellectuelle';

interface PacteData {
    nombreAssocies: string;
    lienAssocies: LienAssocie[];
    besoins: Besoin[];
}

interface PacteResults {
    clausesImpact: {
        clause: string;
        impact: string;
    }[];
    recommandations: string[];
}

const PacteAssocies: React.FC = () => {
    const [formData, setFormData] = useState<PacteData>({
        nombreAssocies: '2',
        lienAssocies: [],
        besoins: [],
    });

    const [results, setResults] = useState<PacteResults | null>(null);

    const calculatePacte = () => {
        const nbAssocies = parseInt(formData.nombreAssocies);
        const isCouple = formData.lienAssocies.includes('couple');
        const nbBesoins = formData.besoins.length;

        // Générer les clauses recommandées
        const clausesRecommandees = [
            formData.besoins.includes('information') &&
                "Clause d'information : droits d'information et d'audit sur la société",
            formData.besoins.includes('benefices') &&
                'Clause de répartition des bénéfices : modalités de distribution des dividendes',
            formData.besoins.includes('nondilution') &&
                'Clause de non-dilution : maintien du pourcentage de détention lors des augmentations de capital',
            formData.besoins.includes('inalienabilite') &&
                "Clause d'inaliénabilité : blocage des parts pendant une durée déterminée",
            formData.besoins.includes('preemption') &&
                'Droit de préemption : priorité de rachat des parts en cas de cession',
            formData.besoins.includes('sortieconjointe') &&
                'Clause de sortie conjointe : vente des parts minoritaires avec le majoritaire',
            formData.besoins.includes('sortiedesaccord') &&
                'Clause de sortie sur désaccord : sortie en cas de désaccord grave',
            formData.besoins.includes('sortiealternative') &&
                'Clause de sortie alternative : mécanisme de rachat ou vente réciproque',
            formData.besoins.includes('sortieforcee') &&
                'Clause de sortie forcée : obligation de vente des minoritaires',
            formData.besoins.includes('nonconcurrence') &&
                "Clause de non-concurrence : interdiction d'activités concurrentes",
            formData.besoins.includes('exclusivite') &&
                "Clause d'exclusivité : développement uniquement au sein de la société",
            formData.besoins.includes('proprieteintellectuelle') &&
                'Clause de propriété intellectuelle : protection des développements',
        ].filter(Boolean);

        // Si c'est un couple marié avec moins de 3 associés et peu de besoins
        if (isCouple && nbAssocies < 3 && nbBesoins <= 2) {
            setResults({
                clausesImpact: [],
                recommandations: ['', ...clausesRecommandees],
            });
            return;
        }

        // Sinon, afficher les clauses avec le message pour contacter un juriste
        setResults({
            clausesImpact: [],
            recommandations: clausesRecommandees,
        });
    };

    const handleLienChange = (lien: LienAssocie) => {
        setResults(null);
        setFormData(prev => ({
            ...prev,
            lienAssocies: prev.lienAssocies.includes(lien)
                ? prev.lienAssocies.filter(l => l !== lien)
                : [...prev.lienAssocies, lien],
        }));
    };

    const handleBesoinChange = (besoin: Besoin) => {
        setResults(null);
        setFormData(prev => ({
            ...prev,
            besoins: prev.besoins.includes(besoin) ? prev.besoins.filter(b => b !== besoin) : [...prev.besoins, besoin],
        }));
    };

    // Ajouter une fonction de validation
    const isFormValid = () => {
        const nbAssocies = parseInt(formData.nombreAssocies);
        return (
            nbAssocies >= 2 && // Au moins 2 associés
            formData.lienAssocies.length > 0 && // Au moins un lien sélectionné
            formData.besoins.length > 0 // Au moins un besoin sélectionné
        );
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur de pacte d'associés
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre d'associés"
                        type="number"
                        value={formData.nombreAssocies}
                        onChange={e => {
                            setResults(null);
                            const value = parseInt(e.target.value);
                            if (value >= 2) {
                                // N'accepter que les valeurs >= 2
                                setFormData(prev => ({ ...prev, nombreAssocies: e.target.value }));
                            }
                        }}
                        inputProps={{ min: 2 }} // Empêcher la saisie de valeurs < 2
                        placeholder="Ex: 2"
                        error={parseInt(formData.nombreAssocies) < 2}
                        helperText={
                            parseInt(formData.nombreAssocies) < 2 ? "Le nombre d'associés doit être d'au moins 2" : ''
                        }
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Lien avec vos associés
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.lienAssocies.includes('famille')}
                                onChange={() => handleLienChange('famille')}
                            />
                        }
                        label="Famille (hors couple)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.lienAssocies.includes('couple')}
                                onChange={() => handleLienChange('couple')}
                            />
                        }
                        label="Couple marié/pacsé"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.lienAssocies.includes('ami')}
                                onChange={() => handleLienChange('ami')}
                            />
                        }
                        label="Ami(s)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.lienAssocies.includes('business')}
                                onChange={() => handleLienChange('business')}
                            />
                        }
                        label="Relation professionnelle"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.lienAssocies.includes('autre')}
                                onChange={() => handleLienChange('autre')}
                            />
                        }
                        label="Autre"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Vos besoins
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('information')}
                                onChange={() => handleBesoinChange('information')}
                            />
                        }
                        label="Obtenir des informations détaillées sur la société"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('benefices')}
                                onChange={() => handleBesoinChange('benefices')}
                            />
                        }
                        label="Définir la répartition des bénéfices"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('nondilution')}
                                onChange={() => handleBesoinChange('nondilution')}
                            />
                        }
                        label="Maintenir son pourcentage de capital"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('inalienabilite')}
                                onChange={() => handleBesoinChange('inalienabilite')}
                            />
                        }
                        label="Bloquer la cession des parts"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('preemption')}
                                onChange={() => handleBesoinChange('preemption')}
                            />
                        }
                        label="Racheter en priorité les parts cédées"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('sortieconjointe')}
                                onChange={() => handleBesoinChange('sortieconjointe')}
                            />
                        }
                        label="Vendre ses parts avec le majoritaire"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('sortiedesaccord')}
                                onChange={() => handleBesoinChange('sortiedesaccord')}
                            />
                        }
                        label="Sortir en cas de désaccord"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('sortiealternative')}
                                onChange={() => handleBesoinChange('sortiealternative')}
                            />
                        }
                        label="Proposer un rachat ou une vente"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('sortieforcee')}
                                onChange={() => handleBesoinChange('sortieforcee')}
                            />
                        }
                        label="Forcer la vente des minoritaires"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('nonconcurrence')}
                                onChange={() => handleBesoinChange('nonconcurrence')}
                            />
                        }
                        label="Interdire la concurrence"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('exclusivite')}
                                onChange={() => handleBesoinChange('exclusivite')}
                            />
                        }
                        label="Développer l'activité uniquement dans la société"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.besoins.includes('proprieteintellectuelle')}
                                onChange={() => handleBesoinChange('proprieteintellectuelle')}
                            />
                        }
                        label="Protéger la propriété intellectuelle"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePacte}
                    disabled={!isFormValid()}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Valider
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', fontWeight: 'bold' }}>
                        <span className="label">
                            {formData.lienAssocies.includes('couple') &&
                            parseInt(formData.nombreAssocies) < 3 &&
                            formData.besoins.length <= 2 ? (
                                'Vous pouvez intégrer ces clauses dans les statuts :'
                            ) : (
                                <>
                                    Nous vous recommandons de vous rapprocher d'un juriste spécialisé pour rédiger votre
                                    pacte d'associés. Contactez Dougs !
                                    <br />
                                    <br />
                                    Voici les clauses à intégrer dans votre pacte d'associés.
                                </>
                            )}
                        </span>
                    </div>
                    {results.recommandations.map((recommandation, index) => (
                        <div key={index} className="result-row">
                            <span className="label" style={{ color: '#666' }}>
                                {recommandation}
                            </span>
                        </div>
                    ))}
                </Paper>
            )}
        </div>
    );
};

export default PacteAssocies;
