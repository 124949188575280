import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface CessionData {
    prixCession: string;
    valeurComptable: string;
    dureeDetention: string;
    regimeFiscal: 'ir' | 'is';
    typeCession: 'titres' | 'fonds';
    retraite: boolean;
}

interface CessionResults {
    plusValue: number;
    abattement: number;
    plusValueImposable: number;
    montantImpot: number;
    montantNet: number;
}

const PlusValueCession: React.FC = () => {
    const [formData, setFormData] = useState<CessionData>({
        prixCession: '',
        valeurComptable: '',
        dureeDetention: '',
        regimeFiscal: 'ir',
        typeCession: 'titres',
        retraite: false,
    });

    const [results, setResults] = useState<CessionResults | null>(null);

    const calculatePlusValue = () => {
        const prixCession = parseFloat(formData.prixCession) || 0;
        const valeurComptable = parseFloat(formData.valeurComptable) || 0;
        const dureeDetention = parseInt(formData.dureeDetention) || 0;

        // Calcul de la plus-value brute
        const plusValue = prixCession - valeurComptable;

        let abattement = 0;
        let tauxImposition = 0;

        if (formData.regimeFiscal === 'ir') {
            // Abattement pour durée de détention (titres de société)
            if (formData.typeCession === 'titres') {
                if (dureeDetention >= 8) {
                    abattement = plusValue * (formData.retraite ? 0.85 : 0.65); // 85% si départ en retraite, 65% sinon
                } else if (dureeDetention >= 4) {
                    abattement = plusValue * 0.5;
                } else if (dureeDetention >= 1) {
                    abattement = plusValue * 0.25;
                }
                tauxImposition = 0.3; // Flat tax 30%
            } else {
                // Fonds de commerce
                if (dureeDetention >= 5) {
                    abattement = plusValue * 0.5;
                }
                tauxImposition = 0.3333; // 33.33%
            }
        } else {
            // Régime IS
            tauxImposition = 0.25; // Taux IS normal
            if (plusValue <= 500000 && formData.typeCession === 'titres') {
                tauxImposition = 0.15; // Taux réduit PME
            }
        }

        const plusValueImposable = plusValue - abattement;
        const montantImpot = plusValueImposable * tauxImposition;
        const montantNet = prixCession - montantImpot;

        setResults({
            plusValue,
            abattement,
            plusValueImposable,
            montantImpot,
            montantNet,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de plus-value de cession
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.regimeFiscal}
                        onChange={e => setFormData(prev => ({ ...prev, regimeFiscal: e.target.value as 'ir' | 'is' }))}
                    >
                        <FormControlLabel
                            value="ir"
                            control={<Radio />}
                            label="Impôt sur le revenu"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="is"
                            control={<Radio />}
                            label="Impôt sur les sociétés"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeCession}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, typeCession: e.target.value as 'titres' | 'fonds' }))
                        }
                    >
                        <FormControlLabel
                            value="titres"
                            control={<Radio />}
                            label="Cession de titres"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="fonds"
                            control={<Radio />}
                            label="Cession de fonds"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Prix de cession (€)"
                        type="number"
                        value={formData.prixCession}
                        onChange={e => setFormData(prev => ({ ...prev, prixCession: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Valeur comptable (€)"
                        type="number"
                        value={formData.valeurComptable}
                        onChange={e => setFormData(prev => ({ ...prev, valeurComptable: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Durée de détention (années)"
                        type="number"
                        value={formData.dureeDetention}
                        onChange={e => setFormData(prev => ({ ...prev, dureeDetention: e.target.value }))}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                {formData.regimeFiscal === 'ir' && formData.typeCession === 'titres' && (
                    <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                        <RadioGroup
                            row
                            value={formData.retraite}
                            onChange={e => setFormData(prev => ({ ...prev, retraite: e.target.value === 'true' }))}
                        >
                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Départ en retraite"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Pas de départ en retraite"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                        </RadioGroup>
                    </FormControl>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePlusValue}
                    disabled={!formData.prixCession || !formData.valeurComptable}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer la plus-value
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Prix de cession</span>
                        <span className="value">{parseFloat(formData.prixCession).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Plus-value brute</span>
                        <span className="value">{Math.round(results.plusValue).toLocaleString('fr-FR')} €</span>
                    </div>
                    {results.abattement > 0 && (
                        <div className="result-row">
                            <span className="label">Abattement</span>
                            <span className="value">-{Math.round(results.abattement).toLocaleString('fr-FR')} €</span>
                        </div>
                    )}
                    <div className="result-row">
                        <span className="label">Plus-value imposable</span>
                        <span className="value">
                            {Math.round(results.plusValueImposable).toLocaleString('fr-FR')} €
                        </span>
                    </div>
                    <div className="result-row">
                        <span className="label">Impôt estimé</span>
                        <span className="value" style={{ color: 'red' }}>
                            -{Math.round(results.montantImpot).toLocaleString('fr-FR')} €
                        </span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Montant net après impôt</span>
                        <span className="value">{Math.round(results.montantNet).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default PlusValueCession;
