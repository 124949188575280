import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, Checkbox, FormControlLabel } from '@mui/material';

interface PersonnelData {
    salaireBrut: string;
    primes: string;
    avantagesNature: string;
    heuresSupp: string;
    cadre: boolean;
    tempsPartiel: boolean;
    tauxTempsPartiel: string;
    mutuelle: boolean;
    ticketsRestaurant: boolean;
    transport: string;
}

interface PersonnelResults {
    coutsMensuels: {
        label: string;
        montant: number;
        description: string;
    }[];
    coutsAnnuels: {
        label: string;
        montant: number;
        description: string;
    }[];
    totalMensuel: number;
    totalAnnuel: number;
}

const CoutPersonnel: React.FC = () => {
    const [formData, setFormData] = useState<PersonnelData>({
        salaireBrut: '',
        primes: '0',
        avantagesNature: '0',
        heuresSupp: '0',
        cadre: false,
        tempsPartiel: false,
        tauxTempsPartiel: '100',
        mutuelle: true,
        ticketsRestaurant: false,
        transport: '0',
    });

    const [results, setResults] = useState<PersonnelResults | null>(null);

    const calculateCout = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;
        const primes = parseFloat(formData.primes) || 0;
        const avantagesNature = parseFloat(formData.avantagesNature) || 0;
        const heuresSupp = parseFloat(formData.heuresSupp) || 0;
        const transport = parseFloat(formData.transport) || 0;
        const tauxTempsPartiel = parseFloat(formData.tauxTempsPartiel) / 100;

        // Base de calcul
        const baseBrute = (salaireBrut + primes + avantagesNature + heuresSupp) * tauxTempsPartiel;

        // Charges patronales
        const tauxChargesPatronales = formData.cadre ? 0.45 : 0.42;
        const chargesPatronales = baseBrute * tauxChargesPatronales;

        // Coûts annexes mensuels
        const coutMutuelle = formData.mutuelle ? 25 : 0;
        const coutTickets = formData.ticketsRestaurant ? 22 * 5.5 : 0; // 22 jours x 5.50€ part patronale

        // Coûts mensuels
        const coutsMensuels = [
            {
                label: 'Salaire brut',
                montant: baseBrute,
                description: 'Base de rémunération mensuelle',
            },
            {
                label: 'Charges patronales',
                montant: chargesPatronales,
                description: `${(tauxChargesPatronales * 100).toFixed(0)}% de charges patronales`,
            },
            {
                label: "Mutuelle d'entreprise",
                montant: coutMutuelle,
                description: 'Part patronale de la mutuelle',
            },
            {
                label: 'Tickets restaurant',
                montant: coutTickets,
                description: 'Part patronale des tickets restaurant',
            },
            {
                label: 'Transport',
                montant: transport,
                description: 'Remboursement transport',
            },
        ];

        const totalMensuel = coutsMensuels.reduce((acc, cout) => acc + cout.montant, 0);

        // Coûts annuels
        const coutsAnnuels = [
            {
                label: 'Rémunération annuelle',
                montant: totalMensuel * 12,
                description: 'Coût mensuel × 12 mois',
            },
            {
                label: '13ème mois',
                montant: baseBrute,
                description: "Prime de fin d'année",
            },
            {
                label: 'Congés payés',
                montant: baseBrute * 0.1,
                description: 'Provision pour congés payés (10%)',
            },
            {
                label: 'Formation',
                montant: baseBrute * 12 * 0.01,
                description: 'Budget formation (1%)',
            },
            {
                label: 'Médecine du travail',
                montant: 100,
                description: 'Cotisation annuelle médecine du travail',
            },
        ];

        const totalAnnuel = coutsAnnuels.reduce((acc, cout) => acc + cout.montant, 0);

        setResults({
            coutsMensuels,
            coutsAnnuels,
            totalMensuel,
            totalAnnuel,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur du coût du personnel
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Salaire brut mensuel (€)"
                        type="number"
                        value={formData.salaireBrut}
                        onChange={e => setFormData(prev => ({ ...prev, salaireBrut: e.target.value }))}
                        placeholder="Ex: 2500"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Primes mensuelles (€)"
                        type="number"
                        value={formData.primes}
                        onChange={e => setFormData(prev => ({ ...prev, primes: e.target.value }))}
                        placeholder="Ex: 200"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Avantages en nature (€)"
                        type="number"
                        value={formData.avantagesNature}
                        onChange={e => setFormData(prev => ({ ...prev, avantagesNature: e.target.value }))}
                        placeholder="Ex: 0"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Heures supplémentaires (€)"
                        type="number"
                        value={formData.heuresSupp}
                        onChange={e => setFormData(prev => ({ ...prev, heuresSupp: e.target.value }))}
                        placeholder="Ex: 0"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.cadre}
                                onChange={e => setFormData(prev => ({ ...prev, cadre: e.target.checked }))}
                            />
                        }
                        label="Statut cadre"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.tempsPartiel}
                                onChange={e => setFormData(prev => ({ ...prev, tempsPartiel: e.target.checked }))}
                            />
                        }
                        label="Temps partiel"
                    />
                </FormControl>

                {formData.tempsPartiel && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Taux temps partiel (%)"
                            type="number"
                            value={formData.tauxTempsPartiel}
                            onChange={e => setFormData(prev => ({ ...prev, tauxTempsPartiel: e.target.value }))}
                            placeholder="Ex: 80"
                        />
                    </FormControl>
                )}

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.mutuelle}
                                onChange={e => setFormData(prev => ({ ...prev, mutuelle: e.target.checked }))}
                            />
                        }
                        label="Mutuelle d'entreprise"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.ticketsRestaurant}
                                onChange={e => setFormData(prev => ({ ...prev, ticketsRestaurant: e.target.checked }))}
                            />
                        }
                        label="Tickets restaurant"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Remboursement transport (€)"
                        type="number"
                        value={formData.transport}
                        onChange={e => setFormData(prev => ({ ...prev, transport: e.target.value }))}
                        placeholder="Ex: 75"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateCout}
                    disabled={!formData.salaireBrut}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer le coût total
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Coûts mensuels</span>
                        <span className="value"></span>
                    </div>
                    {results.coutsMensuels.map((cout, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{cout.label}</span>
                                <span className="value">{Math.round(cout.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {cout.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}
                    <div className="result-row highlight">
                        <span className="label">Total mensuel</span>
                        <span className="value">{Math.round(results.totalMensuel).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Coûts annuels</span>
                        <span className="value"></span>
                    </div>
                    {results.coutsAnnuels.map((cout, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{cout.label}</span>
                                <span className="value">{Math.round(cout.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {cout.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}
                    <div className="result-row highlight">
                        <span className="label">Coût total annuel</span>
                        <span className="value">{Math.round(results.totalAnnuel).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default CoutPersonnel;
