import { Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface CapitalData {
    formeJuridique: 'sarl' | 'sas' | 'eurl' | 'sasu';
    activite: 'commerce' | 'service' | 'artisanat' | 'liberal';
    investissementInitial: string;
    besoinTresorerie: string;
}

interface CapitalResults {
    capitalMinimum: number;
    capitalRecommande: number;
    capitalOptimal: number;
    justification: string[];
}

const CapitalSocial: React.FC = () => {
    const [formData, setFormData] = useState<CapitalData>({
        formeJuridique: 'sarl',
        activite: 'commerce',
        investissementInitial: '',
        besoinTresorerie: '',
    });

    const [results, setResults] = useState<CapitalResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateCapital = () => {
        const investissement = parseFloat(formData.investissementInitial) || 0;
        const tresorerie = parseFloat(formData.besoinTresorerie) || 0;

        // Calcul du capital minimum légal
        let capitalMinimum = 1; // Par défaut 1€
        if (formData.formeJuridique === 'sarl' && formData.activite === 'liberal') {
            capitalMinimum = 15000; // SELARL
        }

        // Calcul du capital recommandé (basé sur les besoins réels)
        const capitalRecommande = 1000;

        // Calcul du capital optimal (avec marge de sécurité)
        const capitalOptimal = investissement + tresorerie - capitalRecommande;

        // Justifications des recommandations
        const justification = [
            `Le capital minimum légal est de ${capitalMinimum}€ pour votre forme juridique.`,
            `Nous recommandons un capital de ${Math.round(capitalRecommande)}€ pour couvrir 30% des investissements et 50% du besoin en trésorerie.`,
            `Le capital optimal de ${Math.round(capitalOptimal)}€ offre une marge de sécurité supplémentaire de 20%.`,
            capitalOptimal > 50000
                ? 'Un capital social élevé renforce votre crédibilité auprès des banques et partenaires.'
                : 'Un capital social modéré est adapté à votre activité.',
        ].filter(item => item !== '');

        setResults({
            capitalMinimum,
            capitalRecommande,
            capitalOptimal,
            justification,
        });
    };

    const handleSubmit = () => {
        calculateCapital();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de capital social
                </Typography>

                <Typography
                    variant="body2"
                    sx={{
                        mb: 3,
                        p: 2,
                        bgcolor: '#fff3e0',
                        borderRadius: 1,
                        color: '#e65100',
                    }}
                >
                    ⚠️ Note : Ce calculateur n'est pas adapté aux sociétés de transport qui sont soumises à des
                    exigences spécifiques de capital social minimum.
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Investissements initiaux prévus (€)"
                        type="number"
                        value={formData.investissementInitial}
                        onChange={e => setFormData(prev => ({ ...prev, investissementInitial: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Besoin en trésorerie (€)"
                        type="number"
                        value={formData.besoinTresorerie}
                        onChange={e => setFormData(prev => ({ ...prev, besoinTresorerie: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.investissementInitial || !formData.besoinTresorerie}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer le capital social
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Capital minimum légal</span>
                        <span className="value">{Math.round(results.capitalMinimum).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Capital recommandé</span>
                        <span className="value">{Math.round(results.capitalRecommande).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Apport en compte courant recommandé</span>
                        <span className="value">{Math.round(results.capitalOptimal).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default CapitalSocial;
