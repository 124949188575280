import { Button, FormControl, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface MonthlyData {
    ventes: number;
    delaiClient: number;
    achats: number;
    delaiFournisseur: number;
    salaires: number;
    charges: number;
    autres: number;
}

interface TresorerieData {
    soldeInitial: string;
    monthlyData: MonthlyData[];
}

interface MonthlyResults {
    mois: string;
    encaissements: number;
    decaissements: number;
    solde: number;
    soldeProgressif: number;
}

const moisLabels = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];

const PlanTresorerie: React.FC = () => {
    const [formData, setFormData] = useState<TresorerieData>({
        soldeInitial: '',
        monthlyData: Array(12).fill({
            ventes: 0,
            delaiClient: 30,
            achats: 0,
            delaiFournisseur: 30,
            salaires: 0,
            charges: 0,
            autres: 0,
        }),
    });

    const [results, setResults] = useState<MonthlyResults[]>(null);

    const calculateTresorerie = () => {
        const soldeInitial = parseFloat(formData.soldeInitial) || 0;
        const monthlyResults: MonthlyResults[] = [];
        let soldeProgressif = soldeInitial;

        formData.monthlyData.forEach((data, index) => {
            // Calcul des encaissements (avec délai client)
            const encaissementsMois = data.ventes;
            let encaissements = encaissementsMois;
            if (data.delaiClient > 30) {
                encaissements = index > 0 ? formData.monthlyData[index - 1].ventes : 0;
            }

            // Calcul des décaissements (avec délai fournisseur)
            const decaissementsAchats = data.achats;
            let decaissements = data.salaires + data.charges + data.autres;
            if (data.delaiFournisseur > 30) {
                decaissements += index > 0 ? formData.monthlyData[index - 1].achats : 0;
            } else {
                decaissements += decaissementsAchats;
            }

            // Calcul du solde du mois
            const soldeMois = encaissements - decaissements;
            soldeProgressif += soldeMois;

            monthlyResults.push({
                mois: moisLabels[index],
                encaissements,
                decaissements,
                solde: soldeMois,
                soldeProgressif,
            });
        });

        setResults(monthlyResults);
    };

    const handleMonthlyDataChange = (index: number, field: keyof MonthlyData, value: string) => {
        const newMonthlyData = [...formData.monthlyData];
        newMonthlyData[index] = {
            ...newMonthlyData[index],
            [field]: parseFloat(value) || 0,
        };
        setFormData(prev => ({ ...prev, monthlyData: newMonthlyData }));
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Plan de trésorerie sur 12 mois
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Solde initial de trésorerie (€)"
                        type="number"
                        value={formData.soldeInitial}
                        onChange={e => setFormData(prev => ({ ...prev, soldeInitial: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                {moisLabels.map((mois, index) => (
                    <Paper key={mois} elevation={0} sx={{ p: 2, mb: 2, backgroundColor: '#f8f9fa' }}>
                        <Typography variant="subtitle1" gutterBottom>
                            {mois}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Ventes (€)"
                                    type="number"
                                    value={formData.monthlyData[index].ventes || ''}
                                    onChange={e => handleMonthlyDataChange(index, 'ventes', e.target.value)}
                                    placeholder="Ex: 5000"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Délai client (jours)"
                                    type="number"
                                    value={formData.monthlyData[index].delaiClient || ''}
                                    onChange={e => handleMonthlyDataChange(index, 'delaiClient', e.target.value)}
                                    placeholder="Ex: 30"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Achats (€)"
                                    type="number"
                                    value={formData.monthlyData[index].achats || ''}
                                    onChange={e => handleMonthlyDataChange(index, 'achats', e.target.value)}
                                    placeholder="Ex: 3000"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Délai fournisseur (jours)"
                                    type="number"
                                    value={formData.monthlyData[index].delaiFournisseur || ''}
                                    onChange={e => handleMonthlyDataChange(index, 'delaiFournisseur', e.target.value)}
                                    placeholder="Ex: 30"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Salaires (€)"
                                    type="number"
                                    value={formData.monthlyData[index].salaires || ''}
                                    onChange={e => handleMonthlyDataChange(index, 'salaires', e.target.value)}
                                    placeholder="Ex: 2000"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Charges sociales (€)"
                                    type="number"
                                    value={formData.monthlyData[index].charges || ''}
                                    onChange={e => handleMonthlyDataChange(index, 'charges', e.target.value)}
                                    placeholder="Ex: 1000"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Autres dépenses (€)"
                                    type="number"
                                    value={formData.monthlyData[index].autres || ''}
                                    onChange={e => handleMonthlyDataChange(index, 'autres', e.target.value)}
                                    placeholder="Ex: 500"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                ))}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateTresorerie}
                    disabled={!formData.soldeInitial}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer le plan de trésorerie
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.map((result, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">{result.mois}</span>
                                <span className="value"></span>
                            </div>
                            <div className="result-row">
                                <span className="label">Encaissements</span>
                                <span className="value">
                                    {Math.round(result.encaissements).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row">
                                <span className="label">Décaissements</span>
                                <span className="value">
                                    -{Math.round(result.decaissements).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row">
                                <span className="label">Solde du mois</span>
                                <span className="value" style={{ color: result.solde >= 0 ? undefined : 'red' }}>
                                    {Math.round(result.solde).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className={`result-row ${index === results.length - 1 ? 'highlight' : ''}`}>
                                <span className="label">Solde progressif</span>
                                <span
                                    className="value"
                                    style={{ color: result.soldeProgressif >= 0 ? undefined : 'red' }}
                                >
                                    {Math.round(result.soldeProgressif).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                        </React.Fragment>
                    ))}
                </Paper>
            )}
        </div>
    );
};

export default PlanTresorerie;
