import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface CoutData {
    achatsMatieres: string;
    mainOeuvreDirecte: string;
    fraisProduction: string;
    fraisGeneraux: string;
    quantiteProduite: string;
    typeCalcul: 'complet' | 'partiel';
    margeCommerciale: string;
}

interface CoutResults {
    coutProduction: number;
    coutRevient: number;
    coutUnitaire: number;
    prixVenteHT: number;
    margeUnitaire: number;
    tauxMarge: number;
    details: {
        label: string;
        montant: number;
        pourcentage: number;
        description: string;
    }[];
    recommandations: string[];
}

const CoutRevient: React.FC = () => {
    const [formData, setFormData] = useState<CoutData>({
        achatsMatieres: '',
        mainOeuvreDirecte: '',
        fraisProduction: '',
        fraisGeneraux: '',
        quantiteProduite: '',
        typeCalcul: 'complet',
        margeCommerciale: '30',
    });

    const [results, setResults] = useState<CoutResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateCout = () => {
        const achatsMatieres = parseFloat(formData.achatsMatieres) || 0;
        const mainOeuvreDirecte = parseFloat(formData.mainOeuvreDirecte) || 0;
        const fraisProduction = parseFloat(formData.fraisProduction) || 0;
        const fraisGeneraux = parseFloat(formData.fraisGeneraux) || 0;
        const quantiteProduite = parseFloat(formData.quantiteProduite) || 1;
        const margeCommerciale = parseFloat(formData.margeCommerciale) / 100;

        // Calcul des coûts directs
        const coutsDirects = achatsMatieres + mainOeuvreDirecte;

        // Calcul du coût de production
        const coutProduction = coutsDirects + fraisProduction;

        // Calcul du coût de revient selon la méthode
        const coutRevient = formData.typeCalcul === 'complet' ? coutProduction + fraisGeneraux : coutProduction;

        // Calcul des coûts unitaires
        const coutUnitaire = coutRevient / quantiteProduite;

        // Calcul du prix de vente et de la marge
        const prixVenteHT = coutUnitaire / (1 - margeCommerciale);
        const margeUnitaire = prixVenteHT - coutUnitaire;
        const tauxMarge = (margeUnitaire / prixVenteHT) * 100;

        // Détail des coûts
        const details = [
            {
                label: 'Achats de matières',
                montant: achatsMatieres,
                pourcentage: (achatsMatieres / coutRevient) * 100,
                description: 'Coûts directs des matières premières',
            },
            {
                label: "Main d'œuvre directe",
                montant: mainOeuvreDirecte,
                pourcentage: (mainOeuvreDirecte / coutRevient) * 100,
                description: "Coûts directs de la main d'œuvre",
            },
            {
                label: 'Frais de production',
                montant: fraisProduction,
                pourcentage: (fraisProduction / coutRevient) * 100,
                description: 'Frais indirects de production',
            },
        ];

        if (formData.typeCalcul === 'complet') {
            details.push({
                label: 'Frais généraux',
                montant: fraisGeneraux,
                pourcentage: (fraisGeneraux / coutRevient) * 100,
                description: "Frais de structure et d'administration",
            });
        }

        // Recommandations
        const recommandations = [];

        if (achatsMatieres / coutRevient > 0.6) {
            recommandations.push('Le coût des matières est très élevé, négociez avec vos fournisseurs');
        }

        if (mainOeuvreDirecte / coutRevient > 0.5) {
            recommandations.push("Optimisez vos processus de production pour réduire les coûts de main d'œuvre");
        }

        if (tauxMarge < 20) {
            recommandations.push("Votre marge est faible, envisagez d'augmenter vos prix ou de réduire vos coûts");
        }

        if (quantiteProduite < 100) {
            recommandations.push('Augmentez votre volume de production pour réduire les coûts unitaires');
        }

        setResults({
            coutProduction,
            coutRevient,
            coutUnitaire,
            prixVenteHT,
            margeUnitaire,
            tauxMarge,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateCout();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de coût de revient
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeCalcul}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, typeCalcul: e.target.value as 'complet' | 'partiel' }))
                        }
                    >
                        <FormControlLabel
                            value="complet"
                            control={<Radio />}
                            label="Coût complet"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="partiel"
                            control={<Radio />}
                            label="Coût partiel"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Achats de matières (€)"
                        type="number"
                        value={formData.achatsMatieres}
                        onChange={e => setFormData(prev => ({ ...prev, achatsMatieres: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Main d'œuvre directe (€)"
                        type="number"
                        value={formData.mainOeuvreDirecte}
                        onChange={e => setFormData(prev => ({ ...prev, mainOeuvreDirecte: e.target.value }))}
                        placeholder="Ex: 15000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Frais de production (€)"
                        type="number"
                        value={formData.fraisProduction}
                        onChange={e => setFormData(prev => ({ ...prev, fraisProduction: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                {formData.typeCalcul === 'complet' && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Frais généraux (€)"
                            type="number"
                            value={formData.fraisGeneraux}
                            onChange={e => setFormData(prev => ({ ...prev, fraisGeneraux: e.target.value }))}
                            placeholder="Ex: 8000"
                        />
                    </FormControl>
                )}

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Quantité produite"
                        type="number"
                        value={formData.quantiteProduite}
                        onChange={e => setFormData(prev => ({ ...prev, quantiteProduite: e.target.value }))}
                        placeholder="Ex: 1000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Marge commerciale (%)"
                        type="number"
                        value={formData.margeCommerciale}
                        onChange={e => setFormData(prev => ({ ...prev, margeCommerciale: e.target.value }))}
                        placeholder="Ex: 30"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.achatsMatieres || !formData.quantiteProduite}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer le coût de revient
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">
                                    {Math.round(detail.montant).toLocaleString('fr-FR')} € (
                                    {detail.pourcentage.toFixed(1)}%)
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Coût de revient total</span>
                        <span className="value">{Math.round(results.coutRevient).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Coût unitaire</span>
                        <span className="value">{results.coutUnitaire.toFixed(2)} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Prix de vente HT conseillé</span>
                        <span className="value">{results.prixVenteHT.toFixed(2)} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Marge unitaire</span>
                        <span className="value">
                            {results.margeUnitaire.toFixed(2)} € ({results.tauxMarge.toFixed(1)}%)
                        </span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default CoutRevient;
