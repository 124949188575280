import React, { useState } from 'react';
import {
    TextField,
    Button,
    Paper,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
} from '@mui/material';

interface ImpactData {
    consommationEnergie: string;
    deplacements: string;
    dechets: string;
    eau: string;
    numerique: string;
    secteurActivite: 'commerce' | 'service' | 'industrie';
    options: {
        enr: boolean;
        tri: boolean;
        telecommute: boolean;
        dematerialisation: boolean;
    };
}

interface ImpactResults {
    emissions: {
        categorie: string;
        montant: number;
        unite: string;
        details: string;
    }[];
    totalCO2: number;
    score: {
        valeur: number;
        niveau: string;
    };
    economies: {
        label: string;
        reduction: number;
        investissement: number;
    }[];
    recommandations: string[];
}

const ImpactEcologique: React.FC = () => {
    const [formData, setFormData] = useState<ImpactData>({
        consommationEnergie: '',
        deplacements: '',
        dechets: '',
        eau: '',
        numerique: '',
        secteurActivite: 'commerce',
        options: {
            enr: false,
            tri: false,
            telecommute: false,
            dematerialisation: false,
        },
    });

    const [results, setResults] = useState<ImpactResults | null>(null);

    const calculateImpact = () => {
        // Conversion des données
        const energie = parseFloat(formData.consommationEnergie) || 0; // en kWh
        const deplacements = parseFloat(formData.deplacements) || 0; // en km
        const dechets = parseFloat(formData.dechets) || 0; // en kg
        const eau = parseFloat(formData.eau) || 0; // en m3
        const numerique = parseFloat(formData.numerique) || 0; // en Go

        // Calcul des émissions par catégorie
        const emissions = [
            {
                categorie: 'Énergie',
                montant: energie * (formData.options.enr ? 0.04 : 0.1),
                unite: 'kgCO2e',
                details: "Émissions liées à la consommation d'énergie",
            },
            {
                categorie: 'Déplacements',
                montant: deplacements * 0.2,
                unite: 'kgCO2e',
                details: 'Émissions liées aux déplacements professionnels',
            },
            {
                categorie: 'Déchets',
                montant: dechets * (formData.options.tri ? 0.5 : 1),
                unite: 'kgCO2e',
                details: 'Émissions liées à la gestion des déchets',
            },
            {
                categorie: 'Eau',
                montant: eau * 0.3,
                unite: 'kgCO2e',
                details: "Émissions liées à la consommation d'eau",
            },
            {
                categorie: 'Numérique',
                montant: numerique * (formData.options.dematerialisation ? 0.01 : 0.02),
                unite: 'kgCO2e',
                details: "Émissions liées à l'usage du numérique",
            },
        ];

        // Calcul du total des émissions
        const totalCO2 = emissions.reduce((sum, item) => sum + item.montant, 0);

        // Calcul du score environnemental
        let score = 100 - totalCO2 / 1000;
        let niveau = '';
        if (score >= 80) niveau = 'Excellent';
        else if (score >= 60) niveau = 'Bon';
        else if (score >= 40) niveau = 'Moyen';
        else niveau = 'À améliorer';

        // Calcul des économies potentielles
        const economies = [
            {
                label: 'Passage aux énergies renouvelables',
                reduction: energie * 0.06,
                investissement: energie * 2,
            },
            {
                label: 'Optimisation du tri des déchets',
                reduction: dechets * 0.5,
                investissement: 1000,
            },
            {
                label: 'Télétravail partiel',
                reduction: deplacements * 0.1,
                investissement: 500,
            },
            {
                label: 'Dématérialisation',
                reduction: numerique * 0.01,
                investissement: 2000,
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (!formData.options.enr && energie > 10000) {
            recommandations.push('Étudiez le passage aux énergies renouvelables');
        }

        if (!formData.options.tri && dechets > 1000) {
            recommandations.push('Mettez en place un système de tri sélectif');
        }

        if (!formData.options.telecommute && deplacements > 5000) {
            recommandations.push('Développez le télétravail pour réduire les déplacements');
        }

        if (eau > 1000) {
            recommandations.push("Installez des systèmes d'économie d'eau");
        }

        if (!formData.options.dematerialisation && numerique > 500) {
            recommandations.push('Optimisez votre infrastructure numérique');
        }

        setResults({
            emissions,
            totalCO2,
            score: {
                valeur: score,
                niveau,
            },
            economies,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Impact écologique
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.secteurActivite}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                secteurActivite: e.target.value as 'commerce' | 'service' | 'industrie',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="industrie"
                            control={<Radio />}
                            label="Industrie"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Consommation d'énergie (kWh/an)"
                        type="number"
                        value={formData.consommationEnergie}
                        onChange={e => setFormData(prev => ({ ...prev, consommationEnergie: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Déplacements professionnels (km/an)"
                        type="number"
                        value={formData.deplacements}
                        onChange={e => setFormData(prev => ({ ...prev, deplacements: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Production de déchets (kg/an)"
                        type="number"
                        value={formData.dechets}
                        onChange={e => setFormData(prev => ({ ...prev, dechets: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Consommation d'eau (m3/an)"
                        type="number"
                        value={formData.eau}
                        onChange={e => setFormData(prev => ({ ...prev, eau: e.target.value }))}
                        placeholder="Ex: 500"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Usage numérique (Go/an)"
                        type="number"
                        value={formData.numerique}
                        onChange={e => setFormData(prev => ({ ...prev, numerique: e.target.value }))}
                        placeholder="Ex: 1000"
                    />
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                    Actions en place
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.options.enr}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        options: { ...prev.options, enr: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Énergies renouvelables"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.options.tri}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        options: { ...prev.options, tri: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Tri sélectif"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.options.telecommute}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        options: { ...prev.options, telecommute: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Télétravail"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.options.dematerialisation}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        options: { ...prev.options, dematerialisation: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Dématérialisation"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateImpact}
                    disabled={!formData.consommationEnergie}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer l'impact
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">Score environnemental</span>
                        <span className="value">{Math.round(results.score.valeur)}/100</span>
                    </div>

                    <div
                        className="result-row"
                        style={{
                            backgroundColor:
                                results.score.valeur >= 60
                                    ? '#E8F5E9'
                                    : results.score.valeur >= 40
                                      ? '#FFF3E0'
                                      : '#FFEBEE',
                        }}
                    >
                        <span className="label">Niveau</span>
                        <span className="value">{results.score.niveau}</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Émissions par poste</span>
                        <span className="value"></span>
                    </div>

                    {results.emissions.map((emission, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{emission.categorie}</span>
                                <span className="value">
                                    {Math.round(emission.montant)} {emission.unite}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {emission.details}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Total des émissions</span>
                        <span className="value">{Math.round(results.totalCO2)} kgCO2e</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Économies potentielles</span>
                        <span className="value"></span>
                    </div>

                    {results.economies.map((economie, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{economie.label}</span>
                                <span className="value">-{Math.round(economie.reduction)} kgCO2e</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">Investissement estimé</span>
                                <span className="value">{Math.round(economie.investissement)} €</span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default ImpactEcologique;
