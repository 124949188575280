import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';

import { ROUTES } from '../../../../constants/const/routes.const';
import { useHeaderContext } from '../../../../contexts/headerContextProvider';
import Dropdown from '../../../dropdown/Dropdown';
import SearchForm from '../../../media/SearchForm';
import { HEADER } from '../constants/header.const';
import SearchIcon from './../../../../images/icons/search.svg';
import dougsLogo from './../../../../images/logo/logo-dougs.svg';
import * as styles from './header-media.module.scss';

const MediaHeader = styled.div<{ hasReachedScrollBreakpoint: boolean; isScrollingDown: boolean; headerHeight: number }>`
    position: ${props =>
        props.hasReachedScrollBreakpoint && props.isScrollingDown ? 'fixed !important' : 'relative !important'};
    top: ${props =>
        props.hasReachedScrollBreakpoint && props.isScrollingDown
            ? `${props.headerHeight - (HEADER.HEIGHT.MAIN + HEADER.HEIGHT.MEDIA)}px !important`
            : `0px !important`};
`;

const HeaderMedia = () => {
    const { stickyHeader, isScrollingDown } = useHeaderContext();

    const [opened, setOpened] = useState(false);

    const data = useStaticQuery(graphql`
        query HeaderMediaQuery {
            allPrismicTag(filter: { data: { supertag: { eq: true } } }, sort: { data: { name: ASC } }) {
                nodes {
                    id
                    uri
                    data {
                        name
                    }
                }
            }
            prismicVideos: allPrismicVideo(filter: { data: { indexed: { eq: true } } }) {
                nodes {
                    id
                }
            }
        }
    `);

    const categories = data.allPrismicTag.nodes as Queries.PrismicTag[];
    const showVideoHeader = data.prismicVideos.nodes.length > 0;

    return (
        <>
            <MediaHeader
                hasReachedScrollBreakpoint={stickyHeader.hasReachedScrollBreakpoint}
                headerHeight={stickyHeader.height}
                isScrollingDown={isScrollingDown}
                className={`${styles.headerMedia} ${
                    stickyHeader.hasReachedScrollBreakpoint && isScrollingDown ? 'scrolled' : ''
                }`}
            >
                <div className="container">
                    <div className={styles.headerMediaNavigation}>
                        <Link to={ROUTES.HOME.uri} className={styles.headerMediaLogo}>
                            <img className="img-fluid" src={dougsLogo as unknown as string} alt="logo-dougs" />
                        </Link>
                        <nav>
                            <ul>
                                <li>
                                    <Link
                                        to={ROUTES.MEDIA.uri}
                                        data-ga4="topbar-bar2-text-title1-click-link"
                                        data-ga-send="event,button,click,blog-header-blog"
                                        activeClassName={'active'}
                                    >
                                        {ROUTES.MEDIA.name}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={ROUTES.WEBINAR.uri}
                                        data-ga4="topbar-bar2-text-title4-click-link"
                                        data-ga-send="event,button,click,blog-header-webinar"
                                        partiallyActive={true}
                                        activeClassName={'active'}
                                    >
                                        {ROUTES.WEBINAR.name}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={ROUTES.SIMULATORS.uri}
                                        partiallyActive={true}
                                        activeClassName={'active'}
                                        data-ga4="topbar-bar2-text-title3-click-link"
                                        data-ga-send="event,button,click,blog-header-simulateurs"
                                    >
                                        {ROUTES.SIMULATORS.name}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={ROUTES.GUIDES.uri}
                                        data-ga4="topbar-bar2-text-title2-click-link"
                                        data-ga-send="event,button,click,blog-header-guides"
                                        partiallyActive={true}
                                        activeClassName={'active'}
                                    >
                                        {ROUTES.GUIDES.name}
                                    </Link>
                                </li>
                                {showVideoHeader && (
                                    <li>
                                        <Link
                                            to={ROUTES.VIDEOS.uri}
                                            data-ga4="topbar-bar2-text-title5-click-link"
                                            data-ga-send="event,button,click,blog-header-videos"
                                            partiallyActive={true}
                                            activeClassName={'active'}
                                        >
                                            {ROUTES.VIDEOS.name}
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    </div>
                    <div className={styles.headerMediaFilter}>
                        <Dropdown
                            className={styles.headerMediaCategories}
                            data-ga4="topbar-level1-button-dropdown1-click-dropdownlist"
                            data-ga-send="event,button,click,blog-header-categorie"
                            title="Choisissez une catégorie"
                        >
                            {categories.map((tag, index) => (
                                <Link
                                    to={tag.uri}
                                    className="link-gray-primary"
                                    key={index}
                                    data-ga-send={`event,button,click,blog-header-categorie-${tag.uri}`}
                                    data-ga4={`topbar-level2-dropdown-text-title${index + 1}-click-link`}
                                >
                                    {tag.data.name}
                                </Link>
                            ))}
                        </Dropdown>
                        <button
                            type="button"
                            className={`${styles.headerMediaSearch} ml-1`}
                            onClick={() => setOpened(true)}
                            data-ga4="topbar-level1-button-icon1-click-search"
                            data-ga-send="event,button,click,blog-search"
                        >
                            <SearchIcon />
                        </button>
                    </div>
                </div>
            </MediaHeader>
            <SearchForm opened={opened} setOpened={setOpened} />
        </>
    );
};
export default HeaderMedia;
