import { getImageData } from 'gatsby-plugin-image';
import { RichTextBlock } from 'prismic-reactjs';
import { DetailedReactHTMLElement } from 'react';

import useParseContent from '../../hooks/useParseContent';

export const slugify = (text: string): string => {
    if (typeof text !== 'string') {
        return '';
    }

    return text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
};

export const unbreackableSpace = (text: string): string => {
    if (typeof text !== 'string') {
        return '';
    }

    return text
        .replace(/\s\?/g, '\u00A0?')
        .replace(/\s\!/g, '\u00A0!')
        .replace(/\s;/g, '\u00A0;')
        .replace(/\s€/g, '\u00A0€')
        .replace(/\s:/g, '\u00A0:');
};

export const getSummaryItems = (
    slices: Queries.PrismicVideoDataBody[] | Queries.PrismicPostDataBody[],
): { slug: string; label: string }[] => {
    const summaryItems = [];

    const filtredSlices = slices.filter(slice => ['normal_text', 'wordpresscontent'].includes(slice.slice_type)) as
        | Queries.PrismicPostDataBodyNormalText[]
        | Queries.PrismicPostDataBodyWordpresscontent[];

    filtredSlices.forEach(slice => {
        if (slice.slice_type === 'normal_text') {
            const headings = slice.primary.content_text.richText.filter(elem => elem.type === 'heading2');
            return headings.forEach(heading => {
                summaryItems.push({ slug: slugify(heading.text), label: unbreackableSpace(heading.text) });
            });
        }
        if (slice.slice_type === 'wordpresscontent') {
            const parsedSlice = useParseContent(slice.primary.content.text) as DetailedReactHTMLElement<
                { children: string },
                HTMLElement
            >[];

            if (Array.isArray(parsedSlice)) {
                const headings = parsedSlice.filter(elem => elem.type === 'h2');
                return headings.forEach(heading => {
                    summaryItems.push({
                        slug: slugify(heading.props.children),
                        label: unbreackableSpace(heading.props.children),
                    });
                });
            }
            return null;
        }
    });

    return summaryItems;
};

export const isWordpresscontent = (slices: Queries.PrismicVideoDataBody[] | Queries.PrismicPostDataBody[]): boolean => {
    return !slices.some(slice => 'normal_text' === slice.slice_type);
};

export const transformTextImageToGatsbyImage = (prismicImage: Queries.PrismicImageField) => {
    const { url, dimensions } = prismicImage;

    return getImageData({
        baseUrl: url,
        sourceWidth: dimensions.width,
        sourceHeight: dimensions.height,
        aspectRatio: dimensions.width / dimensions.height,
        urlBuilder: ({ baseUrl, width, height, format }) => `${baseUrl}?w=${width}&h=${height}&fm=${format}`,
    });
};

export const transformPrismicImageToGatsbyImage = (url: string) => {
    const baseUrl = url.split('?')[0];
    const sourceWidth = url.match(/&w=(\d+)/) ? Number(url.match(/&w=(\d+)/)[1]) : 10000;
    const sourceHeight = url.match(/&w=(\d+)/) ? Number(url.match(/&h=(\d+)/)[1]) : 10000;

    return getImageData({
        baseUrl,
        sourceWidth: sourceWidth,
        sourceHeight: sourceHeight,
        urlBuilder: ({ baseUrl, width, height, format }) => `${baseUrl}?w=${width}&h=${height}&fm=${format}`,
    });
};

// Allow to add a space between paragraph and hyperlink when prismic user not add blank line in Card slice
export const checkRichText = (richText: RichTextBlock[]): RichTextBlock[] => {
    if (!richText || richText.length === 0) return [];

    if (richText.length === 1) {
        const richTextBlock = richText[0];
        const texts = richTextBlock.text.split('\n');
        const newRichText = texts.map((text, idx) => {
            let spans = [];
            if (idx === 1) {
                const newSpans = [...richTextBlock.spans];
                newSpans[0].start = newSpans[0].start - newSpans[0].start;
                newSpans[0].end = newSpans[0].end - newSpans[0].start;
                spans = newSpans;
            }
            return { ...richTextBlock, text: unbreackableSpace(text), spans };
        });
        return newRichText;
    }

    return richText.map(block => ({
        ...block,
        text: unbreackableSpace(block.text),
    }));
};

// split RichTextBlock so that each block starts with an H2
export const splitNormalText = (sliceZone: Queries.PrismicPostDataBody[]): Queries.PrismicPostDataBody[] => {
    const newSliceZone = [];

    sliceZone.forEach(slice => {
        let index = 0;
        let newRichText = [];
        if (slice.slice_type !== 'normal_text') {
            newSliceZone.push(slice);
            return;
        }

        slice.primary.content_text.richText.forEach(sliceItem => {
            if (sliceItem.type === 'heading2') {
                if (newRichText.length > 0)
                    newSliceZone.push({
                        id: `${slice.id}__${index}`,
                        primary: {
                            content_text: {
                                html: slice.primary.content_text.html,
                                richText: newRichText,
                                text: newRichText.map(e => e.text).join(' '),
                            },
                        },
                        slice_type: slice.slice_type,
                    });
                index++;
                newRichText = [sliceItem];
                return;
            } else {
                newRichText.push(sliceItem);
            }
        });

        newSliceZone.push({
            id: `${slice.id}__${index}`,
            primary: {
                content_text: {
                    html: slice.primary.content_text.html,
                    richText: newRichText,
                    text: newRichText.map(e => e.text).join(' '),
                },
            },
            slice_type: slice.slice_type,
        });
    });

    return newSliceZone;
};
