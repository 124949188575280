import React, { useState } from 'react';
import {
    TextField,
    Button,
    Paper,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
} from '@mui/material';

interface BulletinData {
    salaireBrut: string;
    heuresSupp: string;
    primes: string;
    avantagesNature: string;
    departement: string;
    convention: string;
    cadre: boolean;
    tempsPartiel: boolean;
    tauxTempsPartiel: string;
}

interface BulletinResults {
    cotisationsSalariales: {
        label: string;
        base: number;
        taux: number;
        montant: number;
    }[];
    cotisationsPatronales: {
        label: string;
        base: number;
        taux: number;
        montant: number;
    }[];
    totalBrut: number;
    totalCotisationsSalariales: number;
    totalCotisationsPatronales: number;
    netAvantImpot: number;
    netAPayer: number;
    coutTotal: number;
}

const BulletinPaie: React.FC = () => {
    const [formData, setFormData] = useState<BulletinData>({
        salaireBrut: '',
        heuresSupp: '0',
        primes: '0',
        avantagesNature: '0',
        departement: '',
        convention: '',
        cadre: false,
        tempsPartiel: false,
        tauxTempsPartiel: '100',
    });

    const [results, setResults] = useState<BulletinResults | null>(null);

    const calculateBulletin = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;
        const heuresSupp = parseFloat(formData.heuresSupp) || 0;
        const primes = parseFloat(formData.primes) || 0;
        const avantagesNature = parseFloat(formData.avantagesNature) || 0;
        const tauxTempsPartiel = parseFloat(formData.tauxTempsPartiel) / 100;

        // Base de calcul
        const baseCalcul = (salaireBrut + primes + avantagesNature) * tauxTempsPartiel;
        const baseHeuresSupp = heuresSupp * tauxTempsPartiel;
        const totalBrut = baseCalcul + baseHeuresSupp;

        // Cotisations salariales
        const cotisationsSalariales = [
            {
                label: 'Sécurité sociale - Maladie, maternité, invalidité, décès',
                base: totalBrut,
                taux: 0.007,
                montant: totalBrut * 0.007,
            },
            {
                label: 'Sécurité sociale - Vieillesse plafonnée',
                base: Math.min(totalBrut, 3666),
                taux: 0.069,
                montant: Math.min(totalBrut, 3666) * 0.069,
            },
            {
                label: 'Sécurité sociale - Vieillesse déplafonnée',
                base: totalBrut,
                taux: 0.004,
                montant: totalBrut * 0.004,
            },
            {
                label: 'Assurance chômage',
                base: totalBrut,
                taux: 0.024,
                montant: totalBrut * 0.024,
            },
            {
                label: 'Retraite complémentaire (AGIRC-ARRCO T1)',
                base: Math.min(totalBrut, 3666),
                taux: formData.cadre ? 0.0394 : 0.0378,
                montant: Math.min(totalBrut, 3666) * (formData.cadre ? 0.0394 : 0.0378),
            },
            {
                label: 'CSG déductible',
                base: totalBrut * 0.9825,
                taux: 0.067,
                montant: totalBrut * 0.9825 * 0.067,
            },
            {
                label: 'CSG/CRDS non déductible',
                base: totalBrut * 0.9825,
                taux: 0.029,
                montant: totalBrut * 0.9825 * 0.029,
            },
        ];

        // Cotisations patronales
        const cotisationsPatronales = [
            {
                label: 'Sécurité sociale - Maladie, maternité, invalidité, décès',
                base: totalBrut,
                taux: 0.13,
                montant: totalBrut * 0.13,
            },
            {
                label: 'Sécurité sociale - Vieillesse plafonnée',
                base: Math.min(totalBrut, 3666),
                taux: 0.0855,
                montant: Math.min(totalBrut, 3666) * 0.0855,
            },
            {
                label: 'Sécurité sociale - Vieillesse déplafonnée',
                base: totalBrut,
                taux: 0.019,
                montant: totalBrut * 0.019,
            },
            {
                label: 'Allocations familiales',
                base: totalBrut,
                taux: 0.0525,
                montant: totalBrut * 0.0525,
            },
            {
                label: 'Accident du travail',
                base: totalBrut,
                taux: 0.012,
                montant: totalBrut * 0.012,
            },
            {
                label: 'Assurance chômage',
                base: totalBrut,
                taux: 0.042,
                montant: totalBrut * 0.042,
            },
            {
                label: 'AGS',
                base: totalBrut,
                taux: 0.0015,
                montant: totalBrut * 0.0015,
            },
            {
                label: 'Retraite complémentaire (AGIRC-ARRCO T1)',
                base: Math.min(totalBrut, 3666),
                taux: formData.cadre ? 0.0591 : 0.0567,
                montant: Math.min(totalBrut, 3666) * (formData.cadre ? 0.0591 : 0.0567),
            },
        ];

        // Totaux
        const totalCotisationsSalariales = cotisationsSalariales.reduce((acc, cot) => acc + cot.montant, 0);
        const totalCotisationsPatronales = cotisationsPatronales.reduce((acc, cot) => acc + cot.montant, 0);
        const netAvantImpot = totalBrut - totalCotisationsSalariales;
        const netAPayer = netAvantImpot;
        const coutTotal = totalBrut + totalCotisationsPatronales;

        setResults({
            cotisationsSalariales,
            cotisationsPatronales,
            totalBrut,
            totalCotisationsSalariales,
            totalCotisationsPatronales,
            netAvantImpot,
            netAPayer,
            coutTotal,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur de bulletin de paie
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Salaire brut mensuel (€)"
                        type="number"
                        value={formData.salaireBrut}
                        onChange={e => setFormData(prev => ({ ...prev, salaireBrut: e.target.value }))}
                        placeholder="Ex: 2500"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Heures supplémentaires (€)"
                        type="number"
                        value={formData.heuresSupp}
                        onChange={e => setFormData(prev => ({ ...prev, heuresSupp: e.target.value }))}
                        placeholder="Ex: 200"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Primes et gratifications (€)"
                        type="number"
                        value={formData.primes}
                        onChange={e => setFormData(prev => ({ ...prev, primes: e.target.value }))}
                        placeholder="Ex: 100"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Avantages en nature (€)"
                        type="number"
                        value={formData.avantagesNature}
                        onChange={e => setFormData(prev => ({ ...prev, avantagesNature: e.target.value }))}
                        placeholder="Ex: 0"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.cadre}
                                onChange={e => setFormData(prev => ({ ...prev, cadre: e.target.checked }))}
                            />
                        }
                        label="Statut cadre"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.tempsPartiel}
                                onChange={e => setFormData(prev => ({ ...prev, tempsPartiel: e.target.checked }))}
                            />
                        }
                        label="Temps partiel"
                    />
                </FormControl>

                {formData.tempsPartiel && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Taux temps partiel (%)"
                            type="number"
                            value={formData.tauxTempsPartiel}
                            onChange={e => setFormData(prev => ({ ...prev, tauxTempsPartiel: e.target.value }))}
                            placeholder="Ex: 80"
                        />
                    </FormControl>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateBulletin}
                    disabled={!formData.salaireBrut}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer le bulletin
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Salaire brut</span>
                        <span className="value">{Math.round(results.totalBrut).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Cotisations salariales</span>
                        <span className="value"></span>
                    </div>
                    {results.cotisationsSalariales.map((cot, index) => (
                        <div key={index} className="result-row">
                            <span className="label">
                                {cot.label} ({(cot.taux * 100).toFixed(2)}%)
                            </span>
                            <span className="value">-{Math.round(cot.montant).toLocaleString('fr-FR')} €</span>
                        </div>
                    ))}

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Cotisations patronales</span>
                        <span className="value"></span>
                    </div>
                    {results.cotisationsPatronales.map((cot, index) => (
                        <div key={index} className="result-row">
                            <span className="label">
                                {cot.label} ({(cot.taux * 100).toFixed(2)}%)
                            </span>
                            <span className="value">{Math.round(cot.montant).toLocaleString('fr-FR')} €</span>
                        </div>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Net à payer avant impôt</span>
                        <span className="value">{Math.round(results.netAvantImpot).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Net à payer</span>
                        <span className="value">{Math.round(results.netAPayer).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Coût total employeur</span>
                        <span className="value">{Math.round(results.coutTotal).toLocaleString('fr-FR')} ��</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default BulletinPaie;
