import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface FormationData {
    masseSalariale: string;
    typeEntreprise: 'moins11' | 'plus11';
    masseSalarialeCDD: string;
    alternanceObjectif: boolean;
}

interface FormationResults {
    cfp: number;
    professionnalisation: number;
    cppCdd: number;
    total: number;
    details: {
        label: string;
        montant: number;
        taux: number;
        description: string;
    }[];
}

const ContributionFormation: React.FC = () => {
    const [formData, setFormData] = useState<FormationData>({
        masseSalariale: '',
        typeEntreprise: 'moins11',
        masseSalarialeCDD: '',
        alternanceObjectif: false,
    });

    const [results, setResults] = useState<FormationResults | null>(null);

    const calculateContribution = () => {
        const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

        const masseSalariale = parseFloat(formData.masseSalariale) || 0;
        const masseSalarialeCDD = parseFloat(formData.masseSalarialeCDD) || 0;

        // Taux selon l'effectif
        const tauxCFP = formData.typeEntreprise === 'moins11' ? 0.0055 : 0.01;
        const tauxPro = formData.typeEntreprise === 'moins11' ? 0.0055 : 0.013;
        const tauxCPFCDD = 0.01;

        // Calcul des contributions avec arrondi
        const cfp = roundToTwoDecimals(masseSalariale * tauxCFP);
        const professionnalisation = roundToTwoDecimals(masseSalariale * tauxPro);
        const cppCdd = roundToTwoDecimals(masseSalarialeCDD * tauxCPFCDD);

        const total = roundToTwoDecimals(cfp + professionnalisation + cppCdd);

        // Détails des calculs
        const details = [
            {
                label: 'Formation professionnelle',
                montant: cfp,
                taux: roundToTwoDecimals(tauxCFP * 100),
                description: `Contribution principale de ${roundToTwoDecimals(tauxCFP * 100)}% de la masse salariale`,
            },
            {
                label: 'CPF-CDD',
                montant: cppCdd,
                taux: roundToTwoDecimals(tauxCPFCDD * 100),
                description: '1% de la masse salariale des CDD pour leur formation',
            },
        ];

        setResults({
            cfp,
            professionnalisation,
            cppCdd,
            total,
            details,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3, mb: 2 }} className="info-message">
                <Typography
                    variant="body2"
                    sx={{
                        backgroundColor: '#f8f9fa',
                        p: 2,
                        borderRadius: 1,
                        color: '#666',
                        borderLeft: '4px solid #0288d1',
                    }}
                >
                    Les contributions relatives aux conventions collectives ne sont pas prises en compte.
                </Typography>
            </Paper>

            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de contribution formation
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <Typography
                        variant="body1"
                        sx={{
                            mb: 1,
                            fontWeight: 500,
                            color: 'rgba(0, 0, 0, 0.87)',
                        }}
                    >
                        Nombre de salariés
                    </Typography>
                    <RadioGroup
                        row
                        value={formData.typeEntreprise}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, typeEntreprise: e.target.value as 'moins11' | 'plus11' }))
                        }
                    >
                        <FormControlLabel
                            value="moins11"
                            control={<Radio />}
                            label="Moins de 11 salariés"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="plus11"
                            control={<Radio />}
                            label="11 salariés et plus (pendant 5 années civiles consécutives)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Masse salariale brute (exclure les apprentis)"
                        type="number"
                        value={formData.masseSalariale}
                        onChange={e => setFormData(prev => ({ ...prev, masseSalariale: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dont masse salariale brute CDD (exclure les contrats d'alternance et d'accompagnement dans l'emploi)"
                        type="number"
                        value={formData.masseSalarialeCDD}
                        onChange={e => setFormData(prev => ({ ...prev, masseSalarialeCDD: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateContribution}
                    disabled={!formData.masseSalariale}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer la contribution
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Masse salariale</span>
                        <span className="value">{parseFloat(formData.masseSalariale).toLocaleString('fr-FR')} €</span>
                    </div>

                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">
                                    {detail.label} ({detail.taux}%)
                                </span>
                                <span className="value">
                                    {detail.montant.toLocaleString('fr-FR', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}{' '}
                                    €
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Contribution totale</span>
                        <span className="value">
                            {results.total.toLocaleString('fr-FR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}{' '}
                            €
                        </span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default ContributionFormation;
