import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface EmbaucheData {
    salaireBrut: string;
    statut: 'cadre' | 'non-cadre' | 'apprenti';
    avantages: {
        mutuelle: boolean;
        ticketsRestaurant: boolean;
        transport: boolean;
    };
    duree: 'cdi' | 'cdd';
    tempsTravail: 'complet' | 'partiel';
}

interface EmbaucheResults {
    chargesPatronales: number;
    chargesSalariales: number;
    avantagesEnNature: number;
    coutTotalAnnuel: number;
    coutTotalMensuel: number;
    salaireNetMensuel: number;
}

const CoutEmbauche: React.FC = () => {
    const [formData, setFormData] = useState<EmbaucheData>({
        salaireBrut: '',
        statut: 'non-cadre',
        avantages: {
            mutuelle: false,
            ticketsRestaurant: false,
            transport: false,
        },
        duree: 'cdi',
        tempsTravail: 'complet',
    });

    const [results, setResults] = useState<EmbaucheResults | null>(null);

    const calculateCout = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;

        // Taux de charges selon le statut
        const tauxChargesPatronales = formData.statut === 'cadre' ? 0.45 : 0.42;
        const tauxChargesSalariales = 0.22;

        // Calcul des avantages
        const mutuelle = formData.avantages.mutuelle ? 50 : 0;
        const ticketsRestaurant = formData.avantages.ticketsRestaurant ? 150 : 0;
        const transport = formData.avantages.transport ? 75 : 0;
        const avantagesTotal = (mutuelle + ticketsRestaurant + transport) * 12;

        // Calculs principaux
        const chargesPatronales = salaireBrut * 12 * tauxChargesPatronales;
        const chargesSalariales = salaireBrut * 12 * tauxChargesSalariales;
        const coutTotalAnnuel = salaireBrut * 12 + chargesPatronales + avantagesTotal;
        const salaireNetMensuel = salaireBrut * (1 - tauxChargesSalariales);

        setResults({
            chargesPatronales,
            chargesSalariales,
            avantagesEnNature: avantagesTotal,
            coutTotalAnnuel,
            coutTotalMensuel: coutTotalAnnuel / 12,
            salaireNetMensuel,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur coût d'embauche
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.statut}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                statut: e.target.value as 'cadre' | 'non-cadre' | 'apprenti',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="cadre"
                            control={<Radio />}
                            label="Cadre"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="non-cadre"
                            control={<Radio />}
                            label="Non cadre"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="apprenti"
                            control={<Radio />}
                            label="Apprenti"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.duree}
                        onChange={e => setFormData(prev => ({ ...prev, duree: e.target.value as 'cdi' | 'cdd' }))}
                    >
                        <FormControlLabel value="cdi" control={<Radio />} label="CDI" sx={{ flex: 1, m: 0, p: 2 }} />
                        <FormControlLabel value="cdd" control={<Radio />} label="CDD" sx={{ flex: 1, m: 0, p: 2 }} />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Salaire brut mensuel (€)"
                        type="number"
                        value={formData.salaireBrut}
                        onChange={e => setFormData(prev => ({ ...prev, salaireBrut: e.target.value }))}
                        placeholder="Ex: 2500"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Avantages en nature
                    </Typography>
                    <RadioGroup
                        value={formData.avantages.mutuelle}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                avantages: {
                                    ...prev.avantages,
                                    mutuelle: e.target.value === 'true',
                                },
                            }))
                        }
                    >
                        <FormControlLabel value="true" control={<Radio />} label="Mutuelle d'entreprise" />
                    </RadioGroup>
                    <RadioGroup
                        value={formData.avantages.ticketsRestaurant}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                avantages: {
                                    ...prev.avantages,
                                    ticketsRestaurant: e.target.value === 'true',
                                },
                            }))
                        }
                    >
                        <FormControlLabel value="true" control={<Radio />} label="Tickets restaurant" />
                    </RadioGroup>
                    <RadioGroup
                        value={formData.avantages.transport}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                avantages: {
                                    ...prev.avantages,
                                    transport: e.target.value === 'true',
                                },
                            }))
                        }
                    >
                        <FormControlLabel value="true" control={<Radio />} label="Remboursement transport" />
                    </RadioGroup>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateCout}
                    disabled={!formData.salaireBrut}
                    fullWidth
                >
                    Calculer le coût total
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Salaire brut annuel</span>
                        <span className="value">
                            {(parseFloat(formData.salaireBrut) * 12).toLocaleString('fr-FR')} €
                        </span>
                    </div>
                    <div className="result-row">
                        <span className="label">Charges patronales</span>
                        <span className="value">{Math.round(results.chargesPatronales).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Charges salariales</span>
                        <span className="value">{Math.round(results.chargesSalariales).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Avantages en nature</span>
                        <span className="value">{Math.round(results.avantagesEnNature).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Salaire net mensuel</span>
                        <span className="value">{Math.round(results.salaireNetMensuel).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Coût total annuel</span>
                        <span className="value">{Math.round(results.coutTotalAnnuel).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Coût total mensuel</span>
                        <span className="value">{Math.round(results.coutTotalMensuel).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default CoutEmbauche;
