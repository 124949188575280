import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface PrixData {
    coutAchat: string;
    coutProduction: string;
    fraisGeneraux: string;
    fraisCommercialisation: string;
    margeObjectif: string;
    methodeCalcul: 'cout' | 'marche';
    prixConcurrence: string;
    volumeEstime: string;
    tauxTva: string;
}

interface PrixResults {
    coutRevient: number;
    prixVenteHT: number;
    prixVenteTTC: number;
    margeUnitaire: number;
    margePourcentage: number;
    seuilRentabilite: number;
    details: {
        label: string;
        montant: number;
        pourcentage: number;
    }[];
    recommandations: string[];
}

const PrixVente: React.FC = () => {
    const [formData, setFormData] = useState<PrixData>({
        coutAchat: '',
        coutProduction: '',
        fraisGeneraux: '',
        fraisCommercialisation: '',
        margeObjectif: '',
        methodeCalcul: 'cout',
        prixConcurrence: '',
        volumeEstime: '',
        tauxTva: '20',
    });

    const [results, setResults] = useState<PrixResults | null>(null);

    const calculatePrix = () => {
        const coutAchat = parseFloat(formData.coutAchat) || 0;
        const coutProduction = parseFloat(formData.coutProduction) || 0;
        const fraisGeneraux = parseFloat(formData.fraisGeneraux) || 0;
        const fraisCommercialisation = parseFloat(formData.fraisCommercialisation) || 0;
        const margeObjectif = parseFloat(formData.margeObjectif) || 0;
        const prixConcurrence = parseFloat(formData.prixConcurrence) || 0;
        const volumeEstime = parseFloat(formData.volumeEstime) || 1;
        const tauxTva = parseFloat(formData.tauxTva) || 20;

        // Calcul du coût de revient
        const coutRevient = coutAchat + coutProduction + fraisGeneraux / volumeEstime + fraisCommercialisation;

        // Calcul du prix de vente selon la méthode choisie
        let prixVenteHT = 0;
        switch (formData.methodeCalcul) {
            case 'cout':
                // Méthode du coût majoré avec taux de marge
                prixVenteHT = coutRevient * (1 + margeObjectif / 100);
                break;
            case 'marche':
                // Méthode alignée sur le marché
                prixVenteHT = prixConcurrence;
                break;
        }

        // Calculs complémentaires
        const margeUnitaire = prixVenteHT - coutRevient;
        const margePourcentage = (margeUnitaire / coutRevient) * 100;
        const prixVenteTTC = prixVenteHT * (1 + tauxTva / 100);
        const seuilRentabilite = fraisGeneraux / (prixVenteHT - (coutAchat + coutProduction + fraisCommercialisation));

        // Détail des composantes du prix
        const details = [
            {
                label: "Coût d'achat/matières premières",
                montant: coutAchat,
                pourcentage: (coutAchat / prixVenteHT) * 100,
            },
            {
                label: 'Coût de production',
                montant: coutProduction,
                pourcentage: (coutProduction / prixVenteHT) * 100,
            },
            {
                label: 'Frais généraux unitaires',
                montant: fraisGeneraux / volumeEstime,
                pourcentage: (fraisGeneraux / volumeEstime / prixVenteHT) * 100,
            },
            {
                label: 'Frais de commercialisation',
                montant: fraisCommercialisation,
                pourcentage: (fraisCommercialisation / prixVenteHT) * 100,
            },
            {
                label: 'Taux de marge',
                montant: margeUnitaire,
                pourcentage: margePourcentage,
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (margePourcentage < 20) {
            recommandations.push("La marge est faible, envisagez d'optimiser vos coûts ou d'augmenter le prix");
        }

        if (formData.methodeCalcul === 'marche' && prixVenteHT < coutRevient * 1.1) {
            recommandations.push(
                'Le prix du marché ne permet pas une rentabilité suffisante, différenciez votre offre',
            );
        }

        if (seuilRentabilite > volumeEstime) {
            recommandations.push('Le volume de ventes estimé est inférieur au seuil de rentabilité');
        }

        if (prixVenteHT > prixConcurrence * 1.2 && formData.methodeCalcul === 'marche') {
            recommandations.push(
                'Votre prix est significativement supérieur au marché, justifiez votre positionnement',
            );
        }

        setResults({
            coutRevient,
            prixVenteHT,
            prixVenteTTC,
            margeUnitaire,
            margePourcentage,
            seuilRentabilite,
            details,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de prix de vente
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.methodeCalcul}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                methodeCalcul: e.target.value as 'cout' | 'marche',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="cout"
                            control={<Radio />}
                            label="Coût majoré"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="marche"
                            control={<Radio />}
                            label="Prix du marché"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Coût d'achat/matières premières (€ HT)"
                        type="number"
                        value={formData.coutAchat}
                        onChange={e => setFormData(prev => ({ ...prev, coutAchat: e.target.value }))}
                        placeholder="Ex: 10"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Coût de production unitaire (€ HT)"
                        type="number"
                        value={formData.coutProduction}
                        onChange={e => setFormData(prev => ({ ...prev, coutProduction: e.target.value }))}
                        placeholder="Ex: 15"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Frais généraux totaux (€ HT)"
                        type="number"
                        value={formData.fraisGeneraux}
                        onChange={e => setFormData(prev => ({ ...prev, fraisGeneraux: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Frais de commercialisation unitaires (€ HT)"
                        type="number"
                        value={formData.fraisCommercialisation}
                        onChange={e => setFormData(prev => ({ ...prev, fraisCommercialisation: e.target.value }))}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Volume estimé (unités)"
                        type="number"
                        value={formData.volumeEstime}
                        onChange={e => setFormData(prev => ({ ...prev, volumeEstime: e.target.value }))}
                        placeholder="Ex: 1000"
                    />
                </FormControl>

                {formData.methodeCalcul === 'cout' && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Taux de marge souhaité (%)"
                            type="number"
                            value={formData.margeObjectif}
                            onChange={e => setFormData(prev => ({ ...prev, margeObjectif: e.target.value }))}
                            placeholder="Ex: 30"
                            helperText="Marge calculée sur le coût de revient"
                        />
                    </FormControl>
                )}

                {formData.methodeCalcul === 'marche' && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Prix moyen concurrence (€ HT)"
                            type="number"
                            value={formData.prixConcurrence}
                            onChange={e => setFormData(prev => ({ ...prev, prixConcurrence: e.target.value }))}
                            placeholder="Ex: 50"
                        />
                    </FormControl>
                )}

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        select
                        fullWidth
                        label="Taux de TVA (%)"
                        value={formData.tauxTva}
                        onChange={e => setFormData(prev => ({ ...prev, tauxTva: e.target.value }))}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="0">0% - Exonéré</option>
                        <option value="2.1">2.1% - Taux super réduit</option>
                        <option value="5.5">5.5% - Taux réduit</option>
                        <option value="10">10% - Taux intermédiaire</option>
                        <option value="20">20% - Taux normal</option>
                    </TextField>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePrix}
                    disabled={!formData.coutAchat || !formData.volumeEstime}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer le prix de vente
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Coût de revient unitaire</span>
                        <span className="value">{results.coutRevient.toFixed(2)} €</span>
                    </div>

                    {results.details.map((detail, index) => (
                        <div key={index} className="result-row">
                            <span className="label">{detail.label}</span>
                            <span className="value">
                                {detail.montant.toFixed(2)} € ({detail.pourcentage.toFixed(1)}%)
                            </span>
                        </div>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Prix de vente HT conseillé</span>
                        <span className="value">{results.prixVenteHT.toFixed(2)} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Prix de vente TTC ({formData.tauxTva}%)</span>
                        <span className="value">{results.prixVenteTTC.toFixed(2)} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Seuil de rentabilité</span>
                        <span className="value">
                            {!isFinite(results.seuilRentabilite)
                                ? 'N/A'
                                : `${Math.ceil(results.seuilRentabilite)} unités`}
                        </span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default PrixVente;
