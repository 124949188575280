import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Divider from '../../../../../UI/Divider';
import { PACK } from '../../const';
import { RecalculateButton, WrapperDesktopDivider, WrapperMobileDivider } from '../../styles';
import { SIMULATOR_SAVING_CASES } from '../../type';
import DisplayResultPrice from '../DisplayResultPrice';
import CustomMadeContent from './CustomMadeContent';
import NoSavingsContent from './NoSavingsContent';
import SuccessContent from './SuccessContent';

interface ResponseSimulatorProps {
    setShowResponse: (show: boolean) => void;
    result: {
        status: string;
        savings: number;
        pack: string;
        form: {
            peoples: number;
        };
    };
    isInBlog: boolean;
}

const ResponseSimulator = ({ setShowResponse, result, isInBlog }: ResponseSimulatorProps) => {
    const data = useStaticQuery(query);

    const getTitleParams = status => {
        switch (status) {
            case SIMULATOR_SAVING_CASES.CUSTOM:
                return {
                    label: "Dougs est en moyenne 36% moins cher qu'un cabinet traditionnel",
                    displayPrice: false,
                    fontSize: [18, 24],
                };

            case SIMULATOR_SAVING_CASES.OK:
                return {
                    label: "d'économies estimées par an",
                    displayPrice: true,
                    fontSize: [14, 16],
                };
            case SIMULATOR_SAVING_CASES.ERROR:
            default:
                return {
                    label: "Vous bénéficiez déjà d'honoraires avantageux",
                    displayPrice: false,
                    fontSize: [18, 24],
                };
        }
    };
    return (
        <>
            <RecalculateButton
                onClick={() => setShowResponse(false)}
                data-ga-send="event,button,click,calculateur-recalculer"
            >
                <img src="/images/common/icon_wrapper.svg" alt="" />
                <p>Recalculer</p>
            </RecalculateButton>
            <DisplayResultPrice
                image={data.calculatorImage.childImageSharp.gatsbyImageData}
                savings={result.savings}
                titleParams={getTitleParams(result.status)}
            />
            {!isInBlog && (
                <>
                    <WrapperMobileDivider>
                        <Divider />
                    </WrapperMobileDivider>
                    <WrapperDesktopDivider>
                        <Divider isVertical />
                    </WrapperDesktopDivider>
                </>
            )}
            {result.status === SIMULATOR_SAVING_CASES.CUSTOM && <CustomMadeContent />}
            {result.status === SIMULATOR_SAVING_CASES.OK && (
                <SuccessContent pack={PACK[result.pack]} peoples={result.form.peoples} />
            )}
            {result.status === SIMULATOR_SAVING_CASES.ERROR && <NoSavingsContent />}
        </>
    );
};

export default ResponseSimulator;

const query = graphql`
    {
        calculatorImage: file(relativePath: { eq: "tarif/icon-emprunt.png" }) {
            childImageSharp {
                gatsbyImageData(height: 95, quality: 50, layout: CONSTRAINED)
            }
        }
    }
`;
