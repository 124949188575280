import { Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface LevierData {
    investissement: string;
    fondsPropresPct: string;
    tauxInteret: string;
    rentabiliteEconomique: string;
    impotSocietes: string;
}

interface LevierResults {
    rentabiliteFinanciere: number;
    effetLevier: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const EffetLevier: React.FC = () => {
    const [formData, setFormData] = useState<LevierData>({
        investissement: '',
        fondsPropresPct: '',
        tauxInteret: '',
        rentabiliteEconomique: '',
        impotSocietes: '25',
    });

    const [results, setResults] = useState<LevierResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateLevier = () => {
        const investissement = parseFloat(formData.investissement) || 0;
        const fondsPropresPct = parseFloat(formData.fondsPropresPct) || 0;
        const tauxInteret = parseFloat(formData.tauxInteret) || 0;
        const rentabiliteEconomique = parseFloat(formData.rentabiliteEconomique) || 0;
        const tauxIS = parseFloat(formData.impotSocietes) / 100;

        // Calcul des montants
        const fondsPropresMontant = investissement * (fondsPropresPct / 100);
        const detteMontant = investissement - fondsPropresMontant;
        const levier = detteMontant / fondsPropresMontant;

        // Calcul des résultats
        const resultatEconomique = investissement * (rentabiliteEconomique / 100);
        const fraisFinanciers = detteMontant * (tauxInteret / 100);
        const resultatAvantImpot = resultatEconomique - fraisFinanciers;
        const impot = resultatAvantImpot * tauxIS;
        const resultatNet = resultatAvantImpot - impot;

        // Calcul des rentabilités
        const rentabiliteFinanciere = (resultatNet / fondsPropresMontant) * 100;
        const effetLevier = rentabiliteFinanciere - rentabiliteEconomique;

        // Détails des calculs
        const details = [
            {
                label: "Montant de l'investissement",
                montant: investissement,
                description: 'Investissement total nécessaire',
            },
            {
                label: 'Fonds propres',
                montant: fondsPropresMontant,
                description: `${fondsPropresPct}% de l'investissement`,
            },
            {
                label: 'Dette',
                montant: detteMontant,
                description: `${(100 - fondsPropresPct).toFixed(1)}% de l'investissement`,
            },
            {
                label: 'Levier financier',
                montant: levier,
                description: 'Ratio Dette/Fonds propres',
            },
            {
                label: 'Résultat économique',
                montant: resultatEconomique,
                description: `${rentabiliteEconomique}% de l'investissement`,
            },
            {
                label: 'Frais financiers',
                montant: fraisFinanciers,
                description: `${tauxInteret}% de la dette`,
            },
            {
                label: 'Résultat net',
                montant: resultatNet,
                description: 'Après frais financiers et IS',
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (rentabiliteEconomique < tauxInteret) {
            recommandations.push(
                "Attention : la rentabilité économique est inférieure au taux d'intérêt (effet de levier négatif)",
            );
        }

        if (levier > 2) {
            recommandations.push("Le niveau d'endettement est élevé, ce qui augmente le risque financier");
        }

        if (rentabiliteFinanciere < 8) {
            recommandations.push("La rentabilité financière est faible, envisagez d'optimiser la structure financière");
        }

        if (effetLevier > 10) {
            recommandations.push(
                "L'effet de levier est très important, vérifiez la capacité à supporter les frais financiers",
            );
        }

        setResults({
            rentabiliteFinanciere,
            effetLevier,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateLevier();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur d'effet de levier
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Montant de l'investissement (€)"
                        type="number"
                        value={formData.investissement}
                        onChange={e => setFormData(prev => ({ ...prev, investissement: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Part des fonds propres (%)"
                        type="number"
                        value={formData.fondsPropresPct}
                        onChange={e => setFormData(prev => ({ ...prev, fondsPropresPct: e.target.value }))}
                        placeholder="Ex: 30"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux d'intérêt (%)"
                        type="number"
                        value={formData.tauxInteret}
                        onChange={e => setFormData(prev => ({ ...prev, tauxInteret: e.target.value }))}
                        placeholder="Ex: 4"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Rentabilité économique (%)"
                        type="number"
                        value={formData.rentabiliteEconomique}
                        onChange={e => setFormData(prev => ({ ...prev, rentabiliteEconomique: e.target.value }))}
                        placeholder="Ex: 8"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux IS (%)"
                        type="number"
                        value={formData.impotSocietes}
                        onChange={e => setFormData(prev => ({ ...prev, impotSocietes: e.target.value }))}
                        placeholder="Ex: 25"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.investissement || !formData.fondsPropresPct}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer l'effet de levier
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">
                                    {detail.label.includes('Levier')
                                        ? detail.montant.toFixed(2)
                                        : Math.round(detail.montant).toLocaleString('fr-FR') + ' €'}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Rentabilité financière</span>
                        <span className="value">{results.rentabiliteFinanciere.toFixed(1)}%</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Effet de levier</span>
                        <span
                            className="value"
                            style={{
                                color: results.effetLevier >= 0 ? '#10B981' : '#EF4444',
                            }}
                        >
                            {results.effetLevier.toFixed(1)}%
                        </span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default EffetLevier;
