import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

interface Produit {
    nom: string;
    prixAchat: string;
    prixVente: string;
    quantite: string;
    fraisVariable: string;
}

interface MargeResults {
    margeUnitaire: number;
    margeGlobale: number;
    tauxMarge: number;
    coefficientMultiplicateur: number;
    details: {
        produit: string;
        marge: number;
        taux: number;
        contribution: number;
    }[];
    recommandations: string[];
}

const MargeCommerciale: React.FC = () => {
    const [produits, setProduits] = useState<Produit[]>([
        {
            nom: '',
            prixAchat: '',
            prixVente: '',
            quantite: '',
            fraisVariable: '0',
        },
    ]);

    const [results, setResults] = useState<MargeResults | null>(null);

    const addProduit = () => {
        setProduits([
            ...produits,
            {
                nom: '',
                prixAchat: '',
                prixVente: '',
                quantite: '',
                fraisVariable: '0',
            },
        ]);
    };

    const removeProduit = (index: number) => {
        setProduits(produits.filter((_, i) => i !== index));
    };

    const updateProduit = (index: number, field: keyof Produit, value: string) => {
        const newProduits = [...produits];
        newProduits[index] = { ...newProduits[index], [field]: value };
        setProduits(newProduits);
    };

    const calculateMarges = () => {
        let margeGlobaleTotale = 0;
        let caTotal = 0;
        const details = [];

        for (const produit of produits) {
            const prixAchat = parseFloat(produit.prixAchat) || 0;
            const prixVente = parseFloat(produit.prixVente) || 0;
            const quantite = parseFloat(produit.quantite) || 0;
            const fraisVariable = parseFloat(produit.fraisVariable) || 0;

            const margeUnitaire = prixVente - prixAchat - fraisVariable;
            const margeGlobale = margeUnitaire * quantite;
            const tauxMarge = (margeUnitaire / prixVente) * 100;
            const caGlobale = prixVente * quantite;

            margeGlobaleTotale += margeGlobale;
            caTotal += caGlobale;

            details.push({
                produit: produit.nom,
                marge: margeGlobale,
                taux: tauxMarge,
                contribution: (margeGlobale / margeGlobaleTotale) * 100,
            });
        }

        // Calcul des indicateurs globaux
        const tauxMargeGlobal = (margeGlobaleTotale / caTotal) * 100;
        const coefficientMoyen = caTotal / (caTotal - margeGlobaleTotale);

        // Génération des recommandations
        const recommandations = [];

        const produitsTriés = [...details].sort((a, b) => b.taux - a.taux);

        if (produitsTriés[0].taux - produitsTriés[produitsTriés.length - 1].taux > 20) {
            recommandations.push('Écart important de marge entre les produits. Réévaluez votre politique tarifaire.');
        }

        if (tauxMargeGlobal < 20) {
            recommandations.push("Marge globale faible. Optimisez vos coûts d'achat ou augmentez vos prix de vente.");
        }

        const produitsFaibleMarge = details.filter(d => d.taux < 15);
        if (produitsFaibleMarge.length > 0) {
            recommandations.push(
                `${produitsFaibleMarge.length} produit(s) avec une marge inférieure à 15%. Envisagez une révision des prix.`,
            );
        }

        const produitsPrincipaux = details.filter(d => d.contribution > 20);
        if (produitsPrincipaux.length > 0 && produitsPrincipaux.some(p => p.taux < tauxMargeGlobal)) {
            recommandations.push(
                "Certains produits à forte contribution ont une marge inférieure à la moyenne. Priorité d'optimisation.",
            );
        }

        setResults({
            margeUnitaire: margeGlobaleTotale / produits.length,
            margeGlobale: margeGlobaleTotale,
            tauxMarge: tauxMargeGlobal,
            coefficientMultiplicateur: coefficientMoyen,
            details,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de marge commerciale
                </Typography>

                {produits.map((produit, index) => (
                    <Paper key={index} elevation={0} sx={{ p: 2, mb: 2, backgroundColor: '#f8f9fa' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '16px',
                            }}
                        >
                            <Typography variant="subtitle1">Produit {index + 1}</Typography>
                            {produits.length > 1 && (
                                <IconButton onClick={() => removeProduit(index)} size="small">
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Nom du produit"
                                value={produit.nom}
                                onChange={e => updateProduit(index, 'nom', e.target.value)}
                                placeholder="Ex: Produit A"
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Prix d'achat HT (€)"
                                type="number"
                                value={produit.prixAchat}
                                onChange={e => updateProduit(index, 'prixAchat', e.target.value)}
                                placeholder="Ex: 10"
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Prix de vente HT (€)"
                                type="number"
                                value={produit.prixVente}
                                onChange={e => updateProduit(index, 'prixVente', e.target.value)}
                                placeholder="Ex: 20"
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Quantité vendue"
                                type="number"
                                value={produit.quantite}
                                onChange={e => updateProduit(index, 'quantite', e.target.value)}
                                placeholder="Ex: 100"
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Frais variables unitaires (€)"
                                type="number"
                                value={produit.fraisVariable}
                                onChange={e => updateProduit(index, 'fraisVariable', e.target.value)}
                                placeholder="Ex: 2"
                            />
                        </FormControl>
                    </Paper>
                ))}

                <Button startIcon={<AddIcon />} variant="outlined" onClick={addProduit} fullWidth sx={{ mb: 3 }}>
                    Ajouter un produit
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateMarges}
                    disabled={!produits.some(p => p.prixAchat && p.prixVente && p.quantite)}
                    fullWidth
                >
                    Calculer les marges
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">Marge commerciale globale</span>
                        <span className="value">{Math.round(results.margeGlobale).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Taux de marge moyen</span>
                        <span className="value">{results.tauxMarge.toFixed(1)}%</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Coefficient multiplicateur moyen</span>
                        <span className="value">×{results.coefficientMultiplicateur.toFixed(2)}</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Analyse par produit</span>
                        <span className="value"></span>
                    </div>

                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.produit}</span>
                                <span className="value">
                                    {Math.round(detail.marge).toLocaleString('fr-FR')} € ({detail.taux.toFixed(1)}%)
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    Contribution à la marge globale : {detail.contribution.toFixed(1)}%
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default MargeCommerciale;
