import {
    Alert,
    Button,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface ISData {
    resultatFiscal: string;
    chiffreAffaires: string;
    regimeIS: 'pme' | 'normal';
}

interface ISResults {
    tauxIS: number;
    montantIS: number;
    resultatNet: number;
    detailIS?: {
        montant15: number;
        montant25: number;
    };
}

const ImpotSocietes: React.FC = () => {
    const [formData, setFormData] = useState<ISData>({
        resultatFiscal: '',
        chiffreAffaires: '',
        regimeIS: 'pme',
    });

    const [results, setResults] = useState<ISResults | null>(null);

    const calculateIS = () => {
        const resultat = parseFloat(formData.resultatFiscal) || 0;
        const ca = parseFloat(formData.chiffreAffaires) || 0;

        let montantIS = 0;
        let detailIS;

        if (formData.regimeIS === 'pme' && ca <= 10000000) {
            if (resultat <= 42500) {
                montantIS = resultat * 0.15;
            } else {
                const montant15 = 42500 * 0.15;
                const montant25 = (resultat - 42500) * 0.25;
                montantIS = montant15 + montant25;
                detailIS = { montant15, montant25 };
            }
        } else {
            montantIS = resultat * 0.25;
        }

        const resultatNet = resultat - montantIS;
        const tauxEffectif = (montantIS / resultat) * 100;

        setResults({
            tauxIS: tauxEffectif,
            montantIS,
            resultatNet,
            detailIS,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur d'impôt sur les sociétés
                </Typography>

                {formData.regimeIS === 'pme' && (
                    <Alert severity="info" sx={{ mb: 3 }}>
                        Pour bénéficier du taux réduit de 15%, deux conditions supplémentaires doivent être remplies :
                        <ul style={{ margin: '8px 0 0 0', paddingLeft: '20px' }}>
                            <li>Le capital social doit être entièrement libéré</li>
                            <li>
                                Le capital doit être détenu pour au moins 75% par des personnes physiques (ou par une
                                société elle-même détenue pour au moins 75% par des personnes physiques)
                            </li>
                        </ul>
                    </Alert>
                )}

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.regimeIS}
                        onChange={e => setFormData(prev => ({ ...prev, regimeIS: e.target.value as 'pme' | 'normal' }))}
                    >
                        <FormControlLabel
                            value="pme"
                            control={<Radio />}
                            label="PME (CA ≤ 10M€)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="normal"
                            control={<Radio />}
                            label="Régime normal"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat fiscal (€)"
                        type="number"
                        value={formData.resultatFiscal}
                        onChange={e => setFormData(prev => ({ ...prev, resultatFiscal: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                {formData.regimeIS === 'pme' && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Chiffre d'affaires (€)"
                            type="number"
                            value={formData.chiffreAffaires}
                            onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                            placeholder="Ex: 1000000"
                        />
                    </FormControl>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateIS}
                    disabled={!formData.resultatFiscal || (formData.regimeIS === 'pme' && !formData.chiffreAffaires)}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer l'IS
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Résultat fiscal</span>
                        <span className="value">{parseFloat(formData.resultatFiscal).toLocaleString('fr-FR')} €</span>
                    </div>
                    {results.detailIS ? (
                        <>
                            <div className="result-row">
                                <span className="label">IS à 15% (jusqu'à 42 500€)</span>
                                <span className="value">
                                    {Math.round(results.detailIS.montant15).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row">
                                <span className="label">IS à 25% (au-delà de 42 500€)</span>
                                <span className="value">
                                    {Math.round(results.detailIS.montant25).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                        </>
                    ) : (
                        <div className="result-row">
                            <span className="label">Taux d'IS applicable</span>
                            <span className="value">{results.tauxIS.toFixed(1)}%</span>
                        </div>
                    )}
                    <div className="result-row">
                        <span className="label">Montant total de l'IS</span>
                        <span className="value">{Math.round(results.montantIS).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Résultat net après IS</span>
                        <span className="value">{Math.round(results.resultatNet).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default ImpotSocietes;
