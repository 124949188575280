import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SalaireBrutNet: React.FC = () => {
  const [montant, setMontant] = useState<string>('');
  const [type, setType] = useState<'brut' | 'net'>('brut');
  const [statut, setStatut] = useState<'cadre' | 'non-cadre'>('non-cadre');
  const [resultat, setResultat] = useState<{ brut: number; net: number } | null>(null);

  const calculerSalaire = () => {
    const montantNumber = parseFloat(montant);
    if (isNaN(montantNumber)) return;

    let tauxCotisations = statut === 'cadre' ? 0.25 : 0.22;

    if (type === 'brut') {
      const net = montantNumber * (1 - tauxCotisations);
      setResultat({
        brut: montantNumber,
        net: Math.round(net * 100) / 100
      });
    } else {
      const brut = montantNumber / (1 - tauxCotisations);
      setResultat({
        brut: Math.round(brut * 100) / 100,
        net: montantNumber
      });
    }
  };

  return (
    <div className="simulator-content">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Calculateur de salaire Brut/Net
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Montant"
              type="number"
              value={montant}
              onChange={(e) => setMontant(e.target.value)}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Type de montant</InputLabel>
              <Select
                value={type}
                label="Type de montant"
                onChange={(e) => setType(e.target.value as 'brut' | 'net')}
              >
                <MenuItem value="brut">Brut</MenuItem>
                <MenuItem value="net">Net</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Statut</InputLabel>
              <Select
                value={statut}
                label="Statut"
                onChange={(e) => setStatut(e.target.value as 'cadre' | 'non-cadre')}
              >
                <MenuItem value="cadre">Cadre</MenuItem>
                <MenuItem value="non-cadre">Non cadre</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button 
              variant="contained" 
              color="primary"
              onClick={calculerSalaire}
              disabled={!montant}
              fullWidth
            >
              Calculer
            </Button>
          </Grid>
        </Grid>

        {resultat && (
          <Paper elevation={1} sx={{ p: 2, mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Résultats
            </Typography>
            <Typography>
              Salaire brut : {resultat.brut.toLocaleString('fr-FR')} €
            </Typography>
            <Typography>
              Salaire net : {resultat.net.toLocaleString('fr-FR')} €
            </Typography>
          </Paper>
        )}
      </Paper>
    </div>
  );
};

export default SalaireBrutNet; 