import { Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface PrecariteData {
    salaireTotal: string;
    indemnitesCP: string;
}

interface PrecariteResults {
    basePrime: number;
    montantPrime: number;
    cotisationsSociales: number;
    montantNet: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const PrimePrecarite: React.FC = () => {
    const [formData, setFormData] = useState<PrecariteData>({
        salaireTotal: '',
        indemnitesCP: '0',
    });

    const [results, setResults] = useState<PrecariteResults | null>(null);

    const calculatePrime = () => {
        const salaireTotal = parseFloat(formData.salaireTotal) || 0;
        const indemnitesCP = parseFloat(formData.indemnitesCP) || 0;

        // Base de calcul
        const basePrime = salaireTotal + indemnitesCP;

        // Calcul de la prime (10% de la rémunération totale brute)
        const montantPrime = basePrime * 0.1;

        setResults({
            basePrime,
            montantPrime,
            cotisationsSociales: 0,
            montantNet: 0,
            details: [],
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de prime de précarité
                </Typography>

                <Paper
                    elevation={0}
                    sx={{
                        p: 2,
                        mb: 3,
                        backgroundColor: '#FFF4E5',
                        border: '1px solid #FFB74D',
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        En cas de rupture anticipée, proposition de CDI, faute grave, contrat saisonnier, contrat
                        étudiant pendant les vacances, il n'y a pas de prime de précarité.
                    </Typography>
                </Paper>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Salaire brut total sur la durée du contrat (renouvellements inclus) (€)"
                        type="number"
                        value={formData.salaireTotal}
                        onChange={e => setFormData(prev => ({ ...prev, salaireTotal: e.target.value }))}
                        placeholder="Ex: 12000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Montant des congés payés versés à la fin du contrat (€)"
                        type="number"
                        value={formData.indemnitesCP}
                        onChange={e => setFormData(prev => ({ ...prev, indemnitesCP: e.target.value }))}
                        placeholder="Ex: 1000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePrime}
                    disabled={!formData.salaireTotal}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer la prime
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">Prime de précarité brute</span>
                        <span className="value">{Math.round(results.montantPrime).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default PrimePrecarite;
