import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface TvaData {
    amount: string;
    direction: 'htToTtc' | 'ttcToHt';
    rate: string;
}

interface TvaResults {
    ht: number;
    tva: number;
    ttc: number;
}

const CalculateurTva: React.FC = () => {
    const [formData, setFormData] = useState<TvaData>({
        amount: '',
        direction: 'htToTtc',
        rate: '20',
    });

    const [results, setResults] = useState<TvaResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const tvaRates = [
        { value: '20', label: '20% (Taux normal)' },
        { value: '10', label: '10% (Taux intermédiaire)' },
        { value: '5.5', label: '5,5% (Taux réduit)' },
        { value: '2.1', label: '2,1% (Taux particulier)' },
    ];

    const calculateTva = () => {
        const amount = parseFloat(formData.amount);
        if (isNaN(amount)) return;

        const rate = parseFloat(formData.rate) / 100;
        let ht: number, tva: number, ttc: number;

        if (formData.direction === 'htToTtc') {
            ht = amount;
            tva = ht * rate;
            ttc = ht + tva;
        } else {
            ttc = amount;
            ht = ttc / (1 + rate);
            tva = ttc - ht;
        }

        setResults({
            ht: Math.round(ht * 100) / 100,
            tva: Math.round(tva * 100) / 100,
            ttc: Math.round(ttc * 100) / 100,
        });
    };

    const handleSubmit = () => {
        calculateTva();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de TVA
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.direction}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, direction: e.target.value as 'htToTtc' | 'ttcToHt' }))
                        }
                    >
                        <FormControlLabel
                            value="htToTtc"
                            control={<Radio />}
                            label="HT vers TTC"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="ttcToHt"
                            control={<Radio />}
                            label="TTC vers HT"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                        fullWidth
                        label={formData.direction === 'htToTtc' ? 'Montant HT (€)' : 'Montant TTC (€)'}
                        type="number"
                        value={formData.amount}
                        onChange={e => setFormData(prev => ({ ...prev, amount: e.target.value }))}
                        placeholder="Ex: 1000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel>Taux de TVA</InputLabel>
                    <Select
                        value={formData.rate}
                        label="Taux de TVA"
                        onChange={e => setFormData(prev => ({ ...prev, rate: e.target.value }))}
                    >
                        {tvaRates.map(rate => (
                            <MenuItem key={rate.value} value={rate.value}>
                                {rate.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.amount}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Montant HT</span>
                        <span className="value">{results.ht.toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">TVA ({formData.rate}%)</span>
                        <span className="value">{results.tva.toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Montant TTC</span>
                        <span className="value">{results.ttc.toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default CalculateurTva;
