import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface EscompteData {
    montantEffet: string;
    duree: string;
    tauxEscompte: string;
    commissionFixe: string;
    nombreEffets: string;
    typeCalcul: 'exact' | 'commercial';
}

interface EscompteResults {
    agios: number;
    commissions: number;
    coutTotal: number;
    tauxEffectif: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const EscompteCommercial: React.FC = () => {
    const [formData, setFormData] = useState<EscompteData>({
        montantEffet: '',
        duree: '',
        tauxEscompte: '',
        commissionFixe: '',
        nombreEffets: '1',
        typeCalcul: 'commercial',
    });

    const [results, setResults] = useState<EscompteResults | null>(null);

    const calculateEscompte = () => {
        const montant = parseFloat(formData.montantEffet) || 0;
        const duree = parseFloat(formData.duree) || 0;
        const tauxAnnuel = parseFloat(formData.tauxEscompte) || 0;
        const commissionFixe = parseFloat(formData.commissionFixe) || 0;
        const nombreEffets = parseInt(formData.nombreEffets) || 1;

        // Calcul des agios
        const nombreJours = formData.typeCalcul === 'commercial' ? 360 : 365;
        const agios = (montant * tauxAnnuel * duree) / (nombreJours * 100);

        // Calcul des commissions
        const commissionsFixe = commissionFixe * nombreEffets;
        const commissionService = montant * 0.0005; // 0.05% de commission de service
        const commissionsTotal = commissionsFixe + commissionService;

        // Coût total
        const coutTotal = agios + commissionsTotal;

        // Taux effectif global
        const tauxEffectif = (coutTotal / montant) * (nombreJours / duree) * 100;

        // Détails des calculs
        const details = [
            {
                label: "Agios d'escompte",
                montant: agios,
                description: `${tauxAnnuel}% sur ${duree} jours (base ${nombreJours})`,
            },
            {
                label: 'Commission fixe',
                montant: commissionsFixe,
                description: `${commissionFixe}€ × ${nombreEffets} effets`,
            },
            {
                label: 'Commission de service',
                montant: commissionService,
                description: '0.05% du montant',
            },
        ];

        // Recommandations
        const recommandations = [];

        if (tauxEffectif > 12) {
            recommandations.push("Le coût de l'escompte est élevé, étudiez d'autres solutions de financement");
        }

        if (duree > 90) {
            recommandations.push("La durée d'escompte est longue, privilégiez des effets à plus courte échéance");
        }

        if (nombreEffets > 5 && montant / nombreEffets < 5000) {
            recommandations.push('Les effets sont de faible montant, regroupez-les pour optimiser les frais fixes');
        }

        setResults({
            agios,
            commissions: commissionsTotal,
            coutTotal,
            tauxEffectif,
            details,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur d'escompte commercial
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeCalcul}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, typeCalcul: e.target.value as 'exact' | 'commercial' }))
                        }
                    >
                        <FormControlLabel
                            value="commercial"
                            control={<Radio />}
                            label="Année commerciale (360j)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="exact"
                            control={<Radio />}
                            label="Année civile (365j)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Montant des effets (€)"
                        type="number"
                        value={formData.montantEffet}
                        onChange={e => setFormData(prev => ({ ...prev, montantEffet: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre d'effets"
                        type="number"
                        value={formData.nombreEffets}
                        onChange={e => setFormData(prev => ({ ...prev, nombreEffets: e.target.value }))}
                        placeholder="Ex: 1"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Durée (jours)"
                        type="number"
                        value={formData.duree}
                        onChange={e => setFormData(prev => ({ ...prev, duree: e.target.value }))}
                        placeholder="Ex: 60"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux d'escompte annuel (%)"
                        type="number"
                        value={formData.tauxEscompte}
                        onChange={e => setFormData(prev => ({ ...prev, tauxEscompte: e.target.value }))}
                        placeholder="Ex: 4.5"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Commission fixe par effet (€)"
                        type="number"
                        value={formData.commissionFixe}
                        onChange={e => setFormData(prev => ({ ...prev, commissionFixe: e.target.value }))}
                        placeholder="Ex: 15"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateEscompte}
                    disabled={!formData.montantEffet || !formData.duree || !formData.tauxEscompte}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer l'escompte
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">{Math.round(detail.montant * 100) / 100} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Coût total de l'escompte</span>
                        <span className="value">{Math.round(results.coutTotal * 100) / 100} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Taux effectif global</span>
                        <span className="value">{Math.round(results.tauxEffectif * 100) / 100}%</span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default EscompteCommercial;
