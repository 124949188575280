import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface RentabiliteData {
    chiffreAffaires: string;
    chargesVariables: string;
    chargesFixes: string;
    typeActivite: 'commerce' | 'service';
    periodicite: 'mensuel' | 'annuel';
}

interface RentabiliteResults {
    margeUnitaire: number;
    tauxMarge: number;
    seuilRentabilite: number;
    pointMort: number;
    margeSecurite: number;
    indiceCouverture: number;
}

const SeuilRentabilite: React.FC = () => {
    const [formData, setFormData] = useState<RentabiliteData>({
        chiffreAffaires: '',
        chargesVariables: '',
        chargesFixes: '',
        typeActivite: 'commerce',
        periodicite: 'mensuel',
    });

    const [results, setResults] = useState<RentabiliteResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateRentabilite = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const cv = parseFloat(formData.chargesVariables) || 0;
        const cf = parseFloat(formData.chargesFixes) || 0;
        const multiplicateur = formData.periodicite === 'mensuel' ? 12 : 1;

        // Calculs annualisés
        const caAnnuel = ca * multiplicateur;
        const cvAnnuel = cv * multiplicateur;
        const cfAnnuel = cf * multiplicateur;

        // Calculs des indicateurs
        const margeUnitaire = caAnnuel - cvAnnuel;
        const tauxMarge = (margeUnitaire / caAnnuel) * 100;
        const seuilRentabilite = cfAnnuel / (tauxMarge / 100);
        const pointMort = seuilRentabilite / multiplicateur;
        const margeSecurite = caAnnuel - seuilRentabilite;
        const indiceCouverture = (margeUnitaire / cfAnnuel) * 100;

        setResults({
            margeUnitaire,
            tauxMarge,
            seuilRentabilite,
            pointMort,
            margeSecurite,
            indiceCouverture,
        });
    };

    const handleSubmit = () => {
        calculateRentabilite();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de seuil de rentabilité
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeActivite}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, typeActivite: e.target.value as 'commerce' | 'service' }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.periodicite}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, periodicite: e.target.value as 'mensuel' | 'annuel' }))
                        }
                    >
                        <FormControlLabel
                            value="mensuel"
                            control={<Radio />}
                            label="Données mensuelles"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="annuel"
                            control={<Radio />}
                            label="Données annuelles"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label={`Chiffre d'affaires ${formData.periodicite} (€)`}
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label={`Charges variables ${formData.periodicite}s (€)`}
                        type="number"
                        value={formData.chargesVariables}
                        onChange={e => setFormData(prev => ({ ...prev, chargesVariables: e.target.value }))}
                        placeholder="Ex: 6000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label={`Charges fixes ${formData.periodicite}s (€)`}
                        type="number"
                        value={formData.chargesFixes}
                        onChange={e => setFormData(prev => ({ ...prev, chargesFixes: e.target.value }))}
                        placeholder="Ex: 3000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.chiffreAffaires || !formData.chargesVariables || !formData.chargesFixes}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer le seuil
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Marge sur coûts variables</span>
                        <span className="value">{Math.round(results.margeUnitaire).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Taux de marge</span>
                        <span className="value">{results.tauxMarge.toFixed(1)}%</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Seuil de rentabilité annuel</span>
                        <span className="value">{Math.round(results.seuilRentabilite).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Point mort {formData.periodicite}</span>
                        <span className="value">{Math.round(results.pointMort).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Marge de sécurité</span>
                        <span className="value" style={{ color: results.margeSecurite >= 0 ? '#10B981' : '#EF4444' }}>
                            {Math.round(results.margeSecurite).toLocaleString('fr-FR')} €
                        </span>
                    </div>
                    <div className="result-row">
                        <span className="label">Indice de couverture des charges fixes</span>
                        <span className="value">{results.indiceCouverture.toFixed(1)}%</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default SeuilRentabilite;
