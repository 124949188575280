import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, Checkbox, FormControlLabel } from '@mui/material';

interface CreditData {
    chiffreAffaires: string;
    depensesRD: string;
    depensesInnovation: string;
    depensesFormation: string;
    depensesApprentissage: string;
    depensesMecennat: string;
    effectif: string;
    pme: boolean;
    jei: boolean;
}

interface CreditResults {
    cir: number;
    cii: number;
    creditFormation: number;
    creditApprentissage: number;
    creditMecennat: number;
    totalCredits: number;
    details: {
        credit: string;
        montant: number;
        description: string;
    }[];
}

const CreditImpot: React.FC = () => {
    const [formData, setFormData] = useState<CreditData>({
        chiffreAffaires: '',
        depensesRD: '',
        depensesInnovation: '',
        depensesFormation: '',
        depensesApprentissage: '',
        depensesMecennat: '',
        effectif: '',
        pme: true,
        jei: false,
    });

    const [results, setResults] = useState<CreditResults | null>(null);

    const calculateCredits = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const rd = parseFloat(formData.depensesRD) || 0;
        const innovation = parseFloat(formData.depensesInnovation) || 0;
        const formation = parseFloat(formData.depensesFormation) || 0;
        const apprentissage = parseFloat(formData.depensesApprentissage) || 0;
        const mecennat = parseFloat(formData.depensesMecennat) || 0;
        const effectif = parseInt(formData.effectif) || 0;

        // Calcul du CIR (30% des dépenses de R&D)
        let cir = rd * 0.3;
        if (formData.jei) {
            cir = rd * 0.4; // Taux majoré pour les JEI
        }

        // Calcul du CII (20% des dépenses d'innovation, plafonné à 400k€)
        let cii = 0;
        if (formData.pme) {
            cii = Math.min(innovation * 0.2, 400000);
        }

        // Crédit d'impôt formation (taux variable selon taille)
        const tauxFormation = effectif < 50 ? 0.2 : 0.1;
        const creditFormation = formation * tauxFormation;

        // Crédit d'impôt apprentissage (1600€ par apprenti)
        const creditApprentissage = apprentissage * 1600;

        // Crédit d'impôt mécénat (60% des dons, réduit à 40% au-delà de 2M€)
        let creditMecennat = 0;
        if (mecennat <= 2000000) {
            creditMecennat = mecennat * 0.6;
        } else {
            creditMecennat = 2000000 * 0.6 + (mecennat - 2000000) * 0.4;
        }

        const totalCredits = cir + cii + creditFormation + creditApprentissage + creditMecennat;

        const details = [
            {
                credit: "Crédit d'Impôt Recherche (CIR)",
                montant: cir,
                description: formData.jei ? '40% des dépenses de R&D (taux JEI)' : '30% des dépenses de R&D',
            },
            {
                credit: "Crédit d'Impôt Innovation (CII)",
                montant: cii,
                description: formData.pme
                    ? "20% des dépenses d'innovation (plafonné à 400k€)"
                    : 'Non applicable (réservé aux PME)',
            },
            {
                credit: "Crédit d'Impôt Formation",
                montant: creditFormation,
                description: `${tauxFormation * 100}% des dépenses de formation`,
            },
            {
                credit: "Crédit d'Impôt Apprentissage",
                montant: creditApprentissage,
                description: '1600€ par apprenti',
            },
            {
                credit: "Crédit d'Impôt Mécénat",
                montant: creditMecennat,
                description: mecennat > 2000000 ? "60% jusqu'à 2M€, 40% au-delà" : '60% des dons',
            },
        ];

        setResults({
            cir,
            cii,
            creditFormation,
            creditApprentissage,
            creditMecennat,
            totalCredits,
            details,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur de crédits d'impôt
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dépenses de R&D (€)"
                        type="number"
                        value={formData.depensesRD}
                        onChange={e => setFormData(prev => ({ ...prev, depensesRD: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dépenses d'innovation (€)"
                        type="number"
                        value={formData.depensesInnovation}
                        onChange={e => setFormData(prev => ({ ...prev, depensesInnovation: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dépenses de formation (€)"
                        type="number"
                        value={formData.depensesFormation}
                        onChange={e => setFormData(prev => ({ ...prev, depensesFormation: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre d'apprentis"
                        type="number"
                        value={formData.depensesApprentissage}
                        onChange={e => setFormData(prev => ({ ...prev, depensesApprentissage: e.target.value }))}
                        placeholder="Ex: 2"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dépenses de mécénat (€)"
                        type="number"
                        value={formData.depensesMecennat}
                        onChange={e => setFormData(prev => ({ ...prev, depensesMecennat: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Effectif"
                        type="number"
                        value={formData.effectif}
                        onChange={e => setFormData(prev => ({ ...prev, effectif: e.target.value }))}
                        placeholder="Ex: 25"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.pme}
                                onChange={e => setFormData(prev => ({ ...prev, pme: e.target.checked }))}
                            />
                        }
                        label="PME (CA < 50M€ et effectif < 250)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.jei}
                                onChange={e => setFormData(prev => ({ ...prev, jei: e.target.checked }))}
                            />
                        }
                        label="Jeune Entreprise Innovante (JEI)"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateCredits}
                    disabled={!formData.chiffreAffaires}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer les crédits d'impôt
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.credit}</span>
                                <span className="value">{Math.round(detail.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}
                    <div className="result-row highlight">
                        <span className="label">Total des crédits d'impôt</span>
                        <span className="value">{Math.round(results.totalCredits).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default CreditImpot;
