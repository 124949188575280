import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl } from '@mui/material';

interface CafData {
    resultatNet: string;
    dotationsAmortissements: string;
    dotationsProvisions: string;
    reprisesProvisions: string;
    plusValues: string;
    moinsValues: string;
    subventions: string;
    quotePartSubventions: string;
}

interface CafResults {
    caf: number;
    mba: number;
    details: {
        label: string;
        montant: number;
        type: 'addition' | 'soustraction';
        description: string;
    }[];
    ratios: {
        label: string;
        valeur: number;
        interpretation: string;
    }[];
}

const CapaciteAutofinancement: React.FC = () => {
    const [formData, setFormData] = useState<CafData>({
        resultatNet: '',
        dotationsAmortissements: '',
        dotationsProvisions: '',
        reprisesProvisions: '',
        plusValues: '',
        moinsValues: '',
        subventions: '',
        quotePartSubventions: '',
    });

    const [results, setResults] = useState<CafResults | null>(null);

    const calculateCAF = () => {
        const rn = parseFloat(formData.resultatNet) || 0;
        const da = parseFloat(formData.dotationsAmortissements) || 0;
        const dp = parseFloat(formData.dotationsProvisions) || 0;
        const rp = parseFloat(formData.reprisesProvisions) || 0;
        const pv = parseFloat(formData.plusValues) || 0;
        const mv = parseFloat(formData.moinsValues) || 0;
        const sub = parseFloat(formData.subventions) || 0;
        const qps = parseFloat(formData.quotePartSubventions) || 0;

        // Calcul de la CAF
        const caf = rn + da + dp - rp - pv + mv - sub + qps;

        // Calcul du MBA (Marge Brute d'Autofinancement)
        const mba = caf - dp + rp;

        // Détail des calculs
        const details = [
            {
                label: 'Résultat net',
                montant: rn,
                type: 'addition' as const,
                description: 'Point de départ du calcul',
            },
            {
                label: 'Dotations aux amortissements',
                montant: da,
                type: 'addition' as const,
                description: 'Charges calculées sans décaissement',
            },
            {
                label: 'Dotations aux provisions',
                montant: dp,
                type: 'addition' as const,
                description: 'Charges potentielles futures',
            },
            {
                label: 'Reprises sur provisions',
                montant: rp,
                type: 'soustraction' as const,
                description: 'Annulation des provisions antérieures',
            },
            {
                label: 'Plus-values de cession',
                montant: pv,
                type: 'soustraction' as const,
                description: "Gains exceptionnels sur cessions d'actifs",
            },
            {
                label: 'Moins-values de cession',
                montant: mv,
                type: 'addition' as const,
                description: "Pertes exceptionnelles sur cessions d'actifs",
            },
            {
                label: "Subventions d'investissement",
                montant: sub,
                type: 'soustraction' as const,
                description: 'Subventions reçues pour les investissements',
            },
            {
                label: 'Quote-part des subventions',
                montant: qps,
                type: 'addition' as const,
                description: 'Réintégration des subventions virées au résultat',
            },
        ];

        // Calcul des ratios
        const ratios = [
            {
                label: 'CAF / Résultat net',
                valeur: rn !== 0 ? (caf / rn) * 100 : 0,
                interpretation: 'Mesure la capacité à générer de la trésorerie par rapport au résultat',
            },
            {
                label: 'MBA / CAF',
                valeur: caf !== 0 ? (mba / caf) * 100 : 0,
                interpretation: 'Évalue la part de la CAF disponible après neutralisation des provisions',
            },
        ];

        setResults({
            caf,
            mba,
            details,
            ratios,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de CAF
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat net (€)"
                        type="number"
                        value={formData.resultatNet}
                        onChange={e => setFormData(prev => ({ ...prev, resultatNet: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dotations aux amortissements (€)"
                        type="number"
                        value={formData.dotationsAmortissements}
                        onChange={e => setFormData(prev => ({ ...prev, dotationsAmortissements: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dotations aux provisions (€)"
                        type="number"
                        value={formData.dotationsProvisions}
                        onChange={e => setFormData(prev => ({ ...prev, dotationsProvisions: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Reprises sur provisions (€)"
                        type="number"
                        value={formData.reprisesProvisions}
                        onChange={e => setFormData(prev => ({ ...prev, reprisesProvisions: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Plus-values de cession (€)"
                        type="number"
                        value={formData.plusValues}
                        onChange={e => setFormData(prev => ({ ...prev, plusValues: e.target.value }))}
                        placeholder="Ex: 15000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Moins-values de cession (€)"
                        type="number"
                        value={formData.moinsValues}
                        onChange={e => setFormData(prev => ({ ...prev, moinsValues: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Subventions d'investissement (€)"
                        type="number"
                        value={formData.subventions}
                        onChange={e => setFormData(prev => ({ ...prev, subventions: e.target.value }))}
                        placeholder="Ex: 30000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Quote-part des subventions (€)"
                        type="number"
                        value={formData.quotePartSubventions}
                        onChange={e => setFormData(prev => ({ ...prev, quotePartSubventions: e.target.value }))}
                        placeholder="Ex: 6000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateCAF}
                    disabled={!formData.resultatNet}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer la CAF
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span
                                    className="value"
                                    style={{ color: detail.type === 'addition' ? undefined : 'red' }}
                                >
                                    {detail.type === 'addition' ? '+' : '-'}{' '}
                                    {Math.abs(detail.montant).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Capacité d'Autofinancement (CAF)</span>
                        <span className="value">{Math.round(results.caf).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Marge Brute d'Autofinancement (MBA)</span>
                        <span className="value">{Math.round(results.mba).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Ratios d'analyse</span>
                        <span className="value"></span>
                    </div>

                    {results.ratios.map((ratio, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{ratio.label}</span>
                                <span className="value">{ratio.valeur.toFixed(1)}%</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {ratio.interpretation}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}
                </Paper>
            )}
        </div>
    );
};

export default CapaciteAutofinancement;
