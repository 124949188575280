import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface RotationData {
    stockMoyen: string;
    achatsAnnuels: string;
    ventesAnnuelles: string;
    typeActivite: 'commerce' | 'industrie';
    stockSecurite: string;
    delaiAppro: string;
}

interface RotationResults {
    tauxRotation: number;
    dureeStockage: number;
    couvertureStock: number;
    coutStockage: number;
    details: {
        label: string;
        valeur: number;
        unite: string;
        description: string;
    }[];
    recommandations: string[];
}

const RotationStocks: React.FC = () => {
    const [formData, setFormData] = useState<RotationData>({
        stockMoyen: '',
        achatsAnnuels: '',
        ventesAnnuelles: '',
        typeActivite: 'commerce',
        stockSecurite: '',
        delaiAppro: '',
    });

    const [results, setResults] = useState<RotationResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateRotation = () => {
        const stockMoyen = parseFloat(formData.stockMoyen) || 0;
        const achatsAnnuels = parseFloat(formData.achatsAnnuels) || 0;
        const ventesAnnuelles = parseFloat(formData.ventesAnnuelles) || 0;
        const stockSecurite = parseFloat(formData.stockSecurite) || 0;
        const delaiAppro = parseFloat(formData.delaiAppro) || 0;

        // Calcul des indicateurs de rotation
        const tauxRotation = achatsAnnuels / stockMoyen;
        const dureeStockage = (stockMoyen / achatsAnnuels) * 365;
        const couvertureStock = (stockMoyen / ventesAnnuelles) * 365;

        // Estimation du coût de stockage (environ 20% de la valeur du stock)
        const coutStockage = stockMoyen * 0.2;

        // Calcul des ratios complémentaires
        const details = [
            {
                label: 'Taux de rotation',
                valeur: tauxRotation,
                unite: 'rotations/an',
                description: "Nombre de fois où le stock se renouvelle dans l'année",
            },
            {
                label: 'Durée moyenne de stockage',
                valeur: dureeStockage,
                unite: 'jours',
                description: 'Temps moyen de présence des articles en stock',
            },
            {
                label: 'Couverture de stock',
                valeur: couvertureStock,
                unite: 'jours',
                description: 'Nombre de jours de vente couverts par le stock',
            },
            {
                label: 'Stock de sécurité',
                valeur: stockSecurite,
                unite: '€',
                description: 'Valeur du stock minimal de sécurité',
            },
            {
                label: 'Coût de stockage annuel',
                valeur: coutStockage,
                unite: '€',
                description: 'Estimation des coûts de détention du stock',
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (dureeStockage > 90) {
            recommandations.push('La durée de stockage est élevée, optimisez vos approvisionnements');
        }

        if (tauxRotation < 4) {
            recommandations.push('Le taux de rotation est faible, réduisez vos stocks moyens');
        }

        if (stockSecurite > stockMoyen * 0.3) {
            recommandations.push('Le stock de sécurité est important, réévaluez vos besoins');
        }

        if (delaiAppro > 30) {
            recommandations.push("Les délais d'approvisionnement sont longs, diversifiez vos fournisseurs");
        }

        if (coutStockage > ventesAnnuelles * 0.1) {
            recommandations.push('Les coûts de stockage sont élevés, optimisez votre gestion des stocks');
        }

        setResults({
            tauxRotation,
            dureeStockage,
            couvertureStock,
            coutStockage,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateRotation();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Analyse de la rotation des stocks
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeActivite}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, typeActivite: e.target.value as 'commerce' | 'industrie' }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="industrie"
                            control={<Radio />}
                            label="Industrie"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Stock moyen (€)"
                        type="number"
                        value={formData.stockMoyen}
                        onChange={e => setFormData(prev => ({ ...prev, stockMoyen: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Achats annuels (€)"
                        type="number"
                        value={formData.achatsAnnuels}
                        onChange={e => setFormData(prev => ({ ...prev, achatsAnnuels: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Ventes annuelles (€)"
                        type="number"
                        value={formData.ventesAnnuelles}
                        onChange={e => setFormData(prev => ({ ...prev, ventesAnnuelles: e.target.value }))}
                        placeholder="Ex: 750000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Stock de sécurité (€)"
                        type="number"
                        value={formData.stockSecurite}
                        onChange={e => setFormData(prev => ({ ...prev, stockSecurite: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Délai d'approvisionnement (jours)"
                        type="number"
                        value={formData.delaiAppro}
                        onChange={e => setFormData(prev => ({ ...prev, delaiAppro: e.target.value }))}
                        placeholder="Ex: 15"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.stockMoyen || !formData.achatsAnnuels}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer la rotation
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">
                                    {typeof detail.valeur === 'number' && !isNaN(detail.valeur)
                                        ? Math.round(detail.valeur * 100) / 100
                                        : detail.valeur}{' '}
                                    {detail.unite}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default RotationStocks;
