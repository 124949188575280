import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface KilometriqueData {
    kilometres: string;
    puissance: string;
    typeVehicule: 'voiture' | 'moto';
    carburant: 'essence' | 'diesel' | 'electrique';
}

interface KilometriqueResults {
    bareme: number;
    indemnites: number;
    plafond: number | null;
    depassement: number;
}

const FraisKilometriques: React.FC = () => {
    const [formData, setFormData] = useState<KilometriqueData>({
        kilometres: '',
        puissance: '',
        typeVehicule: 'voiture',
        carburant: 'essence',
    });

    const [results, setResults] = useState<KilometriqueResults | null>(null);

    // Barèmes 2024 pour les voitures
    const baremesVoiture = {
        '3cv': { jusqu5000: 0.529, de5001a20000: 0.316, plus20000: 0.37 },
        '4cv': { jusqu5000: 0.606, de5001a20000: 0.34, plus20000: 0.407 },
        '5cv': { jusqu5000: 0.636, de5001a20000: 0.357, plus20000: 0.427 },
        '6cv': { jusqu5000: 0.665, de5001a20000: 0.374, plus20000: 0.447 },
        '7cv+': { jusqu5000: 0.697, de5001a20000: 0.394, plus20000: 0.47 },
    };

    // Barèmes 2024 pour les motos (cylindrée > 50 cm³)
    const baremesMoto = {
        '1ou2cv': { jusqu3000: 0.395, de3001a6000: 0.099, plus6000: 0.248 },
        '3a5cv': { jusqu3000: 0.468, de3001a6000: 0.082, plus6000: 0.275 },
        plus5cv: { jusqu3000: 0.606, de3001a6000: 0.079, plus6000: 0.343 },
    };

    const getBonusKilometrique = (tranchePuissance: string) => {
        const bonus = {
            '3cv': 1065,
            '4cv': 1330,
            '5cv': 1395,
            '6cv': 1457,
            '7cv+': 1515,
        };
        return bonus[tranchePuissance as keyof typeof bonus];
    };

    const calculateFrais = () => {
        const kilometres = parseFloat(formData.kilometres) || 0;
        const puissance = parseInt(formData.puissance) || 0;
        let indemnites = 0;
        let bareme = 0;

        if (formData.typeVehicule === 'voiture') {
            // Détermination de la tranche de puissance
            let tranchePuissance = '7cv+';
            if (puissance <= 3) tranchePuissance = '3cv';
            else if (puissance === 4) tranchePuissance = '4cv';
            else if (puissance === 5) tranchePuissance = '5cv';
            else if (puissance === 6) tranchePuissance = '6cv';

            const baremes = baremesVoiture[tranchePuissance as keyof typeof baremesVoiture];

            if (kilometres <= 5000) {
                indemnites = kilometres * baremes.jusqu5000;
                bareme = baremes.jusqu5000;
            } else if (kilometres <= 20000) {
                indemnites = kilometres * baremes.de5001a20000 + getBonusKilometrique(tranchePuissance);
                bareme = indemnites / kilometres;
            } else {
                indemnites = kilometres * baremes.plus20000;
                bareme = baremes.plus20000;
            }

            // Majoration véhicule électrique
            if (formData.carburant === 'electrique') {
                indemnites *= 1.2;
                bareme *= 1.2;
            }
        } else {
            let tranchePuissance = 'plus5cv';
            if (puissance <= 2) tranchePuissance = '1ou2cv';
            else if (puissance <= 5) tranchePuissance = '3a5cv';

            const baremes = baremesMoto[tranchePuissance as keyof typeof baremesMoto];

            if (kilometres <= 3000) {
                indemnites = kilometres * baremes.jusqu3000;
                bareme = baremes.jusqu3000;
            } else if (kilometres <= 6000) {
                const premiereTrancheIndemnites = 3000 * baremes.jusqu3000;
                const deuxiemeTrancheIndemnites = (kilometres - 3000) * baremes.de3001a6000;
                indemnites = premiereTrancheIndemnites + deuxiemeTrancheIndemnites;
                bareme = indemnites / kilometres;
            } else {
                const premiereTrancheIndemnites = 3000 * baremes.jusqu3000;
                const deuxiemeTrancheIndemnites = 3000 * baremes.de3001a6000;
                const troisiemeTrancheIndemnites = (kilometres - 6000) * baremes.plus6000;
                indemnites = premiereTrancheIndemnites + deuxiemeTrancheIndemnites + troisiemeTrancheIndemnites;
                bareme = indemnites / kilometres;
            }

            // Majoration véhicule électrique
            if (formData.carburant === 'electrique') {
                indemnites *= 1.2;
                bareme *= 1.2;
            }
        }

        const plafond = 2000; // Plafond pour les motos et cyclomoteurs
        const depassement = plafond ? Math.max(0, indemnites - plafond) : 0;

        setResults({
            bareme,
            indemnites,
            plafond,
            depassement,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de frais kilométriques
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeVehicule}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                typeVehicule: e.target.value as 'voiture' | 'moto',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="voiture"
                            control={<Radio />}
                            label="Voiture"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel value="moto" control={<Radio />} label="Moto" sx={{ flex: 1, m: 0, p: 2 }} />
                    </RadioGroup>
                </FormControl>

                {formData.typeVehicule === 'voiture' && (
                    <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                        <RadioGroup
                            row
                            value={formData.carburant}
                            onChange={e =>
                                setFormData(prev => ({
                                    ...prev,
                                    carburant: e.target.value as 'essence' | 'diesel' | 'electrique',
                                }))
                            }
                        >
                            <FormControlLabel
                                value="essence"
                                control={<Radio />}
                                label="Essence"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="diesel"
                                control={<Radio />}
                                label="Diesel"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="electrique"
                                control={<Radio />}
                                label="Électrique"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                        </RadioGroup>
                    </FormControl>
                )}

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label={formData.typeVehicule === 'voiture' ? 'Puissance fiscale (CV)' : 'Puissance (kW)'}
                        type="number"
                        value={formData.puissance}
                        onChange={e => setFormData(prev => ({ ...prev, puissance: e.target.value }))}
                        placeholder={formData.typeVehicule === 'voiture' ? 'Ex: 5' : 'Ex: 3'}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Kilométrage annuel"
                        type="number"
                        value={formData.kilometres}
                        onChange={e => setFormData(prev => ({ ...prev, kilometres: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateFrais}
                    disabled={!formData.kilometres || !formData.puissance}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer les frais kilométriques
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Kilométrage annuel</span>
                        <span className="value">{parseFloat(formData.kilometres).toLocaleString('fr-FR')} km</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Barème kilométrique</span>
                        <span className="value">{results.bareme.toFixed(3)} €/km</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Indemnités kilométriques</span>
                        <span className="value">{Math.round(results.indemnites).toLocaleString('fr-FR')} €</span>
                    </div>
                    {!!results.plafond && (
                        <div className="result-row">
                            <span className="label">Plafond déductible</span>
                            <span className="value">{results.plafond.toLocaleString('fr-FR')} €</span>
                        </div>
                    )}
                    {results.depassement > 0 && (
                        <div className="result-row">
                            <span className="label">Dépassement du plafond</span>
                            <span className="value" style={{ color: 'red' }}>
                                {Math.round(results.depassement).toLocaleString('fr-FR')} €
                            </span>
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default FraisKilometriques;
