import React, { useState } from 'react';
import {
    TextField,
    Button,
    Paper,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
} from '@mui/material';

interface InteressementData {
    benefice: string;
    masseSalariale: string;
    valeurAjoutee: string;
    effectif: string;
    formule: 'benefice' | 'performance' | 'productivite';
    objectifAtteint: boolean;
    tauxInteressement: string;
    plafondIndividuel: string;
}

interface InteressementResults {
    montantGlobal: number;
    montantMoyen: number;
    plafondAtteint: boolean;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const Interessement: React.FC = () => {
    const [formData, setFormData] = useState<InteressementData>({
        benefice: '',
        masseSalariale: '',
        valeurAjoutee: '',
        effectif: '',
        formule: 'benefice',
        objectifAtteint: true,
        tauxInteressement: '20',
        plafondIndividuel: '30000',
    });

    const [results, setResults] = useState<InteressementResults | null>(null);

    const calculateInteressement = () => {
        const benefice = parseFloat(formData.benefice) || 0;
        const masseSalariale = parseFloat(formData.masseSalariale) || 0;
        const valeurAjoutee = parseFloat(formData.valeurAjoutee) || 0;
        const effectif = parseInt(formData.effectif) || 0;
        const tauxInteressement = parseFloat(formData.tauxInteressement) / 100;
        const plafondIndividuel = parseFloat(formData.plafondIndividuel) || 30000;

        let montantGlobal = 0;

        // Calcul selon la formule choisie
        switch (formData.formule) {
            case 'benefice':
                montantGlobal = benefice * tauxInteressement;
                break;
            case 'performance':
                montantGlobal = formData.objectifAtteint
                    ? masseSalariale * tauxInteressement
                    : masseSalariale * (tauxInteressement / 2);
                break;
            case 'productivite':
                montantGlobal = (valeurAjoutee / effectif) * tauxInteressement * effectif;
                break;
        }

        // Plafonnement global (20% de la masse salariale)
        const plafondGlobal = masseSalariale * 0.2;
        montantGlobal = Math.min(montantGlobal, plafondGlobal);

        // Montant moyen par salarié
        const montantMoyen = montantGlobal / effectif;

        // Vérification du plafond individuel
        const plafondAtteint = montantMoyen > plafondIndividuel;
        if (plafondAtteint) {
            montantGlobal = plafondIndividuel * effectif;
        }

        const details = [
            {
                label: 'Base de calcul',
                montant:
                    formData.formule === 'benefice'
                        ? benefice
                        : formData.formule === 'performance'
                          ? masseSalariale
                          : valeurAjoutee,
                description:
                    formData.formule === 'benefice'
                        ? 'Bénéfice net'
                        : formData.formule === 'performance'
                          ? 'Masse salariale'
                          : 'Valeur ajoutée',
            },
            {
                label: "Taux d'intéressement",
                montant: tauxInteressement * 100,
                description:
                    formData.formule === 'performance' && !formData.objectifAtteint
                        ? 'Taux réduit de 50% (objectifs non atteints)'
                        : `Taux appliqué : ${tauxInteressement * 100}%`,
            },
            {
                label: 'Plafond global (20% MS)',
                montant: plafondGlobal,
                description: '20% de la masse salariale annuelle',
            },
            {
                label: 'Plafond individuel',
                montant: plafondIndividuel,
                description: 'Plafond par bénéficiaire',
            },
        ];

        setResults({
            montantGlobal,
            montantMoyen,
            plafondAtteint,
            details,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur d'intéressement
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.formule}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                formule: e.target.value as 'benefice' | 'performance' | 'productivite',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="benefice"
                            control={<Radio />}
                            label="Formule bénéfice"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="performance"
                            control={<Radio />}
                            label="Formule performance"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="productivite"
                            control={<Radio />}
                            label="Formule productivité"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                {formData.formule === 'benefice' && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Bénéfice net (€)"
                            type="number"
                            value={formData.benefice}
                            onChange={e => setFormData(prev => ({ ...prev, benefice: e.target.value }))}
                            placeholder="Ex: 100000"
                        />
                    </FormControl>
                )}

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Masse salariale (€)"
                        type="number"
                        value={formData.masseSalariale}
                        onChange={e => setFormData(prev => ({ ...prev, masseSalariale: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                {formData.formule === 'productivite' && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Valeur ajoutée (€)"
                            type="number"
                            value={formData.valeurAjoutee}
                            onChange={e => setFormData(prev => ({ ...prev, valeurAjoutee: e.target.value }))}
                            placeholder="Ex: 800000"
                        />
                    </FormControl>
                )}

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Effectif"
                        type="number"
                        value={formData.effectif}
                        onChange={e => setFormData(prev => ({ ...prev, effectif: e.target.value }))}
                        placeholder="Ex: 25"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux d'intéressement (%)"
                        type="number"
                        value={formData.tauxInteressement}
                        onChange={e => setFormData(prev => ({ ...prev, tauxInteressement: e.target.value }))}
                        placeholder="Ex: 20"
                    />
                </FormControl>

                {formData.formule === 'performance' && (
                    <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.objectifAtteint}
                                    onChange={e =>
                                        setFormData(prev => ({ ...prev, objectifAtteint: e.target.checked }))
                                    }
                                />
                            }
                            label="Objectifs atteints"
                        />
                    </FormControl>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateInteressement}
                    disabled={!formData.masseSalariale || !formData.effectif}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer l'intéressement
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">
                                    {typeof detail.montant === 'number' && !isNaN(detail.montant)
                                        ? Math.round(detail.montant).toLocaleString('fr-FR') +
                                          (detail.label.includes('Taux') ? '%' : ' €')
                                        : detail.montant}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.plafondAtteint && (
                        <div className="result-row" style={{ backgroundColor: '#FEF2F2' }}>
                            <span className="label">Plafond individuel atteint</span>
                            <span className="value" style={{ color: '#DC2626' }}>
                                Montant plafonné
                            </span>
                        </div>
                    )}

                    <div className="result-row highlight">
                        <span className="label">Intéressement global</span>
                        <span className="value">{Math.round(results.montantGlobal).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Montant moyen par salarié</span>
                        <span className="value">{Math.round(results.montantMoyen).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default Interessement;
