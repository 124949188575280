import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface ComparisonData {
    are: 'oui' | 'non';
    activitePrincipale: 'oui' | 'non';
    couvertureSociale: 'importante' | 'standard';
    strategieRemuneration: 'reguliere' | 'dividendes';
}

interface ComparisonResults {
    formeRecommandee: 'SASU' | 'EURL';
    score: {
        sasu: number;
        eurl: number;
    };
    avantages: string[];
    inconvenients: string[];
    recommandations: string[];
}

const SasuEurl: React.FC = () => {
    const [formData, setFormData] = useState<ComparisonData>({
        are: 'non',
        activitePrincipale: 'oui',
        couvertureSociale: 'standard',
        strategieRemuneration: 'reguliere',
    });

    const [results, setResults] = useState<ComparisonResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateRecommendation = () => {
        let sasuScore = 0;
        let eurlScore = 0;
        const recommandations: string[] = [];

        // Analyse ARE
        if (formData.are === 'oui') {
            sasuScore += 10; // La SASU permet de cumuler ARE et rémunération
            recommandations.push("La SASU permet de cumuler l'ARE avec une rémunération de dirigeant");
        } else {
            eurlScore += 1;
        }

        // Analyse activité principale
        if (formData.activitePrincipale === 'non') {
            sasuScore += 1; // EURL plus flexible pour activité secondaire
            recommandations.push('La SASU est plus adaptée pour une activité secondaire');
        } else {
            eurlScore += 2; // Plus adapté pour une activité principale
        }

        // Analyse couverture sociale (maintenant séparée)
        if (formData.couvertureSociale === 'importante') {
            sasuScore += 3; // Meilleure protection sociale en SASU
            recommandations.push('La SASU offre une meilleure protection sociale (régime général)');
        } else {
            eurlScore += 1; // EURL plus avantageuse si protection standard
        }

        // Analyse stratégie de rémunération
        if (formData.strategieRemuneration === 'reguliere') {
            eurlScore += 2; // EURL plus avantageuse pour dividendes
            recommandations.push("L'EURL est idéale pour une rémunération régulière (salaire)");
        } else {
            sasuScore += 2; // SASU mieux adaptée pour salaire régulier
            recommandations.push('La SASU est plus avantageuse pour une stratégie "dividendes"');
        }

        const formeRecommandee = sasuScore > eurlScore ? 'SASU' : 'EURL';

        const avantages =
            formeRecommandee === 'SASU'
                ? [
                      'Statut de salarié assimilé',
                      'Meilleure protection sociale',
                      'Cumul possible avec ARE',
                      'Optimisation salaire/dividendes',
                  ]
                : [
                      'Charges sociales TNS plus faibles',
                      'Flexibilité de gestion',
                      'Moins de formalisme',
                      'Coûts de constitution moindres',
                      'Régime fiscal plus simple',
                  ];

        const inconvenients = [];

        setResults({
            formeRecommandee,
            score: { sasu: sasuScore, eurl: eurlScore },
            avantages,
            inconvenients,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateRecommendation();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Comparatif SASU vs EURL
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Touchez-vous les ARE (Allocation Retour à l'Emploi) ?
                    </Typography>
                    <RadioGroup
                        value={formData.are}
                        onChange={e => setFormData(prev => ({ ...prev, are: e.target.value as 'oui' | 'non' }))}
                    >
                        <FormControlLabel value="oui" control={<Radio />} label="Oui" sx={{ m: 1 }} />
                        <FormControlLabel value="non" control={<Radio />} label="Non" sx={{ m: 1 }} />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Cette activité sera-t-elle votre activité principale ?
                    </Typography>
                    <RadioGroup
                        value={formData.activitePrincipale}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, activitePrincipale: e.target.value as 'oui' | 'non' }))
                        }
                    >
                        <FormControlLabel value="oui" control={<Radio />} label="Oui" sx={{ m: 1 }} />
                        <FormControlLabel value="non" control={<Radio />} label="Non" sx={{ m: 1 }} />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Quels sont vos besoins en matière de couverture sociale ?
                    </Typography>
                    <RadioGroup
                        value={formData.couvertureSociale}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                couvertureSociale: e.target.value as 'importante' | 'standard',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="importante"
                            control={<Radio />}
                            label="Couverture importante OU je suis déjà couvert par une autre activité"
                            sx={{ m: 1 }}
                        />
                        <FormControlLabel
                            value="standard"
                            control={<Radio />}
                            label="Couverture basique"
                            sx={{ m: 1 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Quelle est votre stratégie de rémunération préférée ?
                    </Typography>
                    <RadioGroup
                        value={formData.strategieRemuneration}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                strategieRemuneration: e.target.value as 'reguliere' | 'dividendes',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="reguliere"
                            control={<Radio />}
                            label="Rémunération régulière (mensuelle/bimestrielle)"
                            sx={{ m: 1 }}
                        />
                        <FormControlLabel
                            value="dividendes"
                            control={<Radio />}
                            label="Principalement sous forme de dividendes"
                            sx={{ m: 1 }}
                        />
                    </RadioGroup>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Comparer SASU et EURL
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">Forme juridique recommandée</span>
                        <span className="value">{results.formeRecommandee}</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#E8F5E9' }}>
                        <span className="label">Avantages</span>
                    </div>
                    {results.avantages.map(avantage => (
                        <div key={avantage} className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                            <span className="label" style={{ color: '#2E7D32' }}>
                                • {avantage}
                            </span>
                        </div>
                    ))}
                </Paper>
            )}
        </div>
    );
};

export default SasuEurl;
