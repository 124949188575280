import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface StockData {
    demandeMensuelle: string;
    delaiLivraison: string;
    coutStockage: string;
    coutCommande: string;
    stockSecurite: string;
    lotReapproMin: string;
    typeGestion: 'quantite' | 'periodicite';
}

interface StockResults {
    stockMoyen: number;
    stockOptimal: number;
    frequenceOptimale: number;
    coutTotal: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const GestionStocks: React.FC = () => {
    const [formData, setFormData] = useState<StockData>({
        demandeMensuelle: '',
        delaiLivraison: '',
        coutStockage: '',
        coutCommande: '',
        stockSecurite: '',
        lotReapproMin: '',
        typeGestion: 'quantite',
    });

    const [results, setResults] = useState<StockResults | null>(null);

    const calculateStock = () => {
        const demandeAnnuelle = (parseFloat(formData.demandeMensuelle) || 0) * 12;
        const delaiLivraison = parseFloat(formData.delaiLivraison) || 0;
        const coutStockage = parseFloat(formData.coutStockage) || 0;
        const coutCommande = parseFloat(formData.coutCommande) || 0;
        const stockSecurite = parseFloat(formData.stockSecurite) || 0;
        const lotReapproMin = parseFloat(formData.lotReapproMin) || 0;

        // Calcul de la quantité économique de commande (formule de Wilson)
        const qec = Math.sqrt((2 * demandeAnnuelle * coutCommande) / coutStockage);

        // Ajustement selon le lot minimum de réapprovisionnement
        const stockOptimal = Math.max(qec, lotReapproMin);

        // Calcul de la fréquence optimale de commande
        const frequenceOptimale = demandeAnnuelle / stockOptimal;

        // Calcul du stock moyen
        const stockMoyen = stockOptimal / 2 + stockSecurite;

        // Calcul des coûts
        const coutAnnuelStockage = stockMoyen * coutStockage;
        const coutAnnuelCommandes = frequenceOptimale * coutCommande;
        const coutTotal = coutAnnuelStockage + coutAnnuelCommandes;

        // Génération des recommandations
        const recommandations = [];

        if (stockMoyen > demandeAnnuelle / 4) {
            recommandations.push('Le niveau de stock moyen est élevé, envisagez de réduire les quantités commandées');
        }

        if (frequenceOptimale > 24) {
            recommandations.push(
                "Fréquence de commande très élevée, négociez des conditions d'approvisionnement plus avantageuses",
            );
        }

        if (stockSecurite > stockOptimal * 0.5) {
            recommandations.push(
                'Stock de sécurité important, réévaluez les délais fournisseurs ou la variabilité de la demande',
            );
        }

        if (coutAnnuelStockage > coutTotal * 0.7) {
            recommandations.push('Coûts de stockage élevés, optimisez votre espace de stockage ou négociez les tarifs');
        }

        setResults({
            stockMoyen,
            stockOptimal,
            frequenceOptimale,
            coutTotal,
            details: [
                {
                    label: 'Quantité économique de commande',
                    montant: stockOptimal,
                    description: 'Quantité optimale à commander à chaque réapprovisionnement',
                },
                {
                    label: 'Stock moyen',
                    montant: stockMoyen,
                    description: 'Niveau moyen de stock incluant le stock de sécurité',
                },
                {
                    label: 'Coût annuel de stockage',
                    montant: coutAnnuelStockage,
                    description: 'Coût lié au stockage des marchandises',
                },
                {
                    label: 'Coût annuel des commandes',
                    montant: coutAnnuelCommandes,
                    description: 'Coût lié aux passations de commandes',
                },
            ],
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Optimisation des stocks
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeGestion}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                typeGestion: e.target.value as 'quantite' | 'periodicite',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="quantite"
                            control={<Radio />}
                            label="Gestion sur quantité"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="periodicite"
                            control={<Radio />}
                            label="Gestion périodique"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Demande mensuelle moyenne (unités)"
                        type="number"
                        value={formData.demandeMensuelle}
                        onChange={e => setFormData(prev => ({ ...prev, demandeMensuelle: e.target.value }))}
                        placeholder="Ex: 1000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Délai de livraison (jours)"
                        type="number"
                        value={formData.delaiLivraison}
                        onChange={e => setFormData(prev => ({ ...prev, delaiLivraison: e.target.value }))}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Coût de stockage unitaire annuel (€)"
                        type="number"
                        value={formData.coutStockage}
                        onChange={e => setFormData(prev => ({ ...prev, coutStockage: e.target.value }))}
                        placeholder="Ex: 10"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Coût de passation de commande (€)"
                        type="number"
                        value={formData.coutCommande}
                        onChange={e => setFormData(prev => ({ ...prev, coutCommande: e.target.value }))}
                        placeholder="Ex: 50"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Stock de sécurité (unités)"
                        type="number"
                        value={formData.stockSecurite}
                        onChange={e => setFormData(prev => ({ ...prev, stockSecurite: e.target.value }))}
                        placeholder="Ex: 100"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Lot minimum de réapprovisionnement"
                        type="number"
                        value={formData.lotReapproMin}
                        onChange={e => setFormData(prev => ({ ...prev, lotReapproMin: e.target.value }))}
                        placeholder="Ex: 200"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateStock}
                    disabled={!formData.demandeMensuelle || !formData.coutStockage}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer l'optimisation
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">
                                    {detail.label.includes('Coût')
                                        ? Math.round(detail.montant).toLocaleString('fr-FR') + ' €'
                                        : Math.round(detail.montant).toLocaleString('fr-FR') + ' unités'}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row">
                        <span className="label">Fréquence optimale de commande</span>
                        <span className="value">{Math.round(results.frequenceOptimale)} commandes/an</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Coût total de gestion</span>
                        <span className="value">{Math.round(results.coutTotal).toLocaleString('fr-FR')} €</span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default GestionStocks;
