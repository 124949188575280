import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface MadelinData {
    revenuPro: string;
    plafondSS: string;
    retraiteBase: string;
    retraiteComplementaire: string;
    prevoyance: string;
    perteEmploi: string;
    trancheImposition: string;
}

interface MadelinResults {
    plafonds: {
        retraite: number;
        prevoyance: number;
        perteEmploi: number;
    };
    deductions: {
        retraite: number;
        prevoyance: number;
        perteEmploi: number;
        total: number;
    };
    economieImpot: number;
    recommandations: string[];
}

const LoiMadelin: React.FC = () => {
    const [formData, setFormData] = useState<MadelinData>({
        revenuPro: '',
        plafondSS: '43992',
        retraiteBase: '',
        retraiteComplementaire: '',
        prevoyance: '',
        perteEmploi: '',
        trancheImposition: '30',
    });

    const [results, setResults] = useState<MadelinResults | null>(null);

    const calculateMadelin = () => {
        const revenu = parseFloat(formData.revenuPro) || 0;
        const pass = parseFloat(formData.plafondSS) || 43992;
        const retraiteBase = parseFloat(formData.retraiteBase) || 0;
        const retraiteComplementaire = parseFloat(formData.retraiteComplementaire) || 0;
        const prevoyance = parseFloat(formData.prevoyance) || 0;
        const perteEmploi = parseFloat(formData.perteEmploi) || 0;
        const tauxImpot = parseFloat(formData.trancheImposition) / 100;

        // Calcul des plafonds de déduction
        const plafondRetraite = Math.min(revenu * 0.1 + pass * 0.1, pass * 0.1 * 8);
        const plafondPrevoyance = Math.min(revenu * 0.035 + pass * 0.07, pass * 0.07 * 8);
        const plafondPerteEmploi = pass * 0.015 * 8;

        // Calcul des déductions effectives
        const deductionRetraite = Math.min(retraiteBase + retraiteComplementaire, plafondRetraite);
        const deductionPrevoyance = Math.min(prevoyance, plafondPrevoyance);
        const deductionPerteEmploi = Math.min(perteEmploi, plafondPerteEmploi);
        const deductionTotale = deductionRetraite + deductionPrevoyance + deductionPerteEmploi;

        // Calcul de l'économie d'impôt
        const economieImpot = deductionTotale * tauxImpot;

        // Génération des recommandations
        const recommandations = [];

        if (deductionRetraite < plafondRetraite * 0.7) {
            recommandations.push(
                "Vous n'utilisez pas pleinement votre plafond retraite Madelin. Optimisez votre retraite future.",
            );
        }

        if (deductionPrevoyance < plafondPrevoyance * 0.5) {
            recommandations.push(
                'Votre couverture prévoyance pourrait être insuffisante. Étudiez un complément de protection.',
            );
        }

        if (perteEmploi === 0) {
            recommandations.push("Pensez à souscrire une garantie perte d'emploi pour sécuriser vos revenus.");
        }

        if (tauxImpot >= 0.3 && deductionTotale < (plafondRetraite + plafondPrevoyance) * 0.5) {
            recommandations.push(
                "Avec votre taux d'imposition, les contrats Madelin sont particulièrement avantageux fiscalement.",
            );
        }

        setResults({
            plafonds: {
                retraite: plafondRetraite,
                prevoyance: plafondPrevoyance,
                perteEmploi: plafondPerteEmploi,
            },
            deductions: {
                retraite: deductionRetraite,
                prevoyance: deductionPrevoyance,
                perteEmploi: deductionPerteEmploi,
                total: deductionTotale,
            },
            economieImpot,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur Loi Madelin
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Revenu professionnel (€)"
                        type="number"
                        value={formData.revenuPro}
                        onChange={e => setFormData(prev => ({ ...prev, revenuPro: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Plafond annuel SS (€)"
                        type="number"
                        value={formData.plafondSS}
                        onChange={e => setFormData(prev => ({ ...prev, plafondSS: e.target.value }))}
                        placeholder="Ex: 43992"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Cotisations retraite de base (€)"
                        type="number"
                        value={formData.retraiteBase}
                        onChange={e => setFormData(prev => ({ ...prev, retraiteBase: e.target.value }))}
                        placeholder="Ex: 3000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Cotisations retraite complémentaire (€)"
                        type="number"
                        value={formData.retraiteComplementaire}
                        onChange={e => setFormData(prev => ({ ...prev, retraiteComplementaire: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Cotisations prévoyance (€)"
                        type="number"
                        value={formData.prevoyance}
                        onChange={e => setFormData(prev => ({ ...prev, prevoyance: e.target.value }))}
                        placeholder="Ex: 2000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Cotisations perte d'emploi (€)"
                        type="number"
                        value={formData.perteEmploi}
                        onChange={e => setFormData(prev => ({ ...prev, perteEmploi: e.target.value }))}
                        placeholder="Ex: 500"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.trancheImposition}
                        onChange={e => setFormData(prev => ({ ...prev, trancheImposition: e.target.value }))}
                    >
                        <FormControlLabel value="11" control={<Radio />} label="11%" sx={{ flex: 1, m: 0, p: 2 }} />
                        <FormControlLabel value="30" control={<Radio />} label="30%" sx={{ flex: 1, m: 0, p: 2 }} />
                        <FormControlLabel value="41" control={<Radio />} label="41%" sx={{ flex: 1, m: 0, p: 2 }} />
                        <FormControlLabel value="45" control={<Radio />} label="45%" sx={{ flex: 1, m: 0, p: 2 }} />
                    </RadioGroup>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateMadelin}
                    disabled={!formData.revenuPro}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer les déductions
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Plafonds de déduction</span>
                        <span className="value"></span>
                    </div>

                    <div className="result-row">
                        <span className="label">Plafond retraite</span>
                        <span className="value">{Math.round(results.plafonds.retraite).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Plafond prévoyance</span>
                        <span className="value">
                            {Math.round(results.plafonds.prevoyance).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row">
                        <span className="label">Plafond perte d'emploi</span>
                        <span className="value">
                            {Math.round(results.plafonds.perteEmploi).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Déductions effectives</span>
                        <span className="value"></span>
                    </div>

                    <div className="result-row">
                        <span className="label">Déduction retraite</span>
                        <span className="value">
                            {Math.round(results.deductions.retraite).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row">
                        <span className="label">Déduction prévoyance</span>
                        <span className="value">
                            {Math.round(results.deductions.prevoyance).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row">
                        <span className="label">Déduction perte d'emploi</span>
                        <span className="value">
                            {Math.round(results.deductions.perteEmploi).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Total des déductions</span>
                        <span className="value">{Math.round(results.deductions.total).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Économie d'impôt estimée</span>
                        <span className="value">{Math.round(results.economieImpot).toLocaleString('fr-FR')} €</span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default LoiMadelin;
