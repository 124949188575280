import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, Checkbox, FormControlLabel } from '@mui/material';

interface TaxeData {
    masseSalariale: string;
    nbApprentis: string;
    departement: string;
    hasAccueilApprentis: boolean;
    hasConventionEcole: boolean;
}

interface TaxeResults {
    taxeBrute: number;
    quoteSolde: number;
    quoteCfa: number;
    reductionApprentis: number;
    reductionConvention: number;
    montantDu: number;
    repartition: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const TaxeApprentissage: React.FC = () => {
    const [formData, setFormData] = useState<TaxeData>({
        masseSalariale: '',
        nbApprentis: '0',
        departement: '',
        hasAccueilApprentis: false,
        hasConventionEcole: false,
    });

    const [results, setResults] = useState<TaxeResults | null>(null);

    const calculateTaxe = () => {
        const masseSalariale = parseFloat(formData.masseSalariale) || 0;
        const nbApprentis = parseInt(formData.nbApprentis) || 0;

        // Calcul de la taxe brute (0.68% de la masse salariale)
        const taxeBrute = masseSalariale * 0.0068;

        // Répartition de la taxe
        const quoteSolde = taxeBrute * 0.13; // 13% pour le solde
        const quoteCfa = taxeBrute * 0.87; // 87% pour les CFA

        // Calcul des réductions
        const reductionApprentis = formData.hasAccueilApprentis ? Math.min(nbApprentis * 415, quoteSolde * 0.1) : 0;

        const reductionConvention = formData.hasConventionEcole ? Math.min(quoteSolde * 0.1, 2000) : 0;

        // Montant final dû
        const montantDu = taxeBrute - reductionApprentis - reductionConvention;

        // Détail de la répartition
        const repartition = [
            {
                label: 'Part principale (87%)',
                montant: quoteCfa,
                description: "Financement des CFA et de l'apprentissage",
            },
            {
                label: 'Solde (13%)',
                montant: quoteSolde,
                description: 'Financement des formations technologiques et professionnelles',
            },
            {
                label: 'Réduction apprentis',
                montant: reductionApprentis,
                description: `${nbApprentis} apprenti(s) × 415€ (plafonné à 10% du solde)`,
            },
            {
                label: 'Réduction convention',
                montant: reductionConvention,
                description: 'Réduction pour convention avec une école (plafonné à 10% du solde)',
            },
        ];

        setResults({
            taxeBrute,
            quoteSolde,
            quoteCfa,
            reductionApprentis,
            reductionConvention,
            montantDu,
            repartition,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de taxe d'apprentissage
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Masse salariale brute (€)"
                        type="number"
                        value={formData.masseSalariale}
                        onChange={e => setFormData(prev => ({ ...prev, masseSalariale: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre d'apprentis"
                        type="number"
                        value={formData.nbApprentis}
                        onChange={e => setFormData(prev => ({ ...prev, nbApprentis: e.target.value }))}
                        placeholder="Ex: 2"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Département"
                        value={formData.departement}
                        onChange={e => setFormData(prev => ({ ...prev, departement: e.target.value }))}
                        placeholder="Ex: 75"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.hasAccueilApprentis}
                                onChange={e =>
                                    setFormData(prev => ({ ...prev, hasAccueilApprentis: e.target.checked }))
                                }
                            />
                        }
                        label="Accueil d'apprentis dans l'année"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.hasConventionEcole}
                                onChange={e => setFormData(prev => ({ ...prev, hasConventionEcole: e.target.checked }))}
                            />
                        }
                        label="Convention avec une école"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateTaxe}
                    disabled={!formData.masseSalariale}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer la taxe
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Masse salariale</span>
                        <span className="value">{parseFloat(formData.masseSalariale).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Taxe brute (0,68%)</span>
                        <span className="value">{Math.round(results.taxeBrute).toLocaleString('fr-FR')} €</span>
                    </div>

                    {results.repartition.map((part, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{part.label}</span>
                                <span className="value">{Math.round(part.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {part.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Montant total à verser</span>
                        <span className="value">{Math.round(results.montantDu).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default TaxeApprentissage;
