import React, { useState } from 'react';
import {
    TextField,
    Button,
    Paper,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
} from '@mui/material';

interface GapData {
    prixCession: string;
    dureeGarantie: string;
    plafondGarantie: string;
    franchise: string;
    typeGarantie: 'bancaire' | 'sequestre' | 'caution';
    risques: {
        fiscal: boolean;
        social: boolean;
        environnemental: boolean;
        clients: boolean;
        fournisseurs: boolean;
        litiges: boolean;
    };
}

interface GapResults {
    montantGarantie: number;
    coutGarantie: number;
    risquesPotentiels: {
        risque: string;
        impact: number;
        probabilite: number;
        provision: number;
    }[];
    recommandations: string[];
}

const GarantieActifPassif: React.FC = () => {
    const [formData, setFormData] = useState<GapData>({
        prixCession: '',
        dureeGarantie: '',
        plafondGarantie: '',
        franchise: '',
        typeGarantie: 'sequestre',
        risques: {
            fiscal: false,
            social: false,
            environnemental: false,
            clients: false,
            fournisseurs: false,
            litiges: false,
        },
    });

    const [results, setResults] = useState<GapResults | null>(null);

    const calculateGap = () => {
        const prixCession = parseFloat(formData.prixCession) || 0;
        const dureeGarantie = parseInt(formData.dureeGarantie) || 0;
        const plafondGarantie = parseFloat(formData.plafondGarantie) || 0;
        const franchise = parseFloat(formData.franchise) || 0;

        // Calcul du montant de la garantie
        const montantGarantie = Math.min(prixCession * (plafondGarantie / 100), prixCession);

        // Calcul du coût de la garantie selon le type
        let coutGarantie = 0;
        switch (formData.typeGarantie) {
            case 'bancaire':
                coutGarantie = montantGarantie * 0.02 * dureeGarantie; // 2% par an
                break;
            case 'sequestre':
                coutGarantie = montantGarantie * 0.005; // 0.5% frais de séquestre
                break;
            case 'caution':
                coutGarantie = montantGarantie * 0.01 * dureeGarantie; // 1% par an
                break;
        }

        // Évaluation des risques potentiels
        const risquesPotentiels = [];

        if (formData.risques.fiscal) {
            risquesPotentiels.push({
                risque: 'Risque fiscal',
                impact: prixCession * 0.15,
                probabilite: 0.2,
                provision: prixCession * 0.15 * 0.2,
            });
        }

        if (formData.risques.social) {
            risquesPotentiels.push({
                risque: 'Risque social',
                impact: prixCession * 0.1,
                probabilite: 0.15,
                provision: prixCession * 0.1 * 0.15,
            });
        }

        if (formData.risques.environnemental) {
            risquesPotentiels.push({
                risque: 'Risque environnemental',
                impact: prixCession * 0.25,
                probabilite: 0.1,
                provision: prixCession * 0.25 * 0.1,
            });
        }

        if (formData.risques.clients) {
            risquesPotentiels.push({
                risque: 'Risque clients',
                impact: prixCession * 0.08,
                probabilite: 0.25,
                provision: prixCession * 0.08 * 0.25,
            });
        }

        if (formData.risques.fournisseurs) {
            risquesPotentiels.push({
                risque: 'Risque fournisseurs',
                impact: prixCession * 0.05,
                probabilite: 0.15,
                provision: prixCession * 0.05 * 0.15,
            });
        }

        if (formData.risques.litiges) {
            risquesPotentiels.push({
                risque: 'Risques litiges',
                impact: prixCession * 0.12,
                probabilite: 0.18,
                provision: prixCession * 0.12 * 0.18,
            });
        }

        // Génération des recommandations
        const recommandations = [];

        const totalRisques = risquesPotentiels.reduce((sum, r) => sum + r.provision, 0);
        if (totalRisques > montantGarantie) {
            recommandations.push(
                'Le montant de la garantie pourrait être insuffisant par rapport aux risques identifiés',
            );
        }

        if (dureeGarantie < 3) {
            recommandations.push(
                'La durée de la garantie est courte, certains risques pourraient se matérialiser plus tard',
            );
        }

        if (franchise > montantGarantie * 0.1) {
            recommandations.push("La franchise est élevée, elle pourrait limiter l'efficacité de la garantie");
        }

        if (formData.risques.fiscal && dureeGarantie < 4) {
            recommandations.push("Pour le risque fiscal, une durée de garantie d'au moins 4 ans est recommandée");
        }

        if (Object.values(formData.risques).filter(r => r).length > 3 && formData.typeGarantie !== 'bancaire') {
            recommandations.push('Vu le nombre de risques identifiés, une garantie bancaire serait plus sécurisante');
        }

        setResults({
            montantGarantie,
            coutGarantie,
            risquesPotentiels,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur de garantie d'actif-passif
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Prix de cession (€)"
                        type="number"
                        value={formData.prixCession}
                        onChange={e => setFormData(prev => ({ ...prev, prixCession: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Durée de la garantie (années)"
                        type="number"
                        value={formData.dureeGarantie}
                        onChange={e => setFormData(prev => ({ ...prev, dureeGarantie: e.target.value }))}
                        placeholder="Ex: 3"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Plafond de garantie (% du prix)"
                        type="number"
                        value={formData.plafondGarantie}
                        onChange={e => setFormData(prev => ({ ...prev, plafondGarantie: e.target.value }))}
                        placeholder="Ex: 30"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Franchise (€)"
                        type="number"
                        value={formData.franchise}
                        onChange={e => setFormData(prev => ({ ...prev, franchise: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeGarantie}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                typeGarantie: e.target.value as 'bancaire' | 'sequestre' | 'caution',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="bancaire"
                            control={<Radio />}
                            label="Garantie bancaire"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="sequestre"
                            control={<Radio />}
                            label="Séquestre"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="caution"
                            control={<Radio />}
                            label="Caution personnelle"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                    Risques identifiés
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.risques.fiscal}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        risques: { ...prev.risques, fiscal: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Risque fiscal"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.risques.social}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        risques: { ...prev.risques, social: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Risque social"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.risques.environnemental}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        risques: { ...prev.risques, environnemental: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Risque environnemental"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.risques.clients}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        risques: { ...prev.risques, clients: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Risque clients"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.risques.fournisseurs}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        risques: { ...prev.risques, fournisseurs: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Risque fournisseurs"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.risques.litiges}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        risques: { ...prev.risques, litiges: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Risques litiges"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateGap}
                    disabled={!formData.prixCession || !formData.dureeGarantie || !formData.plafondGarantie}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Analyser la garantie
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Montant de la garantie</span>
                        <span className="value">{Math.round(results.montantGarantie).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Coût de la garantie</span>
                        <span className="value">{Math.round(results.coutGarantie).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Analyse des risques</span>
                        <span className="value"></span>
                    </div>

                    {results.risquesPotentiels.map((risque, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{risque.risque}</span>
                                <span className="value">
                                    Impact : {Math.round(risque.impact).toLocaleString('fr-FR')} € (
                                    {(risque.probabilite * 100).toFixed(0)}%)
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">Provision recommandée</span>
                                <span className="value">{Math.round(risque.provision).toLocaleString('fr-FR')} €</span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default GarantieActifPassif;
