import * as React from 'react';
import { createContext, ReactNode, useContext } from 'react';

import { HEADER } from '../components/layout/header/constants/header.const';
import useStickyHeader, { StickyHeaderReturnType } from '../components/layout/header/hooks/useStickyHeader';
import useBreakpoints from '../hooks/useBreakpoints';
import useIsScrollDown from '../hooks/useIsScrollDown';

type HeaderContextType = {
    hasHeaderMedia: boolean;
    isMinimal: boolean;
    bannerOptions?: {
        isVisible: boolean;
    };
    stickyHeader?: StickyHeaderReturnType & { height?: number; dynamicHeight?: number };
    isScrollingDown?: boolean;
    forceWhiteBackground?: boolean;
};

const HeaderContext = createContext<HeaderContextType>({
    hasHeaderMedia: false,
    isMinimal: false,
    bannerOptions: {
        isVisible: false,
    },
    stickyHeader: {
        hasReachedScrollBreakpoint: false,
        ref: undefined,
    },
    isScrollingDown: false,
    forceWhiteBackground: false,
});

export default function HeaderContextProvider({
    context,
    children,
}: {
    context: HeaderContextType;
    children: ReactNode;
}) {
    const stickyHeader = useStickyHeader();
    const isScrollingDown = useIsScrollDown();
    const isDesktop = useBreakpoints(['medium', 'large', 'xlarge', 'xxlarge']);

    const getHeaderHeight = (isMinimal: boolean, hasHeaderMedia: boolean, bannerIsVisible: boolean) => {
        const { HEIGHT, IS_BANNER_FIXED } = HEADER;
        const baseHeight = isMinimal ? HEIGHT.MINIMAL : HEIGHT.MAIN;

        let totalHeight = baseHeight;

        if (IS_BANNER_FIXED && bannerIsVisible) {
            if (isDesktop) {
                totalHeight += HEIGHT.BANNER.PROMO_DESKTOP;
            } else {
                totalHeight += HEIGHT.BANNER.PROMO_MOBILE;
            }
        }

        if (hasHeaderMedia) {
            totalHeight += HEIGHT.MEDIA;
        }

        return totalHeight;
    };

    const headerHeight = getHeaderHeight(context.isMinimal, context.hasHeaderMedia, context.bannerOptions.isVisible);

    const dynamicHeight = isScrollingDown && context.hasHeaderMedia ? headerHeight - HEADER.HEIGHT.MAIN : headerHeight;

    return (
        <HeaderContext.Provider
            value={{
                ...context,
                stickyHeader: {
                    ...stickyHeader,
                    height: headerHeight,
                    dynamicHeight: dynamicHeight,
                },
                isScrollingDown,
            }}
        >
            {children}
        </HeaderContext.Provider>
    );
}

export function useHeaderContext() {
    return useContext(HeaderContext);
}
