export const HEADER = {
    IS_BANNER_FIXED: process.env.GATSBY_IS_GREEN_BANNER_FIXED === 'true',
    HEIGHT: {
        MAIN: 72,
        MINIMAL: 64,
        BANNER: {
            NORMAL: 72,
            PROMO_MOBILE: 100,
            PROMO_DESKTOP: 50,
        },
        MEDIA: 72,
    },
} as const;
