import React from 'react';
import styled from 'styled-components';

import { simulators } from '../../../data/simulators';
import SimulatorProvider from '../context/SimulatorProvider';

const Background = styled.div`
    border-radius: 4px;
    border: 1px solid #00000024;
`;

const SimulatorSlice = ({
    slice: {
        primary: { simulator: simulatorId },
    },
}: {
    slice: {
        primary: {
            simulator: string;
        };
    };
}) => {
    const simulator = simulators.find(s => s.id === simulatorId);

    return (
        <Background>
            <SimulatorProvider>
                <simulator.Component />
                <div
                    className="simulator-disclaimer"
                    style={{
                        marginTop: '2rem',
                        padding: '1rem',
                        backgroundColor: '#f8f9fa',
                        borderRadius: '4px',
                        fontSize: '0.9rem',
                        color: '#666',
                    }}
                >
                    Bien que tous les efforts aient été consentis pour s'assurer que les informations contenues sur nos
                    simulateurs sont correctes, Dougs décline toute responsabilité, légale ou autre, pour toute erreur
                    ou omission.
                </div>
            </SimulatorProvider>
        </Background>
    );
};

export default SimulatorSlice;
