export const identifyHubspot = (email: string) => {
    if (typeof window === 'undefined') return;
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
        'identify',
        {
            email: email,
            originalSource: 'direct',
        },
    ]);
};
