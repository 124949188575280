import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface FinancialData {
    initialInvestment: string;
    monthlyRevenue: string;
    monthlyExpenses: string;
    activityType: 'commerce' | 'service' | 'artisanat';
    growthRate: string;
}

interface YearlyResults {
    revenue: number;
    expenses: number;
    profit: number;
}

const PrevisionelFinancier: React.FC = () => {
    const [formData, setFormData] = useState<FinancialData>({
        initialInvestment: '',
        monthlyRevenue: '',
        monthlyExpenses: '',
        activityType: 'commerce',
        growthRate: '5',
    });

    const [results, setResults] = useState<YearlyResults[]>(null);

    const calculatePrevisionnel = () => {
        const investment = parseFloat(formData.initialInvestment) || 0;
        const monthlyRevenue = parseFloat(formData.monthlyRevenue) || 0;
        const monthlyExpenses = parseFloat(formData.monthlyExpenses) || 0;
        const growthRate = parseFloat(formData.growthRate) / 100;

        const yearlyResults: YearlyResults[] = [];
        let currentRevenue = monthlyRevenue * 12;
        let currentExpenses = monthlyExpenses * 12 + investment / 3;

        for (let year = 1; year <= 3; year++) {
            yearlyResults.push({
                revenue: Math.round(currentRevenue),
                expenses: Math.round(currentExpenses),
                profit: Math.round(currentRevenue - currentExpenses),
            });

            currentRevenue *= 1 + growthRate;
            currentExpenses *= 1 + growthRate / 2;
        }

        setResults(yearlyResults);
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Prévisionnel financier sur 3 ans
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.activityType}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                activityType: e.target.value as 'commerce' | 'service' | 'artisanat',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="artisanat"
                            control={<Radio />}
                            label="Artisanat"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                        fullWidth
                        label="Investissement initial (€)"
                        type="number"
                        value={formData.initialInvestment}
                        onChange={e => setFormData(prev => ({ ...prev, initialInvestment: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires mensuel estimé (€)"
                        type="number"
                        value={formData.monthlyRevenue}
                        onChange={e => setFormData(prev => ({ ...prev, monthlyRevenue: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                        fullWidth
                        label="Charges mensuelles estimées (€)"
                        type="number"
                        value={formData.monthlyExpenses}
                        onChange={e => setFormData(prev => ({ ...prev, monthlyExpenses: e.target.value }))}
                        placeholder="Ex: 3000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux de croissance annuel (%)"
                        type="number"
                        value={formData.growthRate}
                        onChange={e => setFormData(prev => ({ ...prev, growthRate: e.target.value }))}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePrevisionnel}
                    disabled={!formData.monthlyRevenue || !formData.monthlyExpenses}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer le prévisionnel
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.map((year, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">Année {index + 1}</span>
                                <span className="value"></span>
                            </div>
                            <div className="result-row">
                                <span className="label">Chiffre d'affaires</span>
                                <span className="value">{year.revenue.toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row">
                                <span className="label">Charges</span>
                                <span className="value">{year.expenses.toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className={`result-row ${index === results.length - 1 ? 'highlight' : ''}`}>
                                <span className="label">Résultat</span>
                                <span className="value" style={{ color: year.profit >= 0 ? undefined : 'red' }}>
                                    {year.profit.toLocaleString('fr-FR')} €
                                </span>
                            </div>
                        </React.Fragment>
                    ))}
                </Paper>
            )}
        </div>
    );
};

export default PrevisionelFinancier;
