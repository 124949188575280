import AnalyseConcurrentielle from '../components/simulators/simulator/AnalyseConcurrentielle';
import BesoinFondsRoulement from '../components/simulators/simulator/BesoinFondsRoulement';
import BudgetMarketing from '../components/simulators/simulator/BudgetMarketing';
import BulletinPaie from '../components/simulators/simulator/BulletinPaie';
import CalculateurTva from '../components/simulators/simulator/CalculateurTva';
import CapaciteAutofinancement from '../components/simulators/simulator/CapaciteAutofinancement';
import CapitalSocial from '../components/simulators/simulator/CapitalSocial';
import ChargesSociales from '../components/simulators/simulator/ChargesSociales';
import ComparatifStatuts from '../components/simulators/simulator/ComparatifStatuts';
import ContributionFormation from '../components/simulators/simulator/ContributionFormation';
import CotisationFonciere from '../components/simulators/simulator/CotisationFonciere';
import CotisationValeurAjoutee from '../components/simulators/simulator/CotisationValeurAjoutee';
import CoutAffacturage from '../components/simulators/simulator/CoutAffacturage';
import CoutEmbauche from '../components/simulators/simulator/CoutEmbauche';
import CoutLicenciement from '../components/simulators/simulator/CoutLicenciement';
import CoutPersonnel from '../components/simulators/simulator/CoutPersonnel';
import CoutRecrutement from '../components/simulators/simulator/CoutRecrutement';
import CoutRevient from '../components/simulators/simulator/CoutRevient';
import CreditImpot from '../components/simulators/simulator/CreditImpot';
import DelaisPaiement from '../components/simulators/simulator/DelaisPaiement';
import DiagnosticFinancier from '../components/simulators/simulator/DiagnosticFinancier';
import EffetLevier from '../components/simulators/simulator/EffetLevier';
import EscompteCommercial from '../components/simulators/simulator/EscompteCommercial';
import EvaluationEntreprise from '../components/simulators/simulator/EvaluationEntreprise';
import FormeJuridique from '../components/simulators/simulator/FormeJuridique';
import FraisDecouvert from '../components/simulators/simulator/FraisDecouvert';
import FraisKilometriques from '../components/simulators/simulator/FraisKilometriques';
import GarantieActifPassif from '../components/simulators/simulator/GarantieActifPassif';
import GestionStocks from '../components/simulators/simulator/GestionStocks';
import HeuresSupplementaires from '../components/simulators/simulator/HeuresSupplementaires';
import ImpactEcologique from '../components/simulators/simulator/ImpactEcologique';
import ImpotRevenuTNS from '../components/simulators/simulator/ImpotRevenuTNS';
import ImpotSocietes from '../components/simulators/simulator/ImpotSocietes';
import IndemnitesConges from '../components/simulators/simulator/IndemnitesConges';
import Interessement from '../components/simulators/simulator/Interessement';
import LoiMadelin from '../components/simulators/simulator/LoiMadelin';
import MargeCommerciale from '../components/simulators/simulator/MargeCommerciale';
import OptimisationRemuneration from '../components/simulators/simulator/OptimisationRemuneration';
import OptimisationStocks from '../components/simulators/simulator/OptimisationStocks';
import PacteAssocies from '../components/simulators/simulator/PacteAssocies';
import ParticipationBenefices from '../components/simulators/simulator/ParticipationBenefices';
import PlafondsMicro from '../components/simulators/simulator/PlafondsMicro';
import PlafondsSociaux from '../components/simulators/simulator/PlafondsSociaux';
import PlanAmortissement from '../components/simulators/simulator/PlanAmortissement';
import PlanFinancement from '../components/simulators/simulator/PlanFinancement';
import PlanInvestissement from '../components/simulators/simulator/PlanInvestissement';
import PlanTresorerie from '../components/simulators/simulator/PlanTresorerie';
import PlusValueCession from '../components/simulators/simulator/PlusValueCession';
import PrevisionelFinancier from '../components/simulators/simulator/PrevisionelFinancier';
import PrevoyanceTns from '../components/simulators/simulator/PrevoyanceTns';
import PrimePrecarite from '../components/simulators/simulator/PrimePrecarite';
import PrixVente from '../components/simulators/simulator/PrixVente';
import RatiosFinanciers from '../components/simulators/simulator/RatiosFinanciers';
import RentabiliteProjet from '../components/simulators/simulator/RentabiliteProjet';
import RetraiteTns from '../components/simulators/simulator/RetraiteTns';
import RotationStocks from '../components/simulators/simulator/RotationStocks';
import RuptureConventionnelle from '../components/simulators/simulator/RuptureConventionnelle';
import SalaireBrutNet from '../components/simulators/simulator/SalaireBrutNet';
import SasuEurl from '../components/simulators/simulator/SasuEurl';
import ScoringFinancier from '../components/simulators/simulator/ScoringFinancier';
import SeuilRentabilite from '../components/simulators/simulator/SeuilRentabilite';
import SimulateurEmprunt from '../components/simulators/simulator/SimulateurEmprunt';
import TableauFinancement from '../components/simulators/simulator/TableauFinancement';
import TaxeApprentissage from '../components/simulators/simulator/TaxeApprentissage';
import TaxeVehiculesSociete from '../components/simulators/simulator/TaxeVehiculesSociete';
import { Categories, Simulator } from '../types/simulator';

export const simulators: Simulator[] = [
    // Création d'entreprise
    {
        isActive: false,
        id: 'forme-juridique',
        Component: FormeJuridique,
        title: 'Choix de la forme juridique',
        description: 'Trouvez la structure juridique la plus adaptée à votre projet',
        url: '/outils-simulation/forme-juridique',
        category: 'creation',
        seoFragments: {
            titleText: 'Choix de la forme juridique',
            metaDesc: 'Trouvez la structure juridique la plus adaptée à votre projet',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'capital-social',
        Component: CapitalSocial,
        title: 'Capital social',
        description: 'Déterminez votre capital social optimal',
        url: '/outils-simulation/capital-social',
        category: 'creation',
        seoFragments: {
            titleText: 'Capital social',
            metaDesc: 'Déterminez votre capital social optimal',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'pacte-associes',
        Component: PacteAssocies,
        title: "Pacte d'associés",
        description: 'Simulez différentes clauses du pacte',
        url: '/outils-simulation/pacte-associes',
        category: 'creation',
        seoFragments: {
            titleText: "Pacte d'associés",
            metaDesc: 'Simulez différentes clauses du pacte',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'comparatif-statuts',
        Component: ComparatifStatuts,
        title: 'Comparatif statuts',
        description: 'Comparez les différents statuts juridiques',
        url: '/outils-simulation/comparatif-statuts',
        category: 'creation',
        seoFragments: {
            titleText: 'Comparatif statuts',
            metaDesc: 'Comparez les différents statuts juridiques',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'sasu-eurl',
        Component: SasuEurl,
        title: 'SASU vs EURL',
        description: 'Comparez les avantages fiscaux et sociaux entre SASU et EURL',
        url: '/outils-simulation/sasu-eurl',
        category: 'creation',
        seoFragments: {
            titleText: 'SASU vs EURL',
            metaDesc: 'Comparez les avantages fiscaux et sociaux entre SASU et EURL',
            metaRobotsNoindex: '1',
        },
    },

    // Fiscalité
    {
        isActive: true,
        id: 'tva',
        Component: CalculateurTva,
        title: 'Calculateur de TVA',
        description: 'Calculez la TVA selon différents taux',
        url: '/outils-simulation/tva',
        category: 'fiscalite',
        seoFragments: {
            titleText: 'Calculateur de TVA',
            metaDesc: 'Calculez la TVA selon différents taux',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'impot-societes',
        Component: ImpotSocietes,
        title: 'Impôt sur les sociétés',
        description: 'Estimez votre impôt sur les sociétés et vos acomptes',
        url: '/outils-simulation/impot-societes',
        category: 'fiscalite',
        seoFragments: {
            titleText: 'Impôt sur les sociétés',
            metaDesc: 'Estimez votre impôt sur les sociétés et vos acomptes',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'impot-revenu',
        Component: ImpotRevenuTNS,
        title: 'Impôt sur le revenu TNS',
        description: "Calculez votre impôt sur le revenu en tant qu'indépendant",
        url: '/outils-simulation/impot-revenu',
        category: 'fiscalite',
        seoFragments: {
            titleText: 'Impôt sur le revenu TNS',
            metaDesc: "Calculez votre impôt sur le revenu en tant qu'indépendant",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'cfe',
        Component: CotisationFonciere,
        title: 'CFE',
        description: 'Estimez votre Cotisation Foncière des Entreprises',
        url: '/outils-simulation/cfe',
        category: 'fiscalite',
        seoFragments: {
            titleText: 'CFE',
            metaDesc: 'Estimez votre Cotisation Foncière des Entreprises',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'cvae',
        Component: CotisationValeurAjoutee,
        title: 'CVAE',
        description: 'Calculez votre Cotisation sur la Valeur Ajoutée',
        url: '/outils-simulation/cvae',
        category: 'fiscalite',
        seoFragments: {
            titleText: 'CVAE',
            metaDesc: 'Calculez votre Cotisation sur la Valeur Ajoutée',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'credit-impot',
        Component: CreditImpot,
        title: "Crédits d'impôt",
        description: "Estimez vos crédits d'impôt (CIR, CICE...)",
        url: '/outils-simulation/credit-impot',
        category: 'fiscalite',
        seoFragments: {
            titleText: "Crédits d'impôt",
            metaDesc: "Estimez vos crédits d'impôt (CIR, CICE...)",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'plafond-micro',
        Component: PlafondsMicro,
        title: 'Plafonds micro-entreprise',
        description: 'Vérifiez si vous dépassez les seuils du régime micro',
        url: '/outils-simulation/plafond-micro',
        category: 'fiscalite',
        seoFragments: {
            titleText: 'Plafonds micro-entreprise',
            metaDesc: 'Vérifiez si vous dépassez les seuils du régime micro',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'tvs',
        Component: TaxeVehiculesSociete,
        title: 'Taxes véhicules société (TVS)',
        description: 'Calculez vos taxes sur les véhicules de société (CO₂ et polluants)',
        url: '/outils-simulation/tvs',
        category: 'fiscalite',
        seoFragments: {
            titleText: 'Taxes véhicules société',
            metaDesc:
                'Calculez vos taxes annuelles sur les émissions de CO₂ et polluants atmosphériques des véhicules de société',
            metaRobotsNoindex: '1',
        },
    },

    // Charges sociales
    {
        isActive: false,
        id: 'charges-sociales',
        Component: ChargesSociales,
        title: 'Calcul des charges sociales',
        description: 'Estimez vos charges sociales selon votre statut',
        url: '/outils-simulation/charges-sociales',
        category: 'social',
        seoFragments: {
            titleText: 'Calcul des charges sociales',
            metaDesc: 'Estimez vos charges sociales selon votre statut',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'taxe-apprentissage',
        Component: TaxeApprentissage,
        title: "Taxe d'apprentissage",
        description: "Estimez votre taxe d'apprentissage et sa répartition",
        url: '/outils-simulation/taxe-apprentissage',
        category: 'social',
        seoFragments: {
            titleText: "Taxe d'apprentissage",
            metaDesc: "Estimez votre taxe d'apprentissage et sa répartition",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'contribution-formation',
        Component: ContributionFormation,
        title: 'Contribution formation',
        description: 'Calculez votre contribution à la formation professionnelle',
        url: '/outils-simulation/contribution-formation',
        category: 'social',
        seoFragments: {
            titleText: 'Contribution formation',
            metaDesc: 'Calculez votre contribution à la formation professionnelle',
            metaRobotsNoindex: '1',
        },
    },

    // Rémunération
    {
        isActive: false,
        id: 'salaire-brut-net',
        Component: SalaireBrutNet,
        title: 'Calculateur Brut/Net',
        description: 'Calculez les conversions entre salaire brut et net',
        url: '/outils-simulation/salaire-brut-net',
        category: 'remuneration',
        seoFragments: {
            titleText: 'Calculateur Brut/Net',
            metaDesc: 'Calculez les conversions entre salaire brut et net',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'optimisation-remuneration',
        Component: OptimisationRemuneration,
        title: 'Optimisation de la rémunération',
        description: 'Trouvez le meilleur équilibre entre salaire et dividendes',
        url: '/outils-simulation/optimisation-remuneration',
        category: 'remuneration',
        seoFragments: {
            titleText: 'Optimisation de la rémunération',
            metaDesc: 'Trouvez le meilleur équilibre entre salaire et dividendes',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'frais-kilometriques',
        Component: FraisKilometriques,
        title: 'Frais kilométriques',
        description: 'Calculez vos indemnités kilométriques',
        url: '/outils-simulation/frais-kilometriques',
        category: 'remuneration',
        seoFragments: {
            titleText: 'Frais kilométriques',
            metaDesc: 'Calculez vos indemnités kilométriques',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'bulletin-paie',
        Component: BulletinPaie,
        title: 'Bulletin de paie',
        description: 'Simulez un bulletin de paie complet',
        url: '/outils-simulation/bulletin-paie',
        category: 'remuneration',
        seoFragments: {
            titleText: 'Bulletin de paie',
            metaDesc: 'Simulez un bulletin de paie complet',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'heures-supplementaires',
        Component: HeuresSupplementaires,
        title: 'Heures supplémentaires',
        description: 'Calculez le coût et la majoration des heures sup',
        url: '/outils-simulation/heures-supplementaires',
        category: 'remuneration',
        seoFragments: {
            titleText: 'Heures supplémentaires',
            metaDesc: 'Calculez le coût et la majoration des heures sup',
            metaRobotsNoindex: '1',
        },
    },

    // Gestion du personnel
    {
        isActive: false,
        id: 'cout-embauche',
        Component: CoutEmbauche,
        title: "Coût d'une embauche",
        description: "Calculez le coût total d'un recrutement",
        url: '/outils-simulation/cout-embauche',
        category: 'rh',
        seoFragments: {
            titleText: "Coût d'une embauche",
            metaDesc: "Calculez le coût total d'un recrutement",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'charges-personnel',
        Component: CoutPersonnel,
        title: 'Coût du personnel',
        description: "Estimez le coût complet d'un salarié",
        url: '/outils-simulation/charges-personnel',
        category: 'rh',
        seoFragments: {
            titleText: 'Coût du personnel',
            metaDesc: "Estimez le coût complet d'un salarié",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'rupture-conventionnelle',
        Component: RuptureConventionnelle,
        title: 'Rupture conventionnelle',
        description: 'Calculez les indemnités de rupture conventionnelle',
        url: '/outils-simulation/rupture-conventionnelle',
        category: 'rh',
        seoFragments: {
            titleText: 'Rupture conventionnelle',
            metaDesc: 'Calculez les indemnités de rupture conventionnelle',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'indemnites-conges',
        Component: IndemnitesConges,
        title: 'Indemnités congés payés',
        description: 'Calculez les indemnités de congés payés',
        url: '/outils-simulation/indemnites-conges',
        category: 'rh',
        seoFragments: {
            titleText: 'Indemnités congés payés',
            metaDesc: 'Calculez les indemnités de congés payés',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'prime-precarite',
        Component: PrimePrecarite,
        title: 'Prime de précarité',
        description: 'Calculez la prime de fin de CDD',
        url: '/outils-simulation/prime-precarite',
        category: 'rh',
        seoFragments: {
            titleText: 'Prime de précarité',
            metaDesc: 'Calculez la prime de fin de CDD',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'participation',
        Component: ParticipationBenefices,
        title: 'Participation aux bénéfices',
        description: 'Calculez la participation des salariés',
        url: '/outils-simulation/participation',
        category: 'rh',
        seoFragments: {
            titleText: 'Participation aux bénéfices',
            metaDesc: 'Calculez la participation des salariés',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'interessement',
        Component: Interessement,
        title: 'Intéressement',
        description: "Simulez votre accord d'intéressement",
        url: '/outils-simulation/interessement',
        category: 'rh',
        seoFragments: {
            titleText: 'Intéressement',
            metaDesc: "Simulez votre accord d'intéressement",
            metaRobotsNoindex: '1',
        },
    },

    // Gestion financière
    {
        isActive: false,
        id: 'previsionnel',
        Component: PrevisionelFinancier,
        title: 'Prévisionnel financier',
        description: 'Créez votre prévisionnel financier sur 3 ans',
        url: '/outils-simulation/previsionnel',
        category: 'finance',
        seoFragments: {
            titleText: 'Prévisionnel financier',
            metaDesc: 'Créez votre prévisionnel financier sur 3 ans',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'tresorerie',
        Component: PlanTresorerie,
        title: 'Plan de trésorerie',
        description: 'Prévoyez votre trésorerie sur 12 mois',
        url: '/outils-simulation/tresorerie',
        category: 'finance',
        seoFragments: {
            titleText: 'Plan de trésorerie',
            metaDesc: 'Prévoyez votre trésorerie sur 12 mois',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'besoin-fonds-roulement',
        Component: BesoinFondsRoulement,
        title: 'BFR',
        description: 'Calculez votre Besoin en Fonds de Roulement',
        url: '/outils-simulation/besoin-fonds-roulement',
        category: 'finance',
        seoFragments: {
            titleText: 'BFR',
            metaDesc: 'Calculez votre Besoin en Fonds de Roulement',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'ratios',
        Component: RatiosFinanciers,
        title: 'Ratios financiers',
        description: 'Calculez et analysez vos ratios financiers clés',
        url: '/outils-simulation/ratios',
        category: 'finance',
        seoFragments: {
            titleText: 'Ratios financiers',
            metaDesc: 'Calculez et analysez vos ratios financiers clés',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'scoring',
        Component: ScoringFinancier,
        title: 'Scoring financier',
        description: 'Évaluez la santé financière de votre entreprise',
        url: '/outils-simulation/scoring',
        category: 'finance',
        seoFragments: {
            titleText: 'Scoring financier',
            metaDesc: 'Évaluez la santé financière de votre entreprise',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'capacite-autofinancement',
        Component: CapaciteAutofinancement,
        title: "Capacité d'autofinancement",
        description: 'Calculez votre CAF et votre MBA',
        url: '/outils-simulation/capacite-autofinancement',
        category: 'finance',
        seoFragments: {
            titleText: "Capacité d'autofinancement",
            metaDesc: 'Calculez votre CAF et votre MBA',
            metaRobotsNoindex: '1',
        },
    },

    // Investissement et financement
    {
        isActive: true,
        id: 'emprunt',
        Component: SimulateurEmprunt,
        title: "Simulateur d'emprunt",
        description: 'Calculez vos mensualités de remboursement',
        url: '/outils-simulation/emprunt',
        category: 'investissement',
        seoFragments: {
            titleText: "Simulateur d'emprunt",
            metaDesc: 'Calculez vos mensualités de remboursement',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'amortissement',
        Component: PlanAmortissement,
        title: "Plan d'amortissement",
        description: "Calculez l'amortissement de vos immobilisations",
        url: '/outils-simulation/amortissement',
        category: 'investissement',
        seoFragments: {
            titleText: "Plan d'amortissement",
            metaDesc: "Calculez l'amortissement de vos immobilisations",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'effet-levier',
        Component: EffetLevier,
        title: 'Effet de levier',
        description: "Mesurez l'impact de l'endettement sur votre rentabilité",
        url: '/outils-simulation/effet-levier',
        category: 'investissement',
        seoFragments: {
            titleText: 'Effet de levier',
            metaDesc: "Mesurez l'impact de l'endettement sur votre rentabilité",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'investissements',
        Component: PlanInvestissement,
        title: "Plan d'investissement",
        description: 'Planifiez vos investissements et leur financement',
        url: '/outils-simulation/investissements',
        category: 'investissement',
        seoFragments: {
            titleText: "Plan d'investissement",
            metaDesc: 'Planifiez vos investissements et leur financement',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'tableau-financement',
        Component: TableauFinancement,
        title: 'Tableau de financement',
        description: 'Construisez votre tableau de financement',
        url: '/outils-simulation/tableau-financement',
        category: 'investissement',
        seoFragments: {
            titleText: 'Tableau de financement',
            metaDesc: 'Construisez votre tableau de financement',
            metaRobotsNoindex: '1',
        },
    },

    // Rentabilité et gestion commerciale
    {
        isActive: true,
        id: 'seuil-rentabilite',
        Component: SeuilRentabilite,
        title: 'Seuil de rentabilité',
        description: 'Déterminez votre point mort et seuil de rentabilité',
        url: '/outils-simulation/seuil-rentabilite',
        category: 'rentabilite',
        seoFragments: {
            titleText: 'Seuil de rentabilité',
            metaDesc: 'Déterminez votre point mort et seuil de rentabilité',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'prix-vente',
        Component: PrixVente,
        title: 'Prix de vente',
        description: 'Calculez vos prix de vente et marges',
        url: '/outils-simulation/prix-vente',
        category: 'rentabilite',
        seoFragments: {
            titleText: 'Prix de vente',
            metaDesc: 'Calculez vos prix de vente et marges',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'cout-revient',
        Component: CoutRevient,
        title: 'Coût de revient',
        description: 'Déterminez vos coûts de revient',
        url: '/outils-simulation/cout-revient',
        category: 'rentabilite',
        seoFragments: {
            titleText: 'Coût de revient',
            metaDesc: 'Déterminez vos coûts de revient',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'marge-commerciale',
        Component: MargeCommerciale,
        title: 'Marge commerciale',
        description: 'Analysez vos marges par produit',
        url: '/outils-simulation/marge-commerciale',
        category: 'rentabilite',
        seoFragments: {
            titleText: 'Marge commerciale',
            metaDesc: 'Analysez vos marges par produit',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'stock',
        Component: GestionStocks,
        title: 'Gestion des stocks',
        description: 'Optimisez votre gestion des stocks',
        url: '/outils-simulation/stock',
        category: 'rentabilite',
        seoFragments: {
            titleText: 'Gestion des stocks',
            metaDesc: 'Optimisez votre gestion des stocks',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'rotation-stocks',
        Component: RotationStocks,
        title: 'Rotation des stocks',
        description: 'Optimisez votre gestion des stocks',
        url: '/outils-simulation/rotation-stocks',
        category: 'rentabilite',
        seoFragments: {
            titleText: 'Rotation des stocks',
            metaDesc: 'Optimisez votre gestion des stocks',
            metaRobotsNoindex: '1',
        },
    },

    // Trésorerie et financement court terme
    {
        isActive: true,
        id: 'delais-paiement',
        Component: DelaisPaiement,
        title: 'Délais de paiement',
        description: 'Analysez vos délais clients et fournisseurs',
        url: '/outils-simulation/delais-paiement',
        category: 'tresorerie',
        seoFragments: {
            titleText: 'Délais de paiement',
            metaDesc: 'Analysez vos délais clients et fournisseurs',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'affacturage',
        Component: CoutAffacturage,
        title: 'Coût affacturage',
        description: "Simulez le coût d'une solution d'affacturage",
        url: '/outils-simulation/affacturage',
        category: 'tresorerie',
        seoFragments: {
            titleText: 'Coût affacturage',
            metaDesc: "Simulez le coût d'une solution d'affacturage",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'escompte',
        Component: EscompteCommercial,
        title: 'Escompte commercial',
        description: "Calculez le coût de l'escompte",
        url: '/outils-simulation/escompte',
        category: 'tresorerie',
        seoFragments: {
            titleText: 'Escompte commercial',
            metaDesc: "Calculez le coût de l'escompte",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'decouvert',
        Component: FraisDecouvert,
        title: 'Frais de découvert',
        description: "Estimez le coût d'un découvert bancaire",
        url: '/outils-simulation/decouvert',
        category: 'tresorerie',
        seoFragments: {
            titleText: 'Frais de découvert',
            metaDesc: "Estimez le coût d'un découvert bancaire",
            metaRobotsNoindex: '1',
        },
    },

    // Transmission et évaluation
    {
        isActive: false,
        id: 'plus-value',
        Component: PlusValueCession,
        title: 'Plus-value de cession',
        description: "Calculez la plus-value de cession d'entreprise",
        url: '/outils-simulation/plus-value',
        category: 'transmission',
        seoFragments: {
            titleText: 'Plus-value de cession',
            metaDesc: "Calculez la plus-value de cession d'entreprise",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'evaluation-entreprise',
        Component: EvaluationEntreprise,
        title: "Évaluation d'entreprise",
        description: "Estimez la valeur d'une entreprise",
        url: '/outils-simulation/evaluation-entreprise',
        category: 'transmission',
        seoFragments: {
            titleText: "Évaluation d'entreprise",
            metaDesc: "Estimez la valeur d'une entreprise",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'garanties-actif-passif',
        Component: GarantieActifPassif,
        title: "Garantie d'actif-passif",
        description: 'Évaluez les risques et garanties',
        url: '/outils-simulation/garanties-actif-passif',
        category: 'transmission',
        seoFragments: {
            titleText: "Garantie d'actif-passif",
            metaDesc: 'Évaluez les risques et garanties',
            metaRobotsNoindex: '1',
        },
    },

    // Protection sociale
    {
        isActive: false,
        id: 'retraite-tns',
        Component: RetraiteTns,
        title: 'Retraite TNS',
        description: 'Estimez vos droits à la retraite',
        url: '/outils-simulation/retraite-tns',
        category: 'protection',
        seoFragments: {
            titleText: 'Retraite TNS',
            metaDesc: 'Estimez vos droits à la retraite',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'prevoyance-tns',
        Component: PrevoyanceTns,
        title: 'Prévoyance TNS',
        description: 'Évaluez votre couverture prévoyance',
        url: '/outils-simulation/prevoyance-tns',
        category: 'protection',
        seoFragments: {
            titleText: 'Prévoyance TNS',
            metaDesc: 'Évaluez votre couverture prévoyance',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'madelin',
        Component: LoiMadelin,
        title: 'Loi Madelin',
        description: 'Optimisez vos contrats Madelin',
        url: '/outils-simulation/madelin',
        category: 'protection',
        seoFragments: {
            titleText: 'Loi Madelin',
            metaDesc: 'Optimisez vos contrats Madelin',
            metaRobotsNoindex: '1',
        },
    },

    // Nouveaux simulateurs
    {
        isActive: false,
        id: 'plafonds-sociaux',
        Component: PlafondsSociaux,
        title: 'Plafonds sociaux',
        description: 'Calculez vos plafonds de cotisations sociales et retraite',
        url: '/outils-simulation/plafonds-sociaux',
        category: 'social',
        seoFragments: {
            titleText: 'Plafonds sociaux',
            metaDesc: 'Calculez vos plafonds de cotisations sociales et retraite',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'cout-licenciement',
        Component: CoutLicenciement,
        title: 'Coût de licenciement',
        description: "Estimez le coût total d'un licenciement (indemnités, préavis...)",
        url: '/outils-simulation/cout-licenciement',
        category: 'rh',
        seoFragments: {
            titleText: 'Coût de licenciement',
            metaDesc: "Estimez le coût total d'un licenciement (indemnités, préavis...)",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'budget-marketing',
        Component: BudgetMarketing,
        title: 'Budget marketing',
        description: 'Planifiez et suivez votre budget marketing et communication',
        url: '/outils-simulation/budget-marketing',
        category: 'finance',
        seoFragments: {
            titleText: 'Budget marketing',
            metaDesc: 'Planifiez et suivez votre budget marketing et communication',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'rentabilite-projet',
        Component: RentabiliteProjet,
        title: 'Rentabilité projet',
        description: "Analysez la rentabilité d'un nouveau projet (VAN, TRI, ROI)",
        url: '/outils-simulation/rentabilite-projet',
        category: 'finance',
        seoFragments: {
            titleText: 'Rentabilité projet',
            metaDesc: "Analysez la rentabilité d'un nouveau projet (VAN, TRI, ROI)",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: true,
        id: 'optimisation-stock',
        Component: OptimisationStocks,
        title: 'Optimisation des stocks',
        description: 'Optimisez vos niveaux de stock et réduisez vos coûts',
        url: '/outils-simulation/optimisation-stock',
        category: 'rentabilite',
        seoFragments: {
            titleText: 'Optimisation des stocks',
            metaDesc: 'Optimisez vos niveaux de stock et réduisez vos coûts',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'analyse-concurrentielle',
        Component: AnalyseConcurrentielle,
        title: 'Analyse concurrentielle',
        description: 'Comparez vos ratios avec ceux du marché',
        url: '/outils-simulation/analyse-concurrentielle',
        category: 'finance',
        seoFragments: {
            titleText: 'Analyse concurrentielle',
            metaDesc: 'Comparez vos ratios avec ceux du marché',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'cout-recrutement',
        Component: CoutRecrutement,
        title: 'Coût de recrutement',
        description: "Calculez le coût complet d'un processus de recrutement",
        url: '/outils-simulation/cout-recrutement',
        category: 'rh',
        seoFragments: {
            titleText: 'Coût de recrutement',
            metaDesc: "Calculez le coût complet d'un processus de recrutement",
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'plan-financement',
        Component: PlanFinancement,
        title: 'Plan de financement',
        description: 'Construisez votre plan de financement sur 3-5 ans',
        url: '/outils-simulation/plan-financement',
        category: 'finance',
        seoFragments: {
            titleText: 'Plan de financement',
            metaDesc: 'Construisez votre plan de financement sur 3-5 ans',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'diagnostic-financier',
        Component: DiagnosticFinancier,
        title: 'Diagnostic financier',
        description: 'Analysez la santé financière de votre entreprise',
        url: '/outils-simulation/diagnostic-financier',
        category: 'finance',
        seoFragments: {
            titleText: 'Diagnostic financier',
            metaDesc: 'Analysez la santé financière de votre entreprise',
            metaRobotsNoindex: '1',
        },
    },
    {
        isActive: false,
        id: 'impact-ecologique',
        Component: ImpactEcologique,
        title: 'Impact écologique',
        description: 'Mesurez et optimisez votre impact environnemental',
        url: '/outils-simulation/impact-ecologique',
        category: 'rse',
        seoFragments: {
            titleText: 'Impact écologique',
            metaDesc: 'Mesurez et optimisez votre impact environnemental',
            metaRobotsNoindex: '1',
        },
    },
];

// Catégories pour l'affichage
export const categories: Categories = {
    creation: {
        titre: "Création d'entreprise",
        description:
            'Tous les outils nécessaires pour bien démarrer votre entreprise : choix du statut juridique, calcul du capital social, comparaison des différentes options...',
    },
    fiscalite: {
        titre: 'Fiscalité',
        description:
            'Calculez et optimisez votre fiscalité : TVA, impôt sur les sociétés, impôt sur le revenu, CFE, CVAE et autres taxes...',
    },
    social: {
        titre: 'Charges sociales',
        description:
            "Gérez vos obligations sociales : charges sociales, taxe d'apprentissage, formation professionnelle...",
    },
    remuneration: {
        titre: 'Rémunération',
        description:
            'Optimisez votre rémunération et celle de vos salariés : salaire brut/net, dividendes, avantages en nature...',
    },
    rh: {
        titre: 'Gestion du personnel',
        description:
            "Pilotez vos ressources humaines : coût d'embauche, charges de personnel, indemnités, participation...",
    },
    finance: {
        titre: 'Gestion financière',
        description: 'Analysez et pilotez vos finances : prévisionnel, trésorerie, BFR, ratios financiers...',
    },
    investissement: {
        titre: 'Investissement et financement',
        description: "Évaluez vos projets d'investissement : emprunt, amortissement, effet de levier...",
    },
    rentabilite: {
        titre: 'Rentabilité et gestion commerciale',
        description: 'Optimisez votre rentabilité : seuil de rentabilité, prix de vente, marges, stocks...',
    },
    tresorerie: {
        titre: 'Trésorerie et financement court terme',
        description: 'Gérez votre trésorerie : délais de paiement, affacturage, découvert...',
    },
    transmission: {
        titre: 'Transmission et évaluation',
        description: 'Préparez la transmission de votre entreprise : plus-value, évaluation, garanties...',
    },
    protection: {
        titre: 'Protection sociale',
        description: 'Protégez-vous et votre famille : retraite, prévoyance, contrats Madelin...',
    },
    rse: {
        titre: 'Responsabilité sociétale',
        description: 'Mesurez et optimisez votre impact environnemental et social',
    },
};
