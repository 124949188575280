import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface PlafondsData {
    remunerationAnnuelle: string;
    statut: 'cadre' | 'non-cadre';
    regimeRetraite: 'general' | 'independant';
    dateEffet: string;
}

interface PlafondsResults {
    plafonds: {
        label: string;
        base: number;
        plafond: number;
        tauxSalarial: number;
        tauxPatronal: number;
        cotisationSalariale: number;
        cotisationPatronale: number;
    }[];
    totalCotisations: {
        salariales: number;
        patronales: number;
        total: number;
    };
    recommandations: string[];
}

const PlafondsSociaux: React.FC = () => {
    const [formData, setFormData] = useState<PlafondsData>({
        remunerationAnnuelle: '',
        statut: 'non-cadre',
        regimeRetraite: 'general',
        dateEffet: new Date().toISOString().split('T')[0],
    });

    const [results, setResults] = useState<PlafondsResults | null>(null);

    const calculatePlafonds = () => {
        const remuneration = parseFloat(formData.remunerationAnnuelle) || 0;
        const PMSS = 43992; // Plafond mensuel 2024 * 12

        const plafonds = [];
        let totalSalarial = 0;
        let totalPatronal = 0;

        // Sécurité sociale
        plafonds.push({
            label: 'Plafond sécurité sociale',
            base: remuneration,
            plafond: PMSS,
            tauxSalarial: 0.069,
            tauxPatronal: 0.28,
            cotisationSalariale: Math.min(remuneration, PMSS) * 0.069,
            cotisationPatronale: Math.min(remuneration, PMSS) * 0.28,
        });

        // Retraite complémentaire tranche 1
        const T1 = Math.min(remuneration, PMSS);
        plafonds.push({
            label: "Retraite T1 (jusqu'à 1 PASS)",
            base: T1,
            plafond: PMSS,
            tauxSalarial: formData.statut === 'cadre' ? 0.0405 : 0.0378,
            tauxPatronal: formData.statut === 'cadre' ? 0.0607 : 0.0567,
            cotisationSalariale: T1 * (formData.statut === 'cadre' ? 0.0405 : 0.0378),
            cotisationPatronale: T1 * (formData.statut === 'cadre' ? 0.0607 : 0.0567),
        });

        // Retraite complémentaire tranche 2 (pour les cadres)
        if (formData.statut === 'cadre' && remuneration > PMSS) {
            const T2 = Math.min(remuneration - PMSS, PMSS * 7);
            plafonds.push({
                label: 'Retraite T2 (1 à 8 PASS)',
                base: T2,
                plafond: PMSS * 7,
                tauxSalarial: 0.0956,
                tauxPatronal: 0.1434,
                cotisationSalariale: T2 * 0.0956,
                cotisationPatronale: T2 * 0.1434,
            });
        }

        // Prévoyance cadre obligatoire
        if (formData.statut === 'cadre') {
            const prevoyance = Math.min(remuneration, PMSS);
            plafonds.push({
                label: 'Prévoyance cadre',
                base: prevoyance,
                plafond: PMSS,
                tauxSalarial: 0,
                tauxPatronal: 0.015,
                cotisationSalariale: 0,
                cotisationPatronale: prevoyance * 0.015,
            });
        }

        // Calcul des totaux
        totalSalarial = plafonds.reduce((sum, p) => sum + p.cotisationSalariale, 0);
        totalPatronal = plafonds.reduce((sum, p) => sum + p.cotisationPatronale, 0);

        // Recommandations
        const recommandations = [];

        if (remuneration > PMSS * 8) {
            recommandations.push('Dépassement du plafond maximum de cotisations (8 PASS)');
        }

        if (formData.statut === 'cadre' && remuneration > PMSS && remuneration < PMSS * 2) {
            recommandations.push('Optimisez vos cotisations retraite T2 pour améliorer vos droits futurs');
        }

        if (formData.statut === 'cadre' && remuneration > PMSS * 4) {
            recommandations.push('Envisagez un régime de retraite supplémentaire pour compenser le plafonnement');
        }

        setResults({
            plafonds,
            totalCotisations: {
                salariales: totalSalarial,
                patronales: totalPatronal,
                total: totalSalarial + totalPatronal,
            },
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de plafonds sociaux
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.statut}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, statut: e.target.value as 'cadre' | 'non-cadre' }))
                        }
                    >
                        <FormControlLabel
                            value="cadre"
                            control={<Radio />}
                            label="Cadre"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="non-cadre"
                            control={<Radio />}
                            label="Non cadre"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.regimeRetraite}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                regimeRetraite: e.target.value as 'general' | 'independant',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="general"
                            control={<Radio />}
                            label="Régime général"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="independant"
                            control={<Radio />}
                            label="Régime indépendant"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Rémunération annuelle brute (€)"
                        type="number"
                        value={formData.remunerationAnnuelle}
                        onChange={e => setFormData(prev => ({ ...prev, remunerationAnnuelle: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Date d'effet"
                        type="date"
                        value={formData.dateEffet}
                        onChange={e => setFormData(prev => ({ ...prev, dateEffet: e.target.value }))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePlafonds}
                    disabled={!formData.remunerationAnnuelle}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer les plafonds
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.plafonds.map((plafond, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">{plafond.label}</span>
                                <span className="value"></span>
                            </div>
                            <div className="result-row">
                                <span className="label">Base de calcul</span>
                                <span className="value">{Math.round(plafond.base).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row">
                                <span className="label">
                                    Cotisation salariale ({(plafond.tauxSalarial * 100).toFixed(2)}%)
                                </span>
                                <span className="value">
                                    {Math.round(plafond.cotisationSalariale).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row">
                                <span className="label">
                                    Cotisation patronale ({(plafond.tauxPatronal * 100).toFixed(2)}%)
                                </span>
                                <span className="value">
                                    {Math.round(plafond.cotisationPatronale).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Total des cotisations salariales</span>
                        <span className="value">
                            {Math.round(results.totalCotisations.salariales).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Total des cotisations patronales</span>
                        <span className="value">
                            {Math.round(results.totalCotisations.patronales).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Coût total</span>
                        <span className="value">
                            {Math.round(results.totalCotisations.total).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default PlafondsSociaux;
