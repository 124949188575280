import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface CvaeData {
    chiffreAffaires: string;
    valeurAjoutee: string;
    effectif: string;
    territoires: string;
    exoneration: boolean;
}

interface CvaeResults {
    tauxEffectif: number;
    montantCvae: number;
    degrevement: number;
    montantFinal: number;
    acomptes: {
        juin: number;
        septembre: number;
        solde: number;
    };
}

const CotisationValeurAjoutee: React.FC = () => {
    const [formData, setFormData] = useState<CvaeData>({
        chiffreAffaires: '',
        valeurAjoutee: '',
        effectif: '',
        territoires: '1',
        exoneration: false,
    });

    const [results, setResults] = useState<CvaeResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateCVAE = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const va = parseFloat(formData.valeurAjoutee) || 0;
        const effectif = parseInt(formData.effectif) || 0;
        const territoires = parseInt(formData.territoires) || 1;

        // Calcul du taux effectif
        let tauxEffectif = 0;
        if (ca > 50000000) {
            tauxEffectif = 0.0075; // 0.75%
        } else if (ca > 500000) {
            // Formule progressive entre 500K€ et 50M€
            tauxEffectif = (0.0075 * (ca - 500000)) / 49500000;
        }

        // Calcul de la CVAE brute
        const montantCvae = va * tauxEffectif;

        // Calcul du dégrèvement
        let degrevement = 0;
        if (ca < 50000000) {
            degrevement = montantCvae * 0.5; // Dégrèvement de 50% pour les entreprises < 50M€
        }

        // Calcul du montant final
        const montantFinal = formData.exoneration ? 0 : montantCvae - degrevement;

        // Calcul des acomptes
        const acompteJuin = montantFinal * 0.5;
        const acompteSeptembre = montantFinal * 0.5;
        const solde = montantFinal - (acompteJuin + acompteSeptembre);

        setResults({
            tauxEffectif,
            montantCvae,
            degrevement,
            montantFinal,
            acomptes: {
                juin: acompteJuin,
                septembre: acompteSeptembre,
                solde,
            },
        });
    };

    const handleSubmit = () => {
        calculateCVAE();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de CVAE
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.exoneration}
                        onChange={e => setFormData(prev => ({ ...prev, exoneration: e.target.value === 'true' }))}
                    >
                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Entreprise exonérée"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Entreprise imposable"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Valeur ajoutée (€)"
                        type="number"
                        value={formData.valeurAjoutee}
                        onChange={e => setFormData(prev => ({ ...prev, valeurAjoutee: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Effectif"
                        type="number"
                        value={formData.effectif}
                        onChange={e => setFormData(prev => ({ ...prev, effectif: e.target.value }))}
                        placeholder="Ex: 10"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre de territoires d'exploitation"
                        type="number"
                        value={formData.territoires}
                        onChange={e => setFormData(prev => ({ ...prev, territoires: e.target.value }))}
                        placeholder="Ex: 1"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.chiffreAffaires || !formData.valeurAjoutee}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer la CVAE
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {formData.exoneration ? (
                        <div className="result-row highlight">
                            <span className="label">Statut</span>
                            <span className="value" style={{ color: '#10B981' }}>
                                Entreprise exonérée de CVAE
                            </span>
                        </div>
                    ) : (
                        <>
                            <div className="result-row">
                                <span className="label">Taux effectif d'imposition</span>
                                <span className="value">{(results.tauxEffectif * 100).toFixed(3)}%</span>
                            </div>
                            <div className="result-row">
                                <span className="label">CVAE brute</span>
                                <span className="value">
                                    {Math.round(results.montantCvae).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            {results.degrevement > 0 && (
                                <div className="result-row">
                                    <span className="label">Dégrèvement</span>
                                    <span className="value">
                                        -{Math.round(results.degrevement).toLocaleString('fr-FR')} €
                                    </span>
                                </div>
                            )}
                            <div className="result-row highlight">
                                <span className="label">CVAE à payer</span>
                                <span className="value">
                                    {Math.round(results.montantFinal).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row">
                                <span className="label">Acompte de juin</span>
                                <span className="value">
                                    {Math.round(results.acomptes.juin).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row">
                                <span className="label">Acompte de septembre</span>
                                <span className="value">
                                    {Math.round(results.acomptes.septembre).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row">
                                <span className="label">Solde à payer</span>
                                <span className="value">
                                    {Math.round(results.acomptes.solde).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                        </>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default CotisationValeurAjoutee;
