import {
    Button,
    FormControl,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

interface EmpruntData {
    montant: string;
    duree: string;
    tauxInteret: string;
    tauxAssurance: string;
    dateDebut: string;
    fraisDossier: string;
    fraisGarantie: string;
}

interface LigneAmortissement {
    annee: number;
    annuite: number;
    capitalRembourse: number;
    interetsRembourses: number;
    assurance: number;
    capitalRestantDu: number;
}

const SimulatorContainer = styled('div')({
    maxWidth: 1200,
    margin: '0 auto',
    padding: 20,
    '@media (max-width: 600px)': {
        padding: 10,
    },
});

const StyledTableCell = styled(TableCell)({
    padding: '8px 16px !important',
    '@media (max-width: 600px)': {
        padding: '6px 8px !important',
        fontSize: '0.8rem !important',
    },
});

const SimulateurEmpruntMeilleurTaux: React.FC = () => {
    const [formData, setFormData] = useState<EmpruntData>({
        montant: '',
        duree: '',
        tauxInteret: '',
        tauxAssurance: '',
        dateDebut: '',
        fraisDossier: '',
        fraisGarantie: '',
    });

    const [tableauAmortissement, setTableauAmortissement] = useState<LigneAmortissement[]>([]);
    const [totalGeneraux, setTotalGeneraux] = useState<{
        totalAnnuites: number;
        totalCapital: number;
        totalInterets: number;
        totalAssurance: number;
    } | null>(null);

    const calculerTableauAmortissement = () => {
        const montant = parseFloat(formData.montant) || 0;
        const dureeAnnees = parseInt(formData.duree) || 0;
        const tauxAnnuel = parseFloat(formData.tauxInteret) || 0;
        const tauxAssurance = parseFloat(formData.tauxAssurance) || 0;
        const fraisDossier = parseFloat(formData.fraisDossier) || 0;
        const fraisGarantie = parseFloat(formData.fraisGarantie) || 0;
        const dateDebut = new Date(formData.dateDebut);
        const moisDebut = dateDebut.getMonth(); // 0-11

        const fraisInitiaux = fraisDossier + fraisGarantie;
        const tauxMensuel = tauxAnnuel / 100 / 12;
        const nombreMois = dureeAnnees * 12;

        const mensualiteHorsAssurance =
            (montant * (tauxMensuel * Math.pow(1 + tauxMensuel, nombreMois))) /
            (Math.pow(1 + tauxMensuel, nombreMois) - 1);
        const mensualiteAssurance = (montant * tauxAssurance) / 100 / 12;

        let capitalRestant = montant;
        const tableau: LigneAmortissement[] = [];
        let totalAnnuites = 0;
        let totalCapital = 0;
        let totalInterets = 0;
        let totalAssurance = 0;

        // Calcul du nombre de mois pour la première année
        const moisPremiereAnnee = 12 - moisDebut;
        // Calcul du nombre de mois pour la dernière année
        const moisDerniereAnnee = (nombreMois - moisPremiereAnnee) % 12 || 12;

        for (let annee = 1; annee <= Math.ceil(nombreMois / 12); annee++) {
            let capitalAnnuel = 0;
            let interetsAnnuels = 0;
            let annuiteAnnuelle = 0;
            let moisDansAnnee;

            // Déterminer le nombre de mois pour cette année
            if (annee === 1) {
                moisDansAnnee = moisPremiereAnnee;
            } else if (annee === Math.ceil(nombreMois / 12)) {
                moisDansAnnee = moisDerniereAnnee;
            } else {
                moisDansAnnee = 12;
            }

            const assuranceAnnuelle = mensualiteAssurance * moisDansAnnee;

            // Calculs mensuels pour l'année
            for (let mois = 1; mois <= moisDansAnnee; mois++) {
                const interetsMois = capitalRestant * tauxMensuel;
                const capitalMois = mensualiteHorsAssurance - interetsMois;

                capitalAnnuel += capitalMois;
                interetsAnnuels += interetsMois;
                annuiteAnnuelle += mensualiteHorsAssurance;
                capitalRestant -= capitalMois;
            }

            const annuiteTotale = annuiteAnnuelle + assuranceAnnuelle;

            tableau.push({
                annee,
                annuite: Math.round(annuiteTotale),
                capitalRembourse: Math.round(capitalAnnuel),
                interetsRembourses: Math.round(interetsAnnuels),
                assurance: Math.round(assuranceAnnuelle),
                capitalRestantDu: Math.round(capitalRestant),
            });

            totalAnnuites += annuiteTotale;
            totalCapital += capitalAnnuel;
            totalInterets += interetsAnnuels;
            totalAssurance += assuranceAnnuelle;
        }

        setTableauAmortissement(tableau);
        setTotalGeneraux({
            totalAnnuites: Math.round(totalAnnuites),
            totalCapital: Math.round(totalCapital),
            totalInterets: Math.round(totalInterets + fraisInitiaux),
            totalAssurance: Math.round(totalAssurance),
        });
    };

    return (
        <SimulatorContainer>
            <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur de tableau d'amortissement
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                label="Montant emprunté (€)"
                                type="number"
                                value={formData.montant}
                                onChange={e => setFormData(prev => ({ ...prev, montant: e.target.value }))}
                                placeholder="Ex: 150000"
                                inputProps={{ min: '0', step: '1000' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                label="Durée (années)"
                                type="number"
                                value={formData.duree}
                                onChange={e => setFormData(prev => ({ ...prev, duree: e.target.value }))}
                                placeholder="Ex: 20"
                                inputProps={{ min: '1', max: '30', step: '1' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                label="Taux d'intérêt (%)"
                                type="number"
                                value={formData.tauxInteret}
                                onChange={e => setFormData(prev => ({ ...prev, tauxInteret: e.target.value }))}
                                placeholder="Ex: 3.45"
                                inputProps={{ min: '0', step: '0.01' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                label="Taux d'assurance (%)"
                                type="number"
                                value={formData.tauxAssurance}
                                onChange={e => setFormData(prev => ({ ...prev, tauxAssurance: e.target.value }))}
                                placeholder="Ex: 0.36"
                                inputProps={{ min: '0', step: '0.01' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                label="Frais de dossier (€)"
                                type="number"
                                value={formData.fraisDossier}
                                onChange={e => setFormData(prev => ({ ...prev, fraisDossier: e.target.value }))}
                                placeholder="Ex: 1000"
                                inputProps={{ min: '0', step: '100' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                label="Frais de garantie (€)"
                                type="number"
                                value={formData.fraisGarantie}
                                onChange={e => setFormData(prev => ({ ...prev, fraisGarantie: e.target.value }))}
                                placeholder="Ex: 2000"
                                inputProps={{ min: '0', step: '100' }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Début des remboursements"
                            type="date"
                            value={formData.dateDebut}
                            onChange={e => setFormData(prev => ({ ...prev, dateDebut: e.target.value }))}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculerTableauAmortissement}
                    disabled={!formData.montant || !formData.duree || !formData.tauxInteret || !formData.dateDebut}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer le tableau d'amortissement
                </Button>
            </Paper>

            {tableauAmortissement.length > 0 && (
                <Paper elevation={0} sx={{ p: 3 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Année</StyledTableCell>
                                    <StyledTableCell align="right">Annuités</StyledTableCell>
                                    <StyledTableCell align="right">Capital remboursé</StyledTableCell>
                                    <StyledTableCell align="right">Intérêts remboursés et frais</StyledTableCell>
                                    <StyledTableCell align="right">Assurance</StyledTableCell>
                                    <StyledTableCell align="right">Capital restant dû</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableauAmortissement.map(ligne => (
                                    <TableRow key={ligne.annee}>
                                        <StyledTableCell>{ligne.annee}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                {ligne.annuite.toLocaleString('fr-FR')} €
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                {ligne.capitalRembourse.toLocaleString('fr-FR')} €
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                {ligne.interetsRembourses.toLocaleString('fr-FR')} €
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                {ligne.assurance.toLocaleString('fr-FR')} €
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                {ligne.capitalRestantDu.toLocaleString('fr-FR')} €
                                            </span>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                                {totalGeneraux && (
                                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                        <StyledTableCell>
                                            <strong>Total</strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <strong>
                                                {totalGeneraux.totalAnnuites.toLocaleString('fr-FR', {
                                                    maximumFractionDigits: 2,
                                                })}{' '}
                                                €
                                            </strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <strong>
                                                {totalGeneraux.totalCapital.toLocaleString('fr-FR', {
                                                    maximumFractionDigits: 2,
                                                })}{' '}
                                                €
                                            </strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <strong>
                                                {totalGeneraux.totalInterets.toLocaleString('fr-FR', {
                                                    maximumFractionDigits: 2,
                                                })}{' '}
                                                €
                                            </strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <strong>
                                                {totalGeneraux.totalAssurance.toLocaleString('fr-FR', {
                                                    maximumFractionDigits: 2,
                                                })}{' '}
                                                €
                                            </strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">-</StyledTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}
        </SimulatorContainer>
    );
};

export default SimulateurEmpruntMeilleurTaux;
