import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, Checkbox, FormControlLabel } from '@mui/material';

interface RuptureData {
    salaireBrut: string;
    anciennete: string;
    primes: string;
    congesRestants: string;
    preavisEffectue: boolean;
    conventionCollective: string;
    cadre: boolean;
}

interface RuptureResults {
    indemnites: {
        label: string;
        montant: number;
        description: string;
    }[];
    totalBrut: number;
    totalNet: number;
    coutEmployeur: number;
}

const RuptureConventionnelle: React.FC = () => {
    const [formData, setFormData] = useState<RuptureData>({
        salaireBrut: '',
        anciennete: '',
        primes: '0',
        congesRestants: '0',
        preavisEffectue: true,
        conventionCollective: '',
        cadre: false,
    });

    const [results, setResults] = useState<RuptureResults | null>(null);

    const calculateRupture = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;
        const anciennete = parseFloat(formData.anciennete) || 0;
        const primes = parseFloat(formData.primes) || 0;
        const congesRestants = parseFloat(formData.congesRestants) || 0;

        // Base de calcul mensuelle
        const baseMensuelle = salaireBrut + primes / 12;

        // Indemnité légale de licenciement
        let indemniteLegale = 0;
        if (anciennete < 10) {
            indemniteLegale = baseMensuelle * 0.25 * anciennete;
        } else {
            indemniteLegale = baseMensuelle * 0.25 * 10 + baseMensuelle * 0.33 * (anciennete - 10);
        }

        // Indemnité de préavis
        const indemnitePreavis = formData.preavisEffectue ? 0 : baseMensuelle * (formData.cadre ? 3 : 2);

        // Indemnité compensatrice de congés payés
        const indemniteConges = (baseMensuelle / 22) * congesRestants;

        // CSG/CRDS sur indemnités
        const csgCrds = (indemniteLegale + indemnitePreavis + indemniteConges) * 0.097;

        // Charges patronales
        const chargesPatronales = (indemnitePreavis + indemniteConges) * 0.45;

        const indemnites = [
            {
                label: 'Indemnité légale de rupture',
                montant: indemniteLegale,
                description: `${anciennete < 10 ? '1/4' : '1/4 puis 1/3'} de mois par année d'ancienneté`,
            },
            {
                label: 'Indemnité de préavis',
                montant: indemnitePreavis,
                description: formData.preavisEffectue
                    ? 'Préavis effectué'
                    : `${formData.cadre ? '3' : '2'} mois de salaire`,
            },
            {
                label: 'Indemnité compensatrice de congés payés',
                montant: indemniteConges,
                description: `${congesRestants} jours de congés restants`,
            },
            {
                label: 'CSG/CRDS',
                montant: -csgCrds,
                description: '9,7% sur les indemnités imposables',
            },
        ];

        const totalBrut = indemniteLegale + indemnitePreavis + indemniteConges;
        const totalNet = totalBrut - csgCrds;
        const coutEmployeur = totalBrut + chargesPatronales;

        setResults({
            indemnites,
            totalBrut,
            totalNet,
            coutEmployeur,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de rupture conventionnelle
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Salaire brut mensuel (€)"
                        type="number"
                        value={formData.salaireBrut}
                        onChange={e => setFormData(prev => ({ ...prev, salaireBrut: e.target.value }))}
                        placeholder="Ex: 2500"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Ancienneté (années)"
                        type="number"
                        value={formData.anciennete}
                        onChange={e => setFormData(prev => ({ ...prev, anciennete: e.target.value }))}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Primes annuelles (€)"
                        type="number"
                        value={formData.primes}
                        onChange={e => setFormData(prev => ({ ...prev, primes: e.target.value }))}
                        placeholder="Ex: 3000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Jours de congés restants"
                        type="number"
                        value={formData.congesRestants}
                        onChange={e => setFormData(prev => ({ ...prev, congesRestants: e.target.value }))}
                        placeholder="Ex: 10"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Convention collective"
                        value={formData.conventionCollective}
                        onChange={e => setFormData(prev => ({ ...prev, conventionCollective: e.target.value }))}
                        placeholder="Ex: Métallurgie"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.cadre}
                                onChange={e => setFormData(prev => ({ ...prev, cadre: e.target.checked }))}
                            />
                        }
                        label="Statut cadre"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.preavisEffectue}
                                onChange={e => setFormData(prev => ({ ...prev, preavisEffectue: e.target.checked }))}
                            />
                        }
                        label="Préavis effectué"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateRupture}
                    disabled={!formData.salaireBrut || !formData.anciennete}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer les indemnités
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.indemnites.map((indemnite, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{indemnite.label}</span>
                                <span className="value" style={{ color: indemnite.montant < 0 ? 'red' : undefined }}>
                                    {Math.round(indemnite.montant).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {indemnite.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row">
                        <span className="label">Total brut</span>
                        <span className="value">{Math.round(results.totalBrut).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Net à verser</span>
                        <span className="value">{Math.round(results.totalNet).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Coût total employeur</span>
                        <span className="value">{Math.round(results.coutEmployeur).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default RuptureConventionnelle;
