import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';
import Faq from 'react-faq-component';
import { Helmet } from 'react-helmet';

import TopPaddingBasedOnHeader from '../components/layout/header/utils/TopPaddingBasedOnHeader';
import Layout from '../components/layout/Layout';
import PostThumb from '../components/media/PostThumb';
import MoreTags from '../components/MoreTags';
import Breadcrumb from '../components/navigation/Breadcrumb';
import { SliceZoneTag } from '../components/prismic/SliceZoneTag';
import { ProductsHighlight } from '../components/ProductsHighlight';
import { SeoTitle } from '../components/seo/seoTitle';
import useParseContent from '../hooks/useParseContent';
import { mediaBreadcrumbParser } from '../utils/BreadcrumbParser';
import { isBrowser } from '../utils/utils';

const Decorator = (props: { children: React.ReactNode }) => {
    isBrowser &&
        document &&
        document
            .querySelectorAll('[role="listitem"]')
            .forEach(
                (element: HTMLElement, index) =>
                    (element.dataset.ga4 = `container-level4-FAQ-text-title${index + 1}-click-link`),
            );
    return <React.Fragment>{props.children}</React.Fragment>;
};

const StyledSeoTitle = styled(SeoTitle)`
    text-align: center;
    margin-bottom: 48px;
    padding-top: 24px;
`;

const TagPage = ({
    data,
    pageContext,
}: {
    data: { prismicTag: Queries.PrismicTag; allPrismicPost: { nodes: Queries.PrismicPost[] } };
    pageContext;
}) => {
    const faqData = {
        rows: data.prismicTag.data.faq
            .filter(data => data.question && data.answer)
            .map(question => {
                return {
                    title: question.question,
                    content: question.answer.html,
                };
            }),
    };
    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: faqData.rows.map(data => {
            return {
                '@type': 'Question',
                name: data.title,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: data.content,
                },
            };
        }),
    };
    const tag = data.prismicTag.data;
    const breadcrumb = mediaBreadcrumbParser(tag);

    const posts = data.allPrismicPost.nodes;
    const description = useParseContent(tag.description.html || '');
    const title = tag.name;
    return (
        <Layout
            title={`${tag.name} - Dougs Compta`}
            seo={{ metaDesc: tag.meta_description || tag.description?.text, metaTitle: tag.meta_title || title }}
            hasHeaderMedia
            forceHeaderWhiteBackground
        >
            {faqData.rows.length > 0 && (
                <Helmet>
                    <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
                </Helmet>
            )}
            <div className="hero">
                <TopPaddingBasedOnHeader padding={{ desktop: 0, tablet: 0, mobile: 0 }} />
                <div className="container">
                    {breadcrumb && <Breadcrumb items={breadcrumb} />}
                    <h1 className="mt-5">{title}</h1>
                    <div className="mb-3 tag-description">{description}</div>
                    <div className="h5 mb-3">
                        {pageContext.pagination.total} {pageContext.pagination.total > 1 ? 'articles' : 'article'} à
                        découvrir
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={'row thumb-list mb-4'}>
                    {posts.map((post, index) => (
                        <div className="col-md-4 col-sm-6" key={index}>
                            <PostThumb
                                post={{
                                    ...post,
                                    img: post.data.cover.gatsbyImageData,
                                }}
                            />
                        </div>
                    ))}
                </div>

                {data.prismicTag.data.supertag && <SliceZoneTag sliceZone={tag.body} />}
                {!data.prismicTag.data.supertag && <ProductsHighlight />}
                {data.prismicTag.data.linked_tag.filter(tag => !!tag.label?.document).length > 0 && (
                    <MoreTags
                        level={3}
                        tags={data.prismicTag.data.linked_tag.map(tag => tag.label?.document) as Queries.PrismicTag[]}
                    />
                )}
                {faqData.rows.length > 0 && <StyledSeoTitle seoStyle={'h2'}>Foire aux questions</StyledSeoTitle>}
                {faqData.rows.length > 0 && (
                    <div className="mb-5">
                        <Decorator>
                            <Faq data={faqData} />
                        </Decorator>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default withPrismicPreview(TagPage);

export const pageQuery = graphql`
    query ($id: String!, $postIds: [String]) {
        prismicTag(id: { eq: $id }) {
            ...PostTag
        }
        allPrismicPost(
            filter: { data: { indexed: { eq: true } }, id: { in: $postIds } }
            sort: { data: { published_date: DESC } }
        ) {
            nodes {
                id
                uid
                first_publication_date
                uri
                _previewable
                data {
                    meta_description
                    published_date
                    titre
                    cover {
                        alt
                        gatsbyImageData
                    }
                    category {
                        document {
                            ... on PrismicCategory {
                                id
                                uri
                                data {
                                    title
                                    slug
                                    color
                                }
                            }
                        }
                    }
                    labels {
                        tag {
                            document {
                                ... on PrismicTag {
                                    id
                                    uri
                                    data {
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                    }
                    body {
                        ...PostQuote
                        ...PostImage
                        ...PostOverline
                        ...PostNormalText
                        ...PostLineHorizontal
                        ...PostButton
                        ...PostCard
                        ...PostGuide
                        ...PostTVA
                    }
                    author {
                        document {
                            ...PostAuthor
                        }
                    }
                }
            }
        }
    }
`;
