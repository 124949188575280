import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface FinancementData {
    investissements: {
        immobilisations: string;
        materiel: string;
        stockInitial: string;
        tresorerieInitiale: string;
    };
    ressources: {
        capitauxPropres: string;
        comptesCourants: string;
        subventions: string;
        emprunts: string;
    };
    duree: string;
    typeProjet: 'creation' | 'developpement' | 'reprise';
}

interface FinancementResults {
    besoins: {
        label: string;
        montant: number;
        description: string;
    }[];
    ressources: {
        label: string;
        montant: number;
        description: string;
    }[];
    equilibre: boolean;
    ecart: number;
    ratios: {
        label: string;
        valeur: number;
        interpretation: string;
    }[];
    recommandations: string[];
}

const PlanFinancement: React.FC = () => {
    const [formData, setFormData] = useState<FinancementData>({
        investissements: {
            immobilisations: '',
            materiel: '',
            stockInitial: '',
            tresorerieInitiale: '',
        },
        ressources: {
            capitauxPropres: '',
            comptesCourants: '',
            subventions: '',
            emprunts: '',
        },
        duree: '',
        typeProjet: 'creation',
    });

    const [results, setResults] = useState<FinancementResults | null>(null);

    const calculatePlan = () => {
        // Calcul des besoins
        const besoins = [
            {
                label: 'Immobilisations',
                montant: parseFloat(formData.investissements.immobilisations) || 0,
                description: 'Investissements immobiliers',
            },
            {
                label: 'Matériel et équipements',
                montant: parseFloat(formData.investissements.materiel) || 0,
                description: 'Investissements matériels',
            },
            {
                label: 'Stock initial',
                montant: parseFloat(formData.investissements.stockInitial) || 0,
                description: 'Constitution du stock de départ',
            },
            {
                label: 'Trésorerie initiale',
                montant: parseFloat(formData.investissements.tresorerieInitiale) || 0,
                description: 'Fonds de roulement initial',
            },
        ];

        // Calcul des ressources
        const ressources = [
            {
                label: 'Capitaux propres',
                montant: parseFloat(formData.ressources.capitauxPropres) || 0,
                description: 'Apport en capital',
            },
            {
                label: 'Comptes courants',
                montant: parseFloat(formData.ressources.comptesCourants) || 0,
                description: 'Apports en compte courant',
            },
            {
                label: 'Subventions',
                montant: parseFloat(formData.ressources.subventions) || 0,
                description: 'Aides et subventions',
            },
            {
                label: 'Emprunts',
                montant: parseFloat(formData.ressources.emprunts) || 0,
                description: 'Financements bancaires',
            },
        ];

        // Calcul des totaux
        const totalBesoins = besoins.reduce((sum, item) => sum + item.montant, 0);
        const totalRessources = ressources.reduce((sum, item) => sum + item.montant, 0);
        const ecart = totalRessources - totalBesoins;

        // Calcul des ratios
        const ratios = [
            {
                label: 'Autonomie financière',
                valeur: (parseFloat(formData.ressources.capitauxPropres) / totalBesoins) * 100,
                interpretation: 'Part des capitaux propres dans le financement',
            },
            {
                label: "Taux d'endettement",
                valeur:
                    (parseFloat(formData.ressources.emprunts) / parseFloat(formData.ressources.capitauxPropres)) * 100,
                interpretation: 'Ratio dettes/fonds propres',
            },
            {
                label: 'Couverture des immobilisations',
                valeur:
                    ((parseFloat(formData.ressources.capitauxPropres) + parseFloat(formData.ressources.emprunts)) /
                        (parseFloat(formData.investissements.immobilisations) +
                            parseFloat(formData.investissements.materiel))) *
                    100,
                interpretation: 'Financement des investissements long terme',
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (ecart < 0) {
            recommandations.push(
                'Le plan de financement est déficitaire, des ressources supplémentaires sont nécessaires',
            );
        }

        if (ratios[0].valeur < 30) {
            recommandations.push("L'autonomie financière est faible, renforcez les fonds propres");
        }

        if (ratios[1].valeur > 200) {
            recommandations.push("Le niveau d'endettement est élevé, diversifiez les sources de financement");
        }

        if (parseFloat(formData.investissements.tresorerieInitiale) < totalBesoins * 0.1) {
            recommandations.push('La trésorerie initiale semble insuffisante');
        }

        setResults({
            besoins,
            ressources,
            equilibre: ecart >= 0,
            ecart,
            ratios,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Plan de financement
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeProjet}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                typeProjet: e.target.value as 'creation' | 'developpement' | 'reprise',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="creation"
                            control={<Radio />}
                            label="Création"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="developpement"
                            control={<Radio />}
                            label="Développement"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="reprise"
                            control={<Radio />}
                            label="Reprise"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 4 }}>
                    Besoins de financement
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Immobilisations (€)"
                        type="number"
                        value={formData.investissements.immobilisations}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                investissements: { ...prev.investissements, immobilisations: e.target.value },
                            }))
                        }
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Matériel et équipements (€)"
                        type="number"
                        value={formData.investissements.materiel}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                investissements: { ...prev.investissements, materiel: e.target.value },
                            }))
                        }
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Stock initial (€)"
                        type="number"
                        value={formData.investissements.stockInitial}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                investissements: { ...prev.investissements, stockInitial: e.target.value },
                            }))
                        }
                        placeholder="Ex: 30000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Trésorerie initiale (€)"
                        type="number"
                        value={formData.investissements.tresorerieInitiale}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                investissements: { ...prev.investissements, tresorerieInitiale: e.target.value },
                            }))
                        }
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 4 }}>
                    Ressources de financement
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Capitaux propres (€)"
                        type="number"
                        value={formData.ressources.capitauxPropres}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                ressources: { ...prev.ressources, capitauxPropres: e.target.value },
                            }))
                        }
                        placeholder="Ex: 80000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Comptes courants d'associés (€)"
                        type="number"
                        value={formData.ressources.comptesCourants}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                ressources: { ...prev.ressources, comptesCourants: e.target.value },
                            }))
                        }
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Subventions (€)"
                        type="number"
                        value={formData.ressources.subventions}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                ressources: { ...prev.ressources, subventions: e.target.value },
                            }))
                        }
                        placeholder="Ex: 15000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Emprunts (€)"
                        type="number"
                        value={formData.ressources.emprunts}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                ressources: { ...prev.ressources, emprunts: e.target.value },
                            }))
                        }
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePlan}
                    disabled={!formData.investissements.immobilisations && !formData.investissements.materiel}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Analyser le plan
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Besoins de financement</span>
                        <span className="value"></span>
                    </div>

                    {results.besoins.map((besoin, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{besoin.label}</span>
                                <span className="value">{Math.round(besoin.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {besoin.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Ressources de financement</span>
                        <span className="value"></span>
                    </div>

                    {results.ressources.map((ressource, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{ressource.label}</span>
                                <span className="value">{Math.round(ressource.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {ressource.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div
                        className="result-row"
                        style={{
                            backgroundColor: results.equilibre ? '#E8F5E9' : '#FFEBEE',
                            marginTop: '24px',
                        }}
                    >
                        <span className="label">Équilibre du plan</span>
                        <span
                            className="value"
                            style={{
                                color: results.equilibre ? '#2E7D32' : '#C62828',
                            }}
                        >
                            {results.ecart >= 0 ? '+' : ''}
                            {Math.round(results.ecart).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Ratios d'analyse</span>
                        <span className="value"></span>
                    </div>

                    {results.ratios.map((ratio, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{ratio.label}</span>
                                <span className="value">{ratio.valeur.toFixed(1)}%</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {ratio.interpretation}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default PlanFinancement;
