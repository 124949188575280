import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface DecouvertData {
    montantDecouvert: string;
    dureeJours: string;
    tauxAnnuel: string;
    commissionMouvement: string;
    commissionPlus: string;
    fraisIncidents: string;
    typeCalcul: 'simple' | 'detaille';
}

interface DecouvertResults {
    interetsDebiteurs: number;
    commissionsDebit: number;
    commissionsPlus: number;
    fraisIncidents: number;
    coutTotal: number;
    tauxEffectif: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const FraisDecouvert: React.FC = () => {
    const [formData, setFormData] = useState<DecouvertData>({
        montantDecouvert: '',
        dureeJours: '',
        tauxAnnuel: '',
        commissionMouvement: '',
        commissionPlus: '',
        fraisIncidents: '',
        typeCalcul: 'simple',
    });

    const [results, setResults] = useState<DecouvertResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateDecouvert = () => {
        const montant = parseFloat(formData.montantDecouvert) || 0;
        const duree = parseFloat(formData.dureeJours) || 0;
        const tauxAnnuel = parseFloat(formData.tauxAnnuel) || 0;
        const commissionMvt = parseFloat(formData.commissionMouvement) || 0;
        const commissionPlus = parseFloat(formData.commissionPlus) || 0;
        const fraisIncidents = parseFloat(formData.fraisIncidents) || 0;

        // Calcul des intérêts débiteurs
        const interetsDebiteurs = (montant * tauxAnnuel * duree) / (100 * 365);

        // Calcul des commissions de mouvement
        const commissionsDebit = (montant * commissionMvt) / 100;

        // Calcul des commissions de plus fort découvert
        const commissionsPlus = (montant * commissionPlus) / 100;

        // Coût total
        const coutTotal = interetsDebiteurs + commissionsDebit + commissionsPlus + fraisIncidents;

        // Calcul du TEG (Taux Effectif Global)
        const tauxEffectif = (coutTotal / montant) * (365 / duree) * 100;

        // Détails des calculs
        const details = [
            {
                label: 'Intérêts débiteurs',
                montant: interetsDebiteurs,
                description: `${tauxAnnuel}% sur ${duree} jours`,
            },
            {
                label: 'Commission de mouvement',
                montant: commissionsDebit,
                description: `${commissionMvt}% du montant débiteur`,
            },
            {
                label: 'Commission de plus fort découvert',
                montant: commissionsPlus,
                description: `${commissionPlus}% du plus fort découvert`,
            },
            {
                label: "Frais d'incidents",
                montant: fraisIncidents,
                description: "Frais de rejets et commissions d'intervention",
            },
        ];

        // Recommandations
        const recommandations = [];

        if (tauxEffectif > 15) {
            recommandations.push("Le coût du découvert est très élevé, privilégiez d'autres solutions de financement");
        }

        if (duree > 30) {
            recommandations.push(
                'La durée du découvert est longue, négociez une facilité de caisse ou un crédit court terme',
            );
        }

        if (montant > 10000) {
            recommandations.push('Pour ce montant, un crédit de trésorerie serait plus adapté');
        }

        if (fraisIncidents > 100) {
            recommandations.push(
                "Les frais d'incidents sont importants, mettez en place une meilleure gestion de trésorerie",
            );
        }

        setResults({
            interetsDebiteurs,
            commissionsDebit,
            commissionsPlus,
            fraisIncidents,
            coutTotal,
            tauxEffectif,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateDecouvert();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de frais de découvert
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeCalcul}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, typeCalcul: e.target.value as 'simple' | 'detaille' }))
                        }
                    >
                        <FormControlLabel
                            value="simple"
                            control={<Radio />}
                            label="Calcul simplifié"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="detaille"
                            control={<Radio />}
                            label="Calcul détaillé"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Montant du découvert (€)"
                        type="number"
                        value={formData.montantDecouvert}
                        onChange={e => setFormData(prev => ({ ...prev, montantDecouvert: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Durée (jours)"
                        type="number"
                        value={formData.dureeJours}
                        onChange={e => setFormData(prev => ({ ...prev, dureeJours: e.target.value }))}
                        placeholder="Ex: 15"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux annuel (%)"
                        type="number"
                        value={formData.tauxAnnuel}
                        onChange={e => setFormData(prev => ({ ...prev, tauxAnnuel: e.target.value }))}
                        placeholder="Ex: 12.5"
                    />
                </FormControl>

                {formData.typeCalcul === 'detaille' && (
                    <>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                label="Commission de mouvement (%)"
                                type="number"
                                value={formData.commissionMouvement}
                                onChange={e => setFormData(prev => ({ ...prev, commissionMouvement: e.target.value }))}
                                placeholder="Ex: 0.1"
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                label="Commission de plus fort découvert (%)"
                                type="number"
                                value={formData.commissionPlus}
                                onChange={e => setFormData(prev => ({ ...prev, commissionPlus: e.target.value }))}
                                placeholder="Ex: 0.05"
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                fullWidth
                                label="Frais d'incidents (€)"
                                type="number"
                                value={formData.fraisIncidents}
                                onChange={e => setFormData(prev => ({ ...prev, fraisIncidents: e.target.value }))}
                                placeholder="Ex: 50"
                            />
                        </FormControl>
                    </>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.montantDecouvert || !formData.dureeJours || !formData.tauxAnnuel}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer les frais
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">{Math.round(detail.montant * 100) / 100} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Coût total du découvert</span>
                        <span className="value">{Math.round(results.coutTotal * 100) / 100} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Taux effectif global (TEG)</span>
                        <span className="value">{Math.round(results.tauxEffectif * 100) / 100}%</span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default FraisDecouvert;
