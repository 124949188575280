import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

type StatusType = 'conforme' | 'alerte' | 'depassement';

interface MicroData {
    chiffreAffaires: string;
    activite: 'commerce' | 'service' | 'liberal';
}

interface MicroResults {
    plafondApplicable: number;
    depassementAnnuel: number;
    statut: StatusType;
    recommendations: string[];
}

const PlafondsMicro: React.FC = () => {
    const [formData, setFormData] = useState<MicroData>({
        chiffreAffaires: '',
        activite: 'commerce',
    });

    const [results, setResults] = useState<MicroResults | null>(null);

    const calculatePlafonds = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;

        // Plafonds 2024
        const plafonds = {
            commerce: 188700,
            service: 77700,
            liberal: 77700,
        };

        const plafondApplicable = plafonds[formData.activite];
        const depassementAnnuel = Math.max(0, ca - plafondApplicable);

        // Détermination du statut
        let statut: StatusType = 'conforme';
        if (ca > plafondApplicable * 1.1) {
            statut = 'depassement';
        } else if (ca > plafondApplicable * 0.7) {
            statut = 'alerte';
        }

        // Recommandations personnalisées
        const recommendations = [];

        if (statut === 'depassement') {
            recommendations.push('Vous devez sortir du régime micro-entreprise.');
            recommendations.push('Contactez votre expert-comptable pour préparer le passage au régime réel.');
        } else if (statut === 'alerte') {
            recommendations.push('Vous approchez du plafond, surveillez votre CA.');
            recommendations.push('Anticipez un possible changement au régime réel');
        } else {
            recommendations.push('Vous respectez les plafonds du régime micro.');
        }

        recommendations.push(
            'Attention, si vous dépassez pendant 2 ans les seuils de CA, vous sortez du régime de la micro-entreprise.',
        );

        setResults({
            plafondApplicable,
            depassementAnnuel,
            statut,
            recommendations,
        });
    };

    const getStatusColor = (status: StatusType) => {
        const colors = {
            conforme: '#10B981',
            alerte: '#F59E0B',
            depassement: '#EF4444',
        };
        return colors[status];
    };

    const getStatusText = (status: StatusType) => {
        const texts = {
            conforme: 'Conforme',
            alerte: 'Vigilance',
            depassement: 'Dépassement',
        };
        return texts[status];
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Vérification des plafonds micro-entreprise
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.activite}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                activite: e.target.value as 'commerce' | 'service' | 'liberal',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="liberal"
                            control={<Radio />}
                            label="Libéral"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires annuel (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePlafonds}
                    disabled={!formData.chiffreAffaires}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Vérifier les plafonds
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Plafond applicable</span>
                        <span className="value">{results.plafondApplicable.toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">CA actuel</span>
                        <span className="value">{parseFloat(formData.chiffreAffaires).toLocaleString('fr-FR')} €</span>
                    </div>
                    {results.depassementAnnuel > 0 && (
                        <div className="result-row">
                            <span className="label">Dépassement</span>
                            <span className="value" style={{ color: 'red' }}>
                                +{Math.round(results.depassementAnnuel).toLocaleString('fr-FR')} €
                            </span>
                        </div>
                    )}
                    <div className="result-row highlight">
                        <span className="label">Statut</span>
                        <span
                            className="value"
                            style={{
                                color: getStatusColor(results.statut),
                            }}
                        >
                            {getStatusText(results.statut)}
                        </span>
                    </div>
                    <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                        <span className="label">Recommandations</span>
                        {results.recommendations.map(recommendation => (
                            <p key={recommendation} style={{ margin: 0, color: '#666' }}>
                                {recommendation}
                            </p>
                        ))}
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default PlafondsMicro;
