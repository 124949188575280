import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface AmortissementData {
    montantInitial: string;
    duree: string;
    modeCalcul: 'lineaire' | 'degressif';
    tauxDegressif: string;
    dateAchat: string;
}

interface AnnuiteAmortissement {
    annee: number;
    baseAmortissable: number;
    dotation: number;
    cumul: number;
    vnc: number;
}

const PlanAmortissement: React.FC = () => {
    const [formData, setFormData] = useState<AmortissementData>({
        montantInitial: '',
        duree: '',
        modeCalcul: 'lineaire',
        tauxDegressif: '1.75', // Coefficient pour le dégressif (1.75 pour 5-6 ans)
        dateAchat: new Date().toISOString().split('T')[0],
    });

    const [results, setResults] = useState<AnnuiteAmortissement[]>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateAmortissement = () => {
        const montant = parseFloat(formData.montantInitial) || 0;
        const duree = parseInt(formData.duree) || 0;
        const tauxDegressif = parseFloat(formData.tauxDegressif);
        const dateAchat = new Date(formData.dateAchat);
        const anneeAchat = dateAchat.getFullYear();

        const annuites: AnnuiteAmortissement[] = [];

        if (formData.modeCalcul === 'lineaire') {
            // Amortissement linéaire
            const dotationAnnuelle = montant / duree;
            let cumul = 0;

            for (let i = 1; i <= duree; i++) {
                cumul += dotationAnnuelle;
                annuites.push({
                    annee: anneeAchat + i - 1,
                    baseAmortissable: montant,
                    dotation: dotationAnnuelle,
                    cumul: cumul,
                    vnc: montant - cumul,
                });
            }
        } else {
            // Amortissement dégressif
            const tauxLineaire = 1 / duree;
            const tauxDegressifAnnuel = tauxLineaire * tauxDegressif;
            let baseAmortissable = montant;
            let cumul = 0;

            for (let i = 1; i <= duree; i++) {
                // On passe en linéaire quand c'est plus avantageux
                const dotationDegressif = baseAmortissable * tauxDegressifAnnuel;
                const dotationLineaire = (montant - cumul) / (duree - i + 1);

                const dotation = Math.max(dotationDegressif, dotationLineaire);
                cumul += dotation;

                annuites.push({
                    annee: anneeAchat + i - 1,
                    baseAmortissable: baseAmortissable,
                    dotation: dotation,
                    cumul: cumul,
                    vnc: montant - cumul,
                });

                baseAmortissable = montant - cumul;
            }
        }

        setResults(annuites);
    };

    const handleSubmit = () => {
        calculateAmortissement();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Plan d'amortissement
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.modeCalcul}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, modeCalcul: e.target.value as 'lineaire' | 'degressif' }))
                        }
                    >
                        <FormControlLabel
                            value="lineaire"
                            control={<Radio />}
                            label="Linéaire"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="degressif"
                            control={<Radio />}
                            label="Dégressif"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Montant de l'immobilisation (€)"
                        type="number"
                        value={formData.montantInitial}
                        onChange={e => setFormData(prev => ({ ...prev, montantInitial: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Durée d'amortissement (années)"
                        type="number"
                        value={formData.duree}
                        onChange={e => setFormData(prev => ({ ...prev, duree: e.target.value }))}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Date d'acquisition"
                        type="date"
                        value={formData.dateAchat}
                        onChange={e => setFormData(prev => ({ ...prev, dateAchat: e.target.value }))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>

                {formData.modeCalcul === 'degressif' && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Coefficient dégressif"
                            type="number"
                            value={formData.tauxDegressif}
                            onChange={e => setFormData(prev => ({ ...prev, tauxDegressif: e.target.value }))}
                            placeholder="Ex: 1.75"
                            helperText="1.25 pour 3-4 ans, 1.75 pour 5-6 ans, 2.25 pour plus de 6 ans"
                        />
                    </FormControl>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.montantInitial || !formData.duree}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer l'amortissement
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Valeur d'origine</span>
                        <span className="value">{parseFloat(formData.montantInitial).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Mode d'amortissement</span>
                        <span className="value">{formData.modeCalcul === 'lineaire' ? 'Linéaire' : 'Dégressif'}</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Durée</span>
                        <span className="value">{formData.duree} ans</span>
                    </div>

                    {results.map((annuite, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">Année {annuite.annee}</span>
                                <span className="value"></span>
                            </div>
                            <div className="result-row">
                                <span className="label">Base amortissable</span>
                                <span className="value">
                                    {Math.round(annuite.baseAmortissable).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row">
                                <span className="label">Dotation</span>
                                <span className="value">{Math.round(annuite.dotation).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row">
                                <span className="label">Cumul des amortissements</span>
                                <span className="value">{Math.round(annuite.cumul).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className={`result-row ${index === results.length - 1 ? 'highlight' : ''}`}>
                                <span className="label">Valeur nette comptable</span>
                                <span className="value">{Math.round(annuite.vnc).toLocaleString('fr-FR')} €</span>
                            </div>
                        </React.Fragment>
                    ))}
                </Paper>
            )}
        </div>
    );
};

export default PlanAmortissement;
