import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

const ChargesSociales: React.FC = () => {
    const [formData, setFormData] = useState({
        revenue: '',
        regime: 'micro',
        activity: 'commercial',
    });

    const [results, setResults] = useState<{
        charges: number;
        net: number;
        rate: number;
    } | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateCharges = () => {
        const revenue = parseFloat(formData.revenue);
        if (isNaN(revenue)) return;

        let rate = 0;
        if (formData.regime === 'micro') {
            rate = formData.activity === 'commercial' ? 0.123 : 0.22;
        } else {
            rate = 0.45;
        }

        const charges = revenue * rate;
        const net = revenue - charges;

        setResults({
            charges: Math.round(charges * 100) / 100,
            net: Math.round(net * 100) / 100,
            rate: rate,
        });
    };

    const handleSubmit = () => {
        calculateCharges();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de charges sociales
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.regime}
                        onChange={e => setFormData(prev => ({ ...prev, regime: e.target.value }))}
                    >
                        <FormControlLabel
                            value="micro"
                            control={<Radio />}
                            label="Micro-entreprise"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="reel"
                            control={<Radio />}
                            label="Régime réel"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.activity}
                        onChange={e => setFormData(prev => ({ ...prev, activity: e.target.value }))}
                    >
                        <FormControlLabel
                            value="commercial"
                            control={<Radio />}
                            label="Commerciale"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="liberal"
                            control={<Radio />}
                            label="Libérale"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires annuel (€)"
                        type="number"
                        value={formData.revenue}
                        onChange={e => setFormData(prev => ({ ...prev, revenue: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.revenue}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer mes charges
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Chiffre d'affaires</span>
                        <span className="value">{parseFloat(formData.revenue).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Taux de charges</span>
                        <span className="value">{(results.rate * 100).toFixed(1)}%</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Montant des charges</span>
                        <span className="value">{results.charges.toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Revenu net</span>
                        <span className="value">{results.net.toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default ChargesSociales;
