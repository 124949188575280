import { Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface LicenciementData {
    salaireBrut: string;
    anciennete: string;
    age: string;
}

interface LicenciementResults {
    indemnites: {
        legale: number;
        conventionnelle: number | null;
        preavis: number;
        conges: number;
        transaction: number | null;
    };
    charges: {
        patronales: number;
        salariales: number;
    };
    coutTotal: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const CoutLicenciement: React.FC = () => {
    const [formData, setFormData] = useState<LicenciementData>({
        salaireBrut: '',
        anciennete: '',
        age: '',
    });

    const [results, setResults] = useState<LicenciementResults | null>(null);

    const calculateLicenciement = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;
        const anciennete = parseFloat(formData.anciennete) || 0;
        const age = parseInt(formData.age) || 0;

        // Calcul de l'indemnité légale
        let indemniteLegale = 0;
        if (anciennete >= 8 / 12) {
            // 8 mois minimum
            if (anciennete < 10) {
                indemniteLegale = salaireBrut * 0.25 * anciennete;
            } else {
                indemniteLegale = salaireBrut * 0.25 * 10 + salaireBrut * 0.33 * (anciennete - 10);
            }
        }

        // Estimation de l'indemnité conventionnelle (si applicable)
        const indemniteConventionnelle = null;

        // Calcul du préavis (fixé à 3 mois)
        const montantPreavis = salaireBrut * 3;

        // Calcul des congés payés
        const montantConges = 0;

        // Estimation d'une éventuelle transaction
        let montantTransaction = null;
        if (age > 50 || anciennete > 10) {
            montantTransaction = indemniteLegale * 0.5;
        }

        // Calcul des charges sociales
        const chargesPatronales = (montantPreavis + montantConges) * 0.45;
        const chargesSalariales = (montantPreavis + montantConges) * 0.22;

        // Calcul du coût total
        const coutTotal =
            (indemniteConventionnelle || indemniteLegale) +
            montantPreavis +
            montantConges +
            (montantTransaction || 0) +
            chargesPatronales;

        // Détails des calculs
        const details = [
            {
                label: 'Indemnité légale minimale',
                montant: indemniteLegale,
                description: `Calculée sur ${anciennete} années d'ancienneté`,
            },
        ];

        // Recommandations
        const recommandations = [];

        if (age > 55) {
            recommandations.push('Attention aux obligations spécifiques pour les seniors');
        }

        if (anciennete > 15) {
            recommandations.push(
                "Dans le cas d'un licenciement économique, vérifiez les obligations de reclassement renforcées",
            );
        }

        setResults({
            indemnites: {
                legale: indemniteLegale,
                conventionnelle: indemniteConventionnelle,
                preavis: montantPreavis,
                conges: montantConges,
                transaction: montantTransaction,
            },
            charges: {
                patronales: chargesPatronales,
                salariales: chargesSalariales,
            },
            coutTotal,
            details,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de coût de licenciement
                </Typography>

                <Typography
                    variant="body2"
                    sx={{
                        mb: 3,
                        p: 2,
                        bgcolor: '#fff3e0',
                        borderRadius: 1,
                        color: '#bf360c',
                    }}
                >
                    Attention : Ce simulateur calcule l'indemnité légale minimale. L'indemnité conventionnelle, qui
                    dépend de votre convention collective, peut être plus avantageuse et sera alors celle qui
                    s'appliquera.
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Salaire mensuel brut (€)"
                        type="number"
                        value={formData.salaireBrut}
                        onChange={e => setFormData(prev => ({ ...prev, salaireBrut: e.target.value }))}
                        placeholder="Ex: 2500"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Ancienneté (années)"
                        type="number"
                        value={formData.anciennete}
                        onChange={e => setFormData(prev => ({ ...prev, anciennete: e.target.value }))}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Âge du salarié"
                        type="number"
                        value={formData.age}
                        onChange={e => setFormData(prev => ({ ...prev, age: e.target.value }))}
                        placeholder="Ex: 45"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateLicenciement}
                    disabled={!formData.salaireBrut || !formData.anciennete}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer le coût
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">{results.details[0].label}</span>
                        <span className="value">
                            {Math.round(results.details[0].montant).toLocaleString('fr-FR')} €
                        </span>
                    </div>
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                            {results.details[0].description}
                        </span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map(recommandation => (
                                <p key={recommandation} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default CoutLicenciement;
