import styled from '@emotion/styled';
import * as React from 'react';
import { createContext, useContext } from 'react';

import { useCookie } from '../../../hooks/useCookies';
import useModal from '../../../hooks/useModal';
import HubspotForm from '../../hubspot/HubspotForm';
import Modal from '../../modals/Modal';

const Titre = styled.div`
    font-size: 24px !important;
`;

export const SimulatorContext = createContext({
    logged: false,
    handleOpen: () => {
        return null;
    },
});

const SimulatorProvider = ({ children }: { children: React.ReactNode }) => {
    const { openModal, isModalOpen, closeModal } = useModal();
    const { cookie, updateCookie } = useCookie('simulator', 'false');
    const logged = cookie === 'logged';

    const handleOpen = () => {
        openModal();
    };

    const handleClose = () => {
        closeModal();
    };

    const handleAfterSubmit = () => {
        updateCookie('logged');
        handleClose();
    };

    return (
        <SimulatorContext.Provider value={{ handleOpen, logged }}>
            <Modal isOpen={isModalOpen} close={handleClose} size="small">
                <React.Fragment>
                    <Titre className="h2">Complétez le formulaire pour obtenir le résultat</Titre>
                    <HubspotForm
                        afterSubmit={handleAfterSubmit}
                        trackingId={'outils-simulation-modal'}
                        formId={'9182a0fd-58e7-454c-bc4e-1b000fb5bb74'}
                        target={'HubspotForm-ressource'}
                    ></HubspotForm>
                </React.Fragment>
            </Modal>
            {children}
        </SimulatorContext.Provider>
    );
};

export default SimulatorProvider;

export function useSimulatorContext() {
    return useContext(SimulatorContext);
}
