import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, Checkbox, FormControlLabel } from '@mui/material';

interface ParticipationData {
    benefice: string;
    capitauxPropres: string;
    masseSalariale: string;
    effectif: string;
    valeurAjoutee: string;
    derogation: boolean;
    tauxDerogation: string;
}

interface ParticipationResults {
    rsp: number;
    montantParSalarie: number;
    plafond: boolean;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const ParticipationBenefices: React.FC = () => {
    const [formData, setFormData] = useState<ParticipationData>({
        benefice: '',
        capitauxPropres: '',
        masseSalariale: '',
        effectif: '',
        valeurAjoutee: '',
        derogation: false,
        tauxDerogation: '0',
    });

    const [results, setResults] = useState<ParticipationResults | null>(null);

    const calculateParticipation = () => {
        const benefice = parseFloat(formData.benefice) || 0;
        const capitauxPropres = parseFloat(formData.capitauxPropres) || 0;
        const masseSalariale = parseFloat(formData.masseSalariale) || 0;
        const effectif = parseInt(formData.effectif) || 0;
        const valeurAjoutee = parseFloat(formData.valeurAjoutee) || 0;
        const tauxDerogation = parseFloat(formData.tauxDerogation) || 0;

        // Calcul de la RSP selon la formule légale
        const tauxRendement = 0.05; // 5% des capitaux propres
        const rendementCapitaux = capitauxPropres * tauxRendement;
        const beneficeNet = benefice - rendementCapitaux;

        // Formule légale : RSP = 1/2 × (B - 5%C) × (S/VA)
        const rspLegale = 0.5 * beneficeNet * (masseSalariale / valeurAjoutee);

        // Plafonnement à 20% de la masse salariale
        const plafondParticipation = masseSalariale * 0.2;
        let rspFinale = Math.min(rspLegale, plafondParticipation);

        // Application du taux dérogatoire si applicable
        if (formData.derogation && tauxDerogation > 0) {
            rspFinale = benefice * (tauxDerogation / 100);
        }

        // Montant par salarié
        const montantParSalarie = rspFinale / effectif;

        const details = [
            {
                label: 'Bénéfice net',
                montant: benefice,
                description: "Bénéfice fiscal de l'exercice",
            },
            {
                label: 'Rendement des capitaux propres',
                montant: rendementCapitaux,
                description: '5% des capitaux propres',
            },
            {
                label: 'Base de calcul',
                montant: beneficeNet,
                description: 'Bénéfice - Rendement des capitaux',
            },
            {
                label: 'Ratio masse salariale/VA',
                montant: masseSalariale / valeurAjoutee,
                description: 'Part du travail dans la création de valeur',
            },
            {
                label: formData.derogation ? 'RSP dérogatoire' : 'RSP légale',
                montant: rspFinale,
                description: formData.derogation ? `${tauxDerogation}% du bénéfice` : 'Selon la formule légale',
            },
        ];

        setResults({
            rsp: rspFinale,
            montantParSalarie,
            plafond: rspLegale > plafondParticipation,
            details,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de participation aux bénéfices
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Bénéfice fiscal (€)"
                        type="number"
                        value={formData.benefice}
                        onChange={e => setFormData(prev => ({ ...prev, benefice: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Capitaux propres (€)"
                        type="number"
                        value={formData.capitauxPropres}
                        onChange={e => setFormData(prev => ({ ...prev, capitauxPropres: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Masse salariale (€)"
                        type="number"
                        value={formData.masseSalariale}
                        onChange={e => setFormData(prev => ({ ...prev, masseSalariale: e.target.value }))}
                        placeholder="Ex: 300000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Valeur ajoutée (€)"
                        type="number"
                        value={formData.valeurAjoutee}
                        onChange={e => setFormData(prev => ({ ...prev, valeurAjoutee: e.target.value }))}
                        placeholder="Ex: 800000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Effectif"
                        type="number"
                        value={formData.effectif}
                        onChange={e => setFormData(prev => ({ ...prev, effectif: e.target.value }))}
                        placeholder="Ex: 25"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.derogation}
                                onChange={e => setFormData(prev => ({ ...prev, derogation: e.target.checked }))}
                            />
                        }
                        label="Accord dérogatoire"
                    />
                </FormControl>

                {formData.derogation && (
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Taux dérogatoire (%)"
                            type="number"
                            value={formData.tauxDerogation}
                            onChange={e => setFormData(prev => ({ ...prev, tauxDerogation: e.target.value }))}
                            placeholder="Ex: 15"
                        />
                    </FormControl>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateParticipation}
                    disabled={!formData.benefice || !formData.masseSalariale || !formData.valeurAjoutee}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer la participation
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">
                                    {typeof detail.montant === 'number' && !isNaN(detail.montant)
                                        ? Math.round(detail.montant).toLocaleString('fr-FR') + ' €'
                                        : (detail.montant * 100).toFixed(1) + '%'}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.plafond && (
                        <div className="result-row" style={{ backgroundColor: '#FEF2F2' }}>
                            <span className="label">Plafonnement appliqué</span>
                            <span className="value" style={{ color: '#DC2626' }}>
                                20% de la masse salariale
                            </span>
                        </div>
                    )}

                    <div className="result-row highlight">
                        <span className="label">Montant par salarié</span>
                        <span className="value">{Math.round(results.montantParSalarie).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default ParticipationBenefices;
