import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import React, { useState } from 'react';

const TaxeVehiculesSociete: React.FC = () => {
    const [dateImmatriculation, setDateImmatriculation] = useState<string>('');
    const [emissionsCO2, setEmissionsCO2] = useState<number>(0);
    const [puissanceFiscale, setPuissanceFiscale] = useState<number>(0);
    const [critAir, setCritAir] = useState<string>('');
    const [isHybride, setIsHybride] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);

    const calculTaxeCO2 = () => {
        const dateImmat = new Date(dateImmatriculation);
        const date2020 = new Date('2020-03-01');
        const date2004 = new Date('2004-06-01');

        // Véhicules WLTP (immatriculés après mars 2020)
        if (dateImmat >= date2020) {
            let taxe = 0;

            // Tranche ≤ 14g : 0€
            // Tranche 15-55g : 1€/g
            if (emissionsCO2 > 14) {
                taxe += Math.min(emissionsCO2 - 14, 41) * 1;
            }
            // Tranche 56-63g : 2€/g
            if (emissionsCO2 > 55) {
                taxe += Math.min(emissionsCO2 - 55, 8) * 2;
            }
            // Tranche 64-95g : 3€/g
            if (emissionsCO2 > 63) {
                taxe += Math.min(emissionsCO2 - 63, 32) * 3;
            }
            // Tranche 96-115g : 4€/g
            if (emissionsCO2 > 95) {
                taxe += Math.min(emissionsCO2 - 95, 20) * 4;
            }
            // Tranche 116-135g : 10€/g
            if (emissionsCO2 > 115) {
                taxe += Math.min(emissionsCO2 - 115, 20) * 10;
            }
            // Tranche 136-155g : 50€/g
            if (emissionsCO2 > 135) {
                taxe += Math.min(emissionsCO2 - 135, 20) * 50;
            }
            // Tranche 156-175g : 60€/g
            if (emissionsCO2 > 155) {
                taxe += Math.min(emissionsCO2 - 155, 20) * 60;
            }
            // Tranche > 175g : 65€/g
            if (emissionsCO2 > 175) {
                taxe += (emissionsCO2 - 175) * 65;
            }

            return taxe;
        }
        // Véhicules entre juin 2004 et février 2020
        else if (dateImmat >= date2004) {
            let taxe = 0;

            // Tranche ≤ 12g : 0€
            // Tranche 13-45g : 1€/g
            if (emissionsCO2 > 12) {
                taxe += Math.min(emissionsCO2 - 12, 33) * 1;
            }
            // Tranche 46-52g : 2€/g
            if (emissionsCO2 > 45) {
                taxe += Math.min(emissionsCO2 - 45, 7) * 2;
            }
            // Tranche 53-79g : 3€/g
            if (emissionsCO2 > 52) {
                taxe += Math.min(emissionsCO2 - 52, 27) * 3;
            }
            // Tranche 80-95g : 4€/g
            if (emissionsCO2 > 79) {
                taxe += Math.min(emissionsCO2 - 79, 16) * 4;
            }
            // Tranche 96-112g : 10€/g
            if (emissionsCO2 > 95) {
                taxe += Math.min(emissionsCO2 - 95, 17) * 10;
            }
            // Tranche 113-128g : 50€/g
            if (emissionsCO2 > 112) {
                taxe += Math.min(emissionsCO2 - 112, 16) * 50;
            }
            // Tranche 129-145g : 60€/g
            if (emissionsCO2 > 128) {
                taxe += Math.min(emissionsCO2 - 128, 17) * 60;
            }
            // Tranche > 145g : 65€/g
            if (emissionsCO2 > 145) {
                taxe += (emissionsCO2 - 145) * 65;
            }

            return taxe;
        }
        // Véhicules avant juin 2004 (puissance fiscale)
        else {
            const bareme = [1500, 2250, 3750, 4750, 6000];
            let taxe = 0;
            if (puissanceFiscale <= 3) taxe = bareme[0];
            else if (puissanceFiscale <= 6) taxe = bareme[0] + bareme[1];
            else if (puissanceFiscale <= 10) taxe = bareme[0] + bareme[1] + bareme[2];
            else if (puissanceFiscale <= 15) taxe = bareme[0] + bareme[1] + bareme[2] + bareme[3];
            else taxe = bareme[0] + bareme[1] + bareme[2] + bareme[3] + bareme[4];
            return taxe;
        }
    };

    const calculTaxePolluants = () => {
        switch (critAir) {
            case '0':
                return 0;
            case '1':
                return 100;
            default:
                return 500;
        }
    };

    const handleCalculer = () => {
        setShowResults(true);
    };

    const handleCritAirChange = (event: SelectChangeEvent) => {
        setCritAir(event.target.value);
    };

    const showPuissanceFiscale = () => {
        if (!dateImmatriculation) return false;
        return new Date(dateImmatriculation) < new Date('2004-06-01');
    };

    const showEmissionsCO2 = () => {
        if (!dateImmatriculation) return false;
        return new Date(dateImmatriculation) >= new Date('2004-06-01');
    };

    return (
        <div className="simulator">
            <div className="simulator__form">
                <h3>Caractéristiques du véhicule</h3>

                <div className="form-group">
                    <label>Date de première immatriculation</label>
                    <input
                        type="date"
                        value={dateImmatriculation}
                        onChange={e => setDateImmatriculation(e.target.value)}
                    />
                </div>

                {showEmissionsCO2() && (
                    <div className="form-group">
                        <label>Émissions de CO₂ (g/km)</label>
                        <input
                            type="number"
                            value={emissionsCO2}
                            onChange={e => setEmissionsCO2(Number(e.target.value))}
                        />
                    </div>
                )}

                {showPuissanceFiscale() && (
                    <div className="form-group">
                        <label>Puissance fiscale (CV)</label>
                        <input
                            type="number"
                            value={puissanceFiscale}
                            onChange={e => setPuissanceFiscale(Number(e.target.value))}
                        />
                    </div>
                )}

                <div className="form-group">
                    <FormControl fullWidth>
                        <InputLabel id="critair-label">Vignette Crit'Air</InputLabel>
                        <Select
                            labelId="critair-label"
                            id="critair-select"
                            value={critAir}
                            label="Vignette Crit'Air"
                            onChange={handleCritAirChange}
                        >
                            <MenuItem value="0">Crit'Air 0 (Véhicule électrique)</MenuItem>
                            <MenuItem value="1">Crit'Air 1</MenuItem>
                            <MenuItem value="2">Crit'Air 2</MenuItem>
                            <MenuItem value="3">Crit'Air 3</MenuItem>
                            <MenuItem value="4">Crit'Air 4</MenuItem>
                            <MenuItem value="5">Crit'Air 5</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="form-group">
                    <FormControlLabel
                        control={<Checkbox checked={isHybride} onChange={e => setIsHybride(e.target.checked)} />}
                        label="Véhicule hybride"
                    />
                </div>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCalculer}
                    sx={{ mt: 2 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer
                </Button>
            </div>

            {showResults && (
                <div className="simulator__result">
                    <h3>Résultats</h3>
                    <div className="result-group">
                        <label>Taxe sur les émissions de CO₂</label>
                        <p className="amount">
                            {isHybride && new Date().getFullYear() <= 2024
                                ? "0 € (Exonération jusqu'à fin 2024)"
                                : `${calculTaxeCO2().toFixed(2)} €`}
                        </p>
                    </div>
                    <div className="result-group">
                        <label>Taxe sur les émissions de polluants atmosphériques</label>
                        <p className="amount">{calculTaxePolluants().toFixed(2)} €</p>
                    </div>
                    <div className="result-group">
                        <label>Total des taxes</label>
                        <p className="amount total">
                            {(
                                (isHybride && new Date().getFullYear() <= 2024 ? 0 : calculTaxeCO2()) +
                                calculTaxePolluants()
                            ).toFixed(2)}{' '}
                            €
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TaxeVehiculesSociete;
