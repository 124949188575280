import {
    Alert,
    Button,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface CfeData {
    valeurLocative: string;
    commune: string;
    tauxCommune: string;
    premierExercice: boolean;
}

interface CfeResults {
    baseCotisation: number;
    cotisationFinale: number;
    exoneration: boolean;
}

const CotisationFonciere: React.FC = () => {
    const [formData, setFormData] = useState<CfeData>({
        valeurLocative: '',
        commune: '',
        tauxCommune: '',
        premierExercice: true,
    });

    const [results, setResults] = useState<CfeResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateCFE = () => {
        const valeurLocative = parseFloat(formData.valeurLocative) || 0;
        const tauxCommune = parseFloat(formData.tauxCommune) || 0;

        // Calcul de la base d'imposition
        const baseCotisation = valeurLocative;
        const cotisationFinale = baseCotisation * (tauxCommune / 100);

        // Vérification des cas d'exonération (uniquement première année)
        const exoneration = formData.premierExercice;

        setResults({
            baseCotisation,
            cotisationFinale: exoneration ? 0 : cotisationFinale,
            exoneration,
        });
    };

    const handleSubmit = () => {
        calculateCFE();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de CFE
                </Typography>
                <Alert severity="info" sx={{ mb: 3 }}>
                    Ce simulateur ne traite pas le calcul de la cotisation minimum
                </Alert>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.premierExercice}
                        onChange={e => setFormData(prev => ({ ...prev, premierExercice: e.target.value === 'true' }))}
                    >
                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Première année d'activité"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Année courante"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Valeur locative cadastrale (€)"
                        type="number"
                        value={formData.valeurLocative}
                        onChange={e => setFormData(prev => ({ ...prev, valeurLocative: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux communal (%)"
                        type="number"
                        value={formData.tauxCommune}
                        onChange={e => setFormData(prev => ({ ...prev, tauxCommune: e.target.value }))}
                        placeholder="Ex: 25.59"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.tauxCommune || !formData.valeurLocative}
                    fullWidth
                    sx={{ mt: 3 }}
                    data-ga-send="event,button,click,cta-resultat-simulateur"
                >
                    Calculer la CFE
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.exoneration ? (
                        <div className="result-row highlight">
                            <span className="label">Statut</span>
                            <span className="value" style={{ color: '#10B981' }}>
                                Exonéré de CFE
                            </span>
                        </div>
                    ) : (
                        <>
                            <div className="result-row">
                                <span className="label">Base d'imposition</span>
                                <span className="value">
                                    {Math.round(results.baseCotisation).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                            <div className="result-row highlight">
                                <span className="label">CFE à payer</span>
                                <span className="value">
                                    {Math.round(results.cotisationFinale).toLocaleString('fr-FR')} €
                                </span>
                            </div>
                        </>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default CotisationFonciere;
