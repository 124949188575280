import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import { useStepContext } from '../../contexts/stepContextProvider';
import { mixpanelEvent } from '../layout/scripts';

const Wrapper = styled.div`
    text-align: -webkit-center;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0px;
    @media screen and (max-width: 1024px) {
        padding: 36px 12px;
        width: 100%;
        margin-top: -30px;
    }
`;

const StepCalendly = () => {
    const { calendlyLink, payload, hasSentEvent, setHasSentEvent } = useStepContext();

    useCalendlyEventListener({
        onEventScheduled: () => {
            if (window.dataLayer && !hasSentEvent) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'meeting_confirmed',
                    event_category: 'contact',
                    event_action: 'schedule',
                    event_label: 'calendly_meeting',
                    platform: 'Marketing WebSite',
                    tag_name: 'Meeting_confirmed',
                });
                setHasSentEvent(true);
                mixpanelEvent({ params: 'send,event,contact,click,meeting-confirmed'.split(',') });
            }
        },
    });

    useEffect(() => {
        mixpanelEvent({ params: 'send,event,contact,click,contact-eligible'.split(','), options: payload });
    }, [payload]);

    useEffect(() => {
        if (window && typeof window !== undefined) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <Wrapper>
            <InlineWidget
                prefill={{
                    name: payload.firstName + ' ' + payload.lastName,
                    email: payload.email,
                    location: '33' + payload.phone.slice(1),
                    customAnswers: {
                        a1: ' ',
                        a2: '33' + payload.phone.slice(1),
                    },
                }}
                styles={{
                    minWidth: '300px',
                    height: '750px',
                    maxWidth: '100%',
                    overflow: 'hidden',
                }}
                pageSettings={{
                    backgroundColor: 'ffffff',
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                    primaryColor: '013a51',
                    textColor: '013a51',
                }}
                url={calendlyLink + '/20-minutes'}
                utm={{
                    utmCampaign: 'prise-de-rendez-vous-page-contact',
                    utmContent: 'prendre-rendez-vous',
                    utmMedium: 'cta',
                    utmSource: window && window.location.pathname,
                }}
            />
        </Wrapper>
    );
};

export default StepCalendly;
