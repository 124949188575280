import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface HeuresSupData {
    tauxHoraire: string;
    nombreHeures: string;
    typeHeures: '25' | '50';
    conventionCollective: string;
    contingentAnnuel: string;
    heuresEffectuees: string;
}

interface HeuresSupResults {
    majorationBrute: number;
    coutEmployeur: number;
    remunerationSalarie: number;
    reductionCharges: number;
    exonerationFiscale: boolean;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const HeuresSupplementaires: React.FC = () => {
    const [formData, setFormData] = useState<HeuresSupData>({
        tauxHoraire: '',
        nombreHeures: '',
        typeHeures: '25',
        conventionCollective: '',
        contingentAnnuel: '220',
        heuresEffectuees: '0',
    });

    const [results, setResults] = useState<HeuresSupResults | null>(null);

    const calculateHeuresSup = () => {
        const tauxHoraire = parseFloat(formData.tauxHoraire) || 0;
        const nombreHeures = parseFloat(formData.nombreHeures) || 0;
        const contingentAnnuel = parseFloat(formData.contingentAnnuel) || 220;
        const heuresEffectuees = parseFloat(formData.heuresEffectuees) || 0;
        const tauxMajoration = formData.typeHeures === '25' ? 1.25 : 1.5;

        // Calcul de la majoration brute
        const majorationBrute = tauxHoraire * nombreHeures * tauxMajoration;
        const majorationBase = tauxHoraire * nombreHeures;
        const majorationSeule = majorationBrute - majorationBase;

        // Calcul des charges sociales
        const chargesPatronales = majorationBrute * 0.45; // 45% de charges patronales
        const chargesSalariales = majorationBrute * 0.22; // 22% de charges salariales

        // Réduction de charges
        const reductionCharges = majorationSeule * 0.2; // 20% de réduction sur la majoration

        // Coût total employeur
        const coutEmployeur = majorationBrute + chargesPatronales;

        // Rémunération nette salarié
        const remunerationSalarie = majorationBrute - chargesSalariales + reductionCharges;

        // Vérification de l'exonération fiscale (limite de 5000€ par an)
        const exonerationFiscale = heuresEffectuees + nombreHeures <= contingentAnnuel;

        const details = [
            {
                label: 'Heures normales',
                montant: majorationBase,
                description: `${nombreHeures}h × ${tauxHoraire}€`,
            },
            {
                label: `Majoration (${(tauxMajoration - 1) * 100}%)`,
                montant: majorationSeule,
                description: 'Majoration légale des heures supplémentaires',
            },
            {
                label: 'Charges patronales',
                montant: chargesPatronales,
                description: '45% de charges patronales',
            },
            {
                label: 'Charges salariales',
                montant: chargesSalariales,
                description: '22% de charges salariales',
            },
            {
                label: 'Réduction de charges',
                montant: reductionCharges,
                description: '20% de réduction sur la majoration',
            },
        ];

        setResults({
            majorationBrute,
            coutEmployeur,
            remunerationSalarie,
            reductionCharges,
            exonerationFiscale,
            details,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur d'heures supplémentaires
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux horaire brut (€)"
                        type="number"
                        value={formData.tauxHoraire}
                        onChange={e => setFormData(prev => ({ ...prev, tauxHoraire: e.target.value }))}
                        placeholder="Ex: 15"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre d'heures supplémentaires"
                        type="number"
                        value={formData.nombreHeures}
                        onChange={e => setFormData(prev => ({ ...prev, nombreHeures: e.target.value }))}
                        placeholder="Ex: 10"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeHeures}
                        onChange={e => setFormData(prev => ({ ...prev, typeHeures: e.target.value as '25' | '50' }))}
                    >
                        <FormControlLabel
                            value="25"
                            control={<Radio />}
                            label="Majoration 25%"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="50"
                            control={<Radio />}
                            label="Majoration 50%"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Convention collective"
                        value={formData.conventionCollective}
                        onChange={e => setFormData(prev => ({ ...prev, conventionCollective: e.target.value }))}
                        placeholder="Ex: Métallurgie"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Contingent annuel (heures)"
                        type="number"
                        value={formData.contingentAnnuel}
                        onChange={e => setFormData(prev => ({ ...prev, contingentAnnuel: e.target.value }))}
                        placeholder="Ex: 220"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Heures supplémentaires déjà effectuées"
                        type="number"
                        value={formData.heuresEffectuees}
                        onChange={e => setFormData(prev => ({ ...prev, heuresEffectuees: e.target.value }))}
                        placeholder="Ex: 100"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateHeuresSup}
                    disabled={!formData.tauxHoraire || !formData.nombreHeures}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer les heures supplémentaires
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">{Math.round(detail.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Coût total employeur</span>
                        <span className="value">{Math.round(results.coutEmployeur).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Rémunération nette salarié</span>
                        <span className="value">
                            {Math.round(results.remunerationSalarie).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div
                        className="result-row"
                        style={{ backgroundColor: results.exonerationFiscale ? '#E8F5E9' : '#FFEBEE' }}
                    >
                        <span className="label">Exonération fiscale</span>
                        <span className="value" style={{ color: results.exonerationFiscale ? '#2E7D32' : '#C62828' }}>
                            {results.exonerationFiscale ? 'Oui' : 'Non (dépassement du contingent)'}
                        </span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default HeuresSupplementaires;
