import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface RemunerationData {
    resultatAnnuel: string;
    statut: 'sasu' | 'eurl';
    situationFamiliale: 'celibataire' | 'marie' | 'pacse';
    autresRevenus: string;
}

interface RemunerationResults {
    salaireOptimal: number;
    dividendesOptimaux: number;
    chargesSociales: number;
    impotRevenu: number;
    impotSocietes: number;
    revenusNets: number;
}

const OptimisationRemuneration: React.FC = () => {
    const [formData, setFormData] = useState<RemunerationData>({
        resultatAnnuel: '',
        statut: 'sasu',
        situationFamiliale: 'celibataire',
        autresRevenus: '0',
    });

    const [results, setResults] = useState<RemunerationResults | null>(null);

    const calculateOptimisation = () => {
        const resultat = parseFloat(formData.resultatAnnuel) || 0;
        const autresRevenus = parseFloat(formData.autresRevenus) || 0;

        // Calcul simplifié pour l'exemple
        const salaireOptimal = resultat * 0.45;
        const dividendesOptimaux = resultat * 0.55;
        const chargesSociales = salaireOptimal * 0.45;
        const impotRevenu = (salaireOptimal + dividendesOptimaux + autresRevenus) * 0.3;
        const impotSocietes = resultat * 0.15;
        const revenusNets = resultat - chargesSociales - impotRevenu - impotSocietes;

        setResults({
            salaireOptimal,
            dividendesOptimaux,
            chargesSociales,
            impotRevenu,
            impotSocietes,
            revenusNets,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Optimisation de la rémunération
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.statut}
                        onChange={e => setFormData(prev => ({ ...prev, statut: e.target.value as 'sasu' | 'eurl' }))}
                    >
                        <FormControlLabel value="sasu" control={<Radio />} label="SASU" sx={{ flex: 1, m: 0, p: 2 }} />
                        <FormControlLabel value="eurl" control={<Radio />} label="EURL" sx={{ flex: 1, m: 0, p: 2 }} />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.situationFamiliale}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                situationFamiliale: e.target.value as 'celibataire' | 'marie' | 'pacse',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="celibataire"
                            control={<Radio />}
                            label="Célibataire"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="marie"
                            control={<Radio />}
                            label="Marié(e)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="pacse"
                            control={<Radio />}
                            label="Pacsé(e)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat annuel avant rémunération (€)"
                        type="number"
                        value={formData.resultatAnnuel}
                        onChange={e => setFormData(prev => ({ ...prev, resultatAnnuel: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Autres revenus du foyer (€)"
                        type="number"
                        value={formData.autresRevenus}
                        onChange={e => setFormData(prev => ({ ...prev, autresRevenus: e.target.value }))}
                        placeholder="Ex: 30000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateOptimisation}
                    disabled={!formData.resultatAnnuel}
                    fullWidth
                >
                    Calculer l'optimisation
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Salaire brut optimal</span>
                        <span className="value">{Math.round(results.salaireOptimal).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Dividendes optimaux</span>
                        <span className="value">
                            {Math.round(results.dividendesOptimaux).toLocaleString('fr-FR')} €
                        </span>
                    </div>
                    <div className="result-row">
                        <span className="label">Charges sociales</span>
                        <span className="value">-{Math.round(results.chargesSociales).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Impôt sur le revenu estimé</span>
                        <span className="value">-{Math.round(results.impotRevenu).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Impôt sur les sociétés</span>
                        <span className="value">-{Math.round(results.impotSocietes).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Revenus nets après impôts</span>
                        <span className="value">{Math.round(results.revenusNets).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default OptimisationRemuneration;
