import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface AnalyseData {
    chiffreAffaires: string;
    resultatNet: string;
    capitauxPropres: string;
    effectif: string;
    secteurActivite: 'commerce' | 'service' | 'industrie';
    tailleSociete: 'tpe' | 'pme' | 'eti';
}

interface AnalyseResults {
    ratios: {
        label: string;
        valeur: number;
        moyenneSecteur: number;
        ecart: number;
        interpretation: string;
    }[];
    positionnement: {
        forces: string[];
        faiblesses: string[];
    };
    recommandations: string[];
}

const AnalyseConcurrentielle: React.FC = () => {
    const [formData, setFormData] = useState<AnalyseData>({
        chiffreAffaires: '',
        resultatNet: '',
        capitauxPropres: '',
        effectif: '',
        secteurActivite: 'commerce',
        tailleSociete: 'pme',
    });

    const [results, setResults] = useState<AnalyseResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateAnalyse = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const rn = parseFloat(formData.resultatNet) || 0;
        const cp = parseFloat(formData.capitauxPropres) || 0;
        const effectif = parseInt(formData.effectif) || 0;

        // Ratios moyens par secteur (données fictives pour l'exemple)
        const moyennesSecteur = {
            commerce: {
                rentabiliteNette: 3,
                rentabiliteFinanciere: 12,
                caParSalarie: 250000,
            },
            service: {
                rentabiliteNette: 8,
                rentabiliteFinanciere: 18,
                caParSalarie: 150000,
            },
            industrie: {
                rentabiliteNette: 5,
                rentabiliteFinanciere: 15,
                caParSalarie: 300000,
            },
        };

        const moyennes = moyennesSecteur[formData.secteurActivite];

        // Calcul des ratios
        const rentabiliteNette = (rn / ca) * 100;
        const rentabiliteFinanciere = (rn / cp) * 100;
        const caParSalarie = ca / effectif;

        // Analyse des écarts
        const ratios = [
            {
                label: 'Rentabilité nette',
                valeur: rentabiliteNette,
                moyenneSecteur: moyennes.rentabiliteNette,
                ecart: rentabiliteNette - moyennes.rentabiliteNette,
                interpretation:
                    rentabiliteNette >= moyennes.rentabiliteNette
                        ? 'Performance supérieure à la moyenne du secteur'
                        : 'Performance inférieure à la moyenne du secteur',
            },
            {
                label: 'Rentabilité financière',
                valeur: rentabiliteFinanciere,
                moyenneSecteur: moyennes.rentabiliteFinanciere,
                ecart: rentabiliteFinanciere - moyennes.rentabiliteFinanciere,
                interpretation:
                    rentabiliteFinanciere >= moyennes.rentabiliteFinanciere
                        ? 'Bonne rémunération des capitaux propres'
                        : 'Rentabilité des capitaux propres à améliorer',
            },
            {
                label: 'CA par salarié',
                valeur: caParSalarie,
                moyenneSecteur: moyennes.caParSalarie,
                ecart: caParSalarie - moyennes.caParSalarie,
                interpretation:
                    caParSalarie >= moyennes.caParSalarie
                        ? 'Productivité supérieure à la moyenne'
                        : 'Productivité à optimiser',
            },
        ];

        // Analyse des forces et faiblesses
        const forces = [];
        const faiblesses = [];

        ratios.forEach(ratio => {
            if (ratio.ecart > 0) {
                forces.push(`${ratio.label} supérieure de ${Math.abs(ratio.ecart).toFixed(1)}% à la moyenne`);
            } else if (ratio.ecart < 0) {
                faiblesses.push(`${ratio.label} inférieure de ${Math.abs(ratio.ecart).toFixed(1)}% à la moyenne`);
            }
        });

        // Recommandations
        const recommandations = [];

        if (rentabiliteNette < moyennes.rentabiliteNette) {
            recommandations.push("Optimisez vos marges et réduisez vos coûts d'exploitation");
        }

        if (rentabiliteFinanciere < moyennes.rentabiliteFinanciere) {
            recommandations.push("Réévaluez votre structure financière et l'utilisation des capitaux propres");
        }

        if (caParSalarie < moyennes.caParSalarie) {
            recommandations.push('Améliorez la productivité par des investissements ou une réorganisation');
        }

        setResults({
            ratios,
            positionnement: {
                forces,
                faiblesses,
            },
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateAnalyse();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Analyse concurrentielle
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.secteurActivite}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                secteurActivite: e.target.value as 'commerce' | 'service' | 'industrie',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="industrie"
                            control={<Radio />}
                            label="Industrie"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.tailleSociete}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                tailleSociete: e.target.value as 'tpe' | 'pme' | 'eti',
                            }))
                        }
                    >
                        <FormControlLabel value="tpe" control={<Radio />} label="TPE" sx={{ flex: 1, m: 0, p: 2 }} />
                        <FormControlLabel value="pme" control={<Radio />} label="PME" sx={{ flex: 1, m: 0, p: 2 }} />
                        <FormControlLabel value="eti" control={<Radio />} label="ETI" sx={{ flex: 1, m: 0, p: 2 }} />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat net (€)"
                        type="number"
                        value={formData.resultatNet}
                        onChange={e => setFormData(prev => ({ ...prev, resultatNet: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Capitaux propres (€)"
                        type="number"
                        value={formData.capitauxPropres}
                        onChange={e => setFormData(prev => ({ ...prev, capitauxPropres: e.target.value }))}
                        placeholder="Ex: 300000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Effectif"
                        type="number"
                        value={formData.effectif}
                        onChange={e => setFormData(prev => ({ ...prev, effectif: e.target.value }))}
                        placeholder="Ex: 10"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.chiffreAffaires || !formData.resultatNet}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Analyser le positionnement
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.ratios.map((ratio, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{ratio.label}</span>
                                <span className="value">
                                    {ratio.label.includes('CA par salarié')
                                        ? Math.round(ratio.valeur).toLocaleString('fr-FR') + ' €'
                                        : ratio.valeur.toFixed(1) + '%'}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">Moyenne du secteur</span>
                                <span className="value">
                                    {ratio.label.includes('CA par salarié')
                                        ? Math.round(ratio.moyenneSecteur).toLocaleString('fr-FR') + ' €'
                                        : ratio.moyenneSecteur.toFixed(1) + '%'}
                                </span>
                            </div>
                            <div
                                className="result-row"
                                style={{
                                    backgroundColor: ratio.ecart >= 0 ? '#E8F5E9' : '#FFEBEE',
                                }}
                            >
                                <span className="label">{ratio.interpretation}</span>
                                <span
                                    className="value"
                                    style={{
                                        color: ratio.ecart >= 0 ? '#2E7D32' : '#C62828',
                                    }}
                                >
                                    {ratio.ecart >= 0 ? '+' : ''}
                                    {ratio.ecart.toFixed(1)}%
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row" style={{ backgroundColor: '#E8F5E9' }}>
                        <span className="label">Forces</span>
                        <span className="value">{results.positionnement.forces.length}</span>
                    </div>
                    {results.positionnement.forces.map((force, index) => (
                        <div key={index} className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                            <span className="label" style={{ color: '#2E7D32' }}>
                                • {force}
                            </span>
                        </div>
                    ))}

                    <div className="result-row" style={{ backgroundColor: '#FFEBEE' }}>
                        <span className="label">Faiblesses</span>
                        <span className="value">{results.positionnement.faiblesses.length}</span>
                    </div>
                    {results.positionnement.faiblesses.map((faiblesse, index) => (
                        <div key={index} className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                            <span className="label" style={{ color: '#C62828' }}>
                                • {faiblesse}
                            </span>
                        </div>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default AnalyseConcurrentielle;
